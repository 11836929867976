.userForm{
    position: relative;
    text-align: left;
    padding: 0.5%;
  }

.inpLabel{
    width:126px;
}


.dynamicFormSection{
    display:flex;
}

.orangeButton{
    width: 45px;
    height: 45px;
    margin-right: 2%;
    border-radius: 5px;
    background-color: #fdf0df;

}

.orangeInput2{
    background-color: #fdf0df;
    width: 200px;
    border-radius: 5px;
    height: 28px;
}

.orangeInput{
    background-color: #fdf0df;
    width: 600px;
    border-radius: 5px;
    height: 28px;

}

.otherFields{
    margin-left: 8%;
    display: flex;
    align-items: baseline;
}

.DEfields{
    margin-top:5%;
    display:flex;
}

.DEfieldSection{
    width: 220px;   
    text-align: left;
}

.itemDiv{
    width:300px;
    height:200px;
    background-color: grey;
}

.titleSection{
    text-align:left;
    margin-top:1%;
    margin-bottom:3%;
    font-size:22px;

}

.pageWrapper{
    display: block;
    position: absolute;
    left: 0%;
    top: 17%;
    overflow-y: scroll;
    height: 70%;
    width: 61%;
    left: 23%;
    overflow-x: hidden;
}


.defaultInp{
    margin-left: 2%;
    height: 30px;
    width: 265px;
    border-radius: 5px;
    background-color: #fceddf;
    border: 2px solid #8080805c;
}

.existingUser{
    margin-left:1%;
}

.userSelect{
    position: relative;
    box-sizing: border-box;
    width: 16%;
    padding: 0%;
    margin-top: .5%;

    margin-bottom: 2%;
    height: 30px;
    border-radius: 5px;



}


.field{
    margin-top:3%;
    margin-left:1%;
    width: 100%;
    display: inline-flex;
}


.contactInfo{
    margin-left:1%;
}

.leftCol{
    width:40%;
    padding-bottom:.5%;
    float:0;
    text-align: left;
}

.rightCol{
    width:60%;
    text-align: left;
    float:1;
}


.availabilityDay{
    width: 36px;
    height: 20px;
    background-color: white;
    border: 2px solid black;
    padding: 1.1%;
    border-radius: 5px;
    margin-left: 3%;
    text-align: center;
    margin-top: 2%;
}

.rowOfBoxes{
    display:flex;
}

.availabilityBox{
    display: inherit;
    position: inherit;
    text-align: left;
    margin-left: 2%;
    margin-top: 2%;
    

}

.availabilityText{
    margin-left:3%;
}

.createItem{
    margin-right: 20%;
}

.jobSelect{
    width:100%;
    margin-bottom:3%;
}



.vendorSelect{
    margin-bottom:3%;
    width:27%;
}

.locationSelect{
    width:35%;
}

.createUserText{
    margin-left:20%;
}


.itemSearch{
    margin-right: 25%;
}
.selectTextWrapper{

}

.jobPositionText{
    margin-left:1%;
    margin-right:8%
}

.rgText1{
    margin-left: 1%;
    margin-right: 1.5%;
}

.bottomSection{
    width: 70%;
  }

  .selectGrouper{
      width:57%;
      text-align: left;
      margin-left:2%
  }

  .blackLineDiv2{
    color: black;
    height: 1px;
    background-color: black;
    margin-bottom: 1%;
    width: 101.7%;
    margin-top: 1.5%;
    left: -2%;
    position: relative;
}


  .locationSelect{
  }
  
  .locationText{
    margin-top:5%;

  }

  .formBtnDiv{
    position: relative;
    width:80%;
    margin-top:7%;
    left:92%;
    display:flex;
  }


  .formBtn{
    background-color: rgb(255, 255, 255);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_text_default);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(47, 47, 47);
    border-radius: 1px;
    padding: 12px 16px;
    opacity: 1;
    margin-right: 5%;
    cursor: pointer;
    top: 857px;
    left: 1064px;
    width: 135px;
    height: 38px;
    z-index: 6;
  }


  .formBtn2{
    background-color: rgb(252, 143, 20);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_primary_contrast_default);
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    top: 857px;
    left: 1373px;
    width: 135px;
    height: 38px;
    z-index: 4;
  }

  