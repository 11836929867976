:root {
    --main-width: 80vw;
    --spacing-unit: 1rem;
    --input-width: 100%;
    --select-width: 300px;
    --transition-speed: 0.3s;
}
.popupBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popupMessage {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.popupMessage h3 {
  margin-top: 0;
}

.popupMessage p {
  margin-bottom: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.modalButtons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modalButtons button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

/* For single-button popups, we can style it differently than the dual-button modals */
.modalButtons button:only-child {
  background-color: #007bff;  /* Use your preferred primary color */
  color: white;
  border: none;
}

/* Keep your existing styles for two-button scenarios */
.modalButtons button:first-child {
  background-color: #f1f1f1;
  color: #333;
  border: none;
}

.modalButtons button:last-child {
  background-color: #f44336;
  color: white;
  border: none;
}

.landing-page__r2 .cds--tabs--scrollable {
    transform: translateZ(0);
    justify-content: flex-end;
  }
  
  .landing-page__r2 .cds--tab-content {
    padding: 0;
  }
  
  .cds--tab--list {
    justify-content: flex-end;
    padding-right: 1rem;
  }
  
  .cds--subgrid--wide {
    margin-right: 1rem;
  }

  /*
  html {
    background-color: $layer-01;
  }*/
  
  .landing-page__illo {
    max-width: 100%;
    float: inline-end;
    height: auto;
  }
/* Base Layout */
.pageWrapper {
    margin-left: 2%;
    max-width: var(--main-width);
    max-height: 93vh;
    min-height: 60vh;
    overflow-x: hidden;
    padding-left: 9%;
    transition: all var(--transition-speed) ease;
}

.pageWrapperScroll {
    overflow-y: auto;
}

/* Form Layout */
.userForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    transition: all var(--transition-speed) ease;
}

.existingUser {
    font-size: 24px;
    color: #333;
    margin: 0;
    transition: font-size var(--transition-speed) ease;
}

.createUserWrapper {
    margin-left: auto;
    transition: margin var(--transition-speed) ease;
}

/* Select Components */
.selectWrapper {
    width: var(--select-width);
    transition: width var(--transition-speed) ease;
}

.userSelect, .jobSelect, .locationSelect {
    width: 100%;
    transition: all var(--transition-speed) ease;
}

/* Main Form Sections */
.userInfoSection {
    margin-bottom: 20px;
    transition: margin var(--transition-speed) ease;
}

.contactInfo {
    font-size: 18px;
    margin-bottom: 15px;
    transition: all var(--transition-speed) ease;
}

.flexBox {
    display: flex;
    width: 43%;
    gap: 60px;
    transition: all var(--transition-speed) ease;
}

.leftCol, .rightCol {
    flex: 1;
    transition: all var(--transition-speed) ease;
}

/* Input Fields */
.field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5vh;
    transition: margin var(--transition-speed) ease;
}

.inpLabel {
    margin-bottom: 0.5vh;
    font-weight: 500;
}

.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.defaultInp {
    width: var(--input-width);
    padding: 1vh 1vw;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1vw;
    transition: all var(--transition-speed) ease;
}

/* Password Input Specific */
.passwordInput {
    padding-right: 40px;
}

.passwordToggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    color: #666;
    transition: color var(--transition-speed) ease;
}

.passwordToggle:hover {
    color: #333;
}

.passwordToggle:focus {
    outline: none;
}

/* Divider */
.blackLineDiv {
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
    width: 92vw;
    position: absolute;
    left: 0;
    transform: translateX(-2%);
    margin-left: 10%;
    transition: all var(--transition-speed) ease;
}

/* Bottom Section */
.bottomSection {
    display: grid;
    grid-template-columns: .22fr 1fr;
    gap: 60px;
    margin-top: 3%;
    row-gap: 1px;
    transition: all var(--transition-speed) ease;
}

.jobPositionSection, .locationSection {
    margin-bottom: 20px;
    min-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: -8%;
    transition: all var(--transition-speed) ease;
}

.jobPositionSection {
    width: 300px;
}

.jobPositionText, .locationText, .availabilityText {
    font-size: 18px;
    margin-bottom: 10px;
    transition: font-size var(--transition-speed) ease;
}

/* Availability Section */
.availabilityBox {
    width: 100%;
    grid-column: 1 / 2;
    transition: width var(--transition-speed) ease;
}

.rowOfBoxes {
    display: flex;
    gap: 10px;
    transition: gap var(--transition-speed) ease;
}

.availabilityDay {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: all var(--transition-speed) ease;
}

/* Buttons */
.formBtnDiv {
    grid-column: 2 / 2;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
    transition: all var(--transition-speed) ease;
}

.formBtn, .formBtn2, .formBtn3 {
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    line-height: 1;
    width: 135px;
    height: 42px;
    transition: all var(--transition-speed) ease;
}

.formBtn {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
}

.formBtn2, .formBtn3 {
    background-color: #f97316;
    color: white;
    border: none;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .flexBox {
        width: 60%;
    }
    
    .bottomSection {
        gap: 30px;
    }
}

@media (max-width: 1024px) {
    .pageWrapper {
        padding-left: 5%;
    }

    .flexBox {
        width: 80%;
        gap: 30px;
    }

    .defaultInp {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    :root {
        --select-width: 100%;
    }

    .flexBox {
        width: 90%;
        flex-direction: column;
        gap: 20px;
    }

    .bottomSection {
        grid-template-columns: 1fr;
    }

    .formBtnDiv {
        grid-column: 1 / -1;
        justify-content: center;
        flex-wrap: wrap;
    }

    .blackLineDiv {
        width: 95vw;
        margin-left: 5%;
    }

    .jobPositionSection {
        width: 100%;
    }

    .inpLabel{
        text-align: left;
    }
    .blackLineDiv{
        display:none
    }

    .defaultInp{
        width:98vw;
    }

    .locationSection{
        min-height: auto;
    }

    .jobPositionSection{
        min-height: auto;

    }
}

/* Height-based Adjustments */
@media screen and (min-height: 1024px) and (max-height: 1230px) {
    .field {
        margin-bottom: 2.3vh;
    }

    .blackLineDiv {
        width: 95vw;
        margin-left: 7%;
    }
}

/* Aspect Ratio Handling */
@media (min-aspect-ratio: 4/3) {
    .blackLineDiv {
        width: 97vw;
        margin-left: 5%;
    }


    /* Height-based Adjustments */
@media screen and (min-height: 1024px) and (max-height: 1230px) {
    .inputWrapper{
        max-width:26vw
    }

    .jobPositionSection{
        margin-right: 3.7vw;
    }
}










@media screen and (max-width: 1024px){
        .inputWrapper{
            max-width:26vw
        }

        .jobPositionSection{
            margin-right: 3.7vw;
        }

        .inputWrapper{
            max-width: 20vw;
        }





    }

}   


/* iPad Pro Vertical (1024x1366) */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .flexBox {
        width: 90%;
    }


    .selectWrapper{
        max-width: 26vw;
    }


    .locationSection{
        margin-left: 7vw;
    }

    .jobPositionSection{
        margin-right: 8.9vw;
    }


    .inpLabel{
        text-align:left;
    }
    .defaultInp {
        font-size: 16px;
    }

    .jobPositionSection {
        width: 100%;
        margin-right: 0;
    }

    .inputWrapper {
        max-width: 26vw;
    }

    .blackLineDiv {
        width: 95vw;
        margin-left: 5%;
    }
}

/* iPad Air 2 Specific Styles */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  
  /* Layout adjustments */
  .pageWrapper {
    padding-left: 15%;
    margin-left: 0;
    max-width: 85vw;
    max-height: 95vh;
  }

  /* Form layout */
  .flexBox {
    width: 80%;
    gap: 30px;
    margin-bottom: 20px;
  }

  /* Input fields */
  .inputWrapper {
    max-width: 35vw;
  }

  .defaultInp {
    width: 100%;
    font-size: 14px;
    padding: 8px 12px;
  }

  /* Select components */
  .selectWrapper {
    max-width: 35vw;
    margin-bottom: 15px;
  }

  /* Section adjustments */
  .bottomSection {
    grid-template-columns: 0.3fr 1fr;
    gap: 30px;
    margin-top: 20px;
  }

  .jobPositionSection {
    width: 100%;
    margin-right: 0;
    min-height: 200px;
  }

  .locationSection {
    margin-left: 0;
    min-height: 200px;
  }

  /* Divider line */
  .blackLineDiv {
    width: 90vw;
    margin-left: 12%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* Text sizing */
  .existingUser {
    font-size: 20px;
  }

  .contactInfo, 
  .jobPositionText, 
  .locationText, 
  .availabilityText {
    font-size: 16px;
  }

  /* Field spacing */
  .field {
    margin-bottom: 15px;
  }

  .inpLabel {
    margin-bottom: 5px;
    font-size: 14px;
  }

  /* Button adjustments */
  .formBtnDiv {
    margin-top: 30px;
    gap: 15px;
  }

  .formBtn, 
  .formBtn2, 
  .formBtn3 {
    padding: 10px 14px;
    font-size: 14px;
    width: 120px;
    height: 38px;
  }

  /* Availability section */
  .availabilityBox {
    margin-top: 20px;
  }

  .availabilityDay {
    width: 28px;
    height: 28px;
    font-size: 13px;
  }
}

/* iPad Air 2 Portrait Mode */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
  
  .pageWrapper {
    padding-left: 10%;
    padding-bottom:10%;
    max-height: 85vh;
  }

  .flexBox {
    width: 90%;
  }

  .jobPositionSection{
      min-height: 10vh;
  }
  .inputWrapper {
    max-width: 40vw;
  }

  .selectWrapper {
    max-width: 40vw;
  }

  .bottomSection {
    grid-template-columns: 0.4fr 1fr;
  }

  .blackLineDiv {
    margin-left: 15%;
  }
  .jobPositionSection{
    max-height: 10vh;
}
.locationSection{
    max-height: 10vh;

}
}

/* iPad Air 2 Landscape Mode */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
  
  .pageWrapper {
    padding-left: 12%;
    padding-bottom:15%;
  }

  .flexBox {
    width: 75%;
  }

  .inputWrapper {
    max-width: 30vw;
  }

  .selectWrapper {
    max-width: 30vw;
  }

  .bottomSection {
    grid-template-columns: 0.25fr 1fr;
  }

  .blackLineDiv {
    margin-left: 10%;
    display:none
  }
  .jobPositionSection{
    max-height: 10vh;
}
.locationSection{
    max-height: 10vh;

}

.availabilityBox{
    margin-top: -3%;
}
}
