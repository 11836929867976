.componentDiv {
    width: 200px;
}

.mainCal {
    display: block;
    width: 298px;
    height: 255px;
    display: block;
    position: relative;
    color: black;
    background-color: #f0f0f3;
    z-index: 99999;
    display: flex;
    border: 3px solid black;
    padding: .5%;

}

.CaldayCell {
    height: 25px;
    width: 14%;
    background-color: white;
    margin: .3%;
    border: 1px solid black;
    padding: .3%;
    font-size: large;

}


.fakeInputBox {
    width: 90%;
    font-size: larger;
}

.monthTitle {
    margin-left: 111px;
    margin-right: 111px;
    font-size: 30px;
    color: var(--text1)
}

.prevbutton {
    height: 50%;
    margin-left: 1%;
    background-color: var(--primary);
    width: 27%;
    font-size: large;
    float:left;

}

.prevbutton:active {
    background-color: var(--highlight1);

}

.nextButton {
    float: right;

    height: 50%;
    width: 27%;
    background-color: var(--primary);


    font-size: large;
}

.nextButton:active {
    background-color: var(--highlight1);
}

.toolbar {
    display: flex;
    width: 100%;
    font-size: larger;
}

.calDateRow {
    display: flex;
}

.headerCell {
    margin: .3%;
    padding: .3%;
    height: 50px;
    width: 14%;
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card {
    transform: scale(.7, .7);
}

.headerRow {
    display: flex;
    height: 35px;
    text-align: center;
    font-size: larger;
}

.starIconCalendar {
    font-size: 20px;

}

.starIconCalendar2 {
    font-size: 30px;
}

.whiteText {
    color: white;
}

.blackText {
    color: black;
}

.transform {
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -o-transition: all 2s ease;
    -ms-transition: all 2s ease;
    transition: all 2s ease;
}

.transform-active {
    height: 350px;
    width: 74%;
    background-color: white;
    margin: .3%;
    border: 1px solid black;
    padding: 1%;
    font-size: large;
}

.dayHighlightListWrapper {
    overflow-y: scroll;
    width: 100%;
    height: 80%;
    padding-top: 5%;
    padding: 5%;
}

.eventInDateDisplayHighlight {
    margin-bottom: 4%;
    box-shadow: rgb(6 24 44 / 40%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset
}



@media (min-aspect-ratio: 16/9) {

    .componentDiv {
        width: 200px;
    }

    .closeBtn {
        margin-top: 1%;
        width: 80px;
        height: 33px;
        margin-right: 2%;
        border-radius: 5px;
        background-color: orangered;
        margin-left: 0%;
        float: left;
    }

    .doneBtn {
        margin-top: 1%;
        width: 80px;
        height: 33px;
        margin-right: 2%;
        border-radius: 5px;
        background-color: #fdf0df;
        margin-left: 0%;
        float: right;
    }

    .mainCal2 {
        display: block;
        width: 199px;
        height: 40px;
        display: block;
        position: relative;
        color: black;
        z-index: 500;
        display: flex;
        padding: .5%;
    }


    .mainCal {
        display: block;
        width: 298px;
        height: 255px;
        display: block;
        position: relative;
        color: black;
        background-color: #f0f0f3;
        z-index: 99999;
        display: flex;
        border: 3px solid black;
        padding: .5%;

    }

    .CaldayCell {
        height: 25px;
        width: 14%;
        background-color: white;
        margin: .3%;
        border: 1px solid black;
        padding: .3%;
        font-size: large;

    }


    .fakeInputBox {
        width: 90%;
        font-size: larger;
    }

    .monthTitle {
        margin-left: 0px;
        margin-right: 0px;
        font-size: 20px;
        color: var(--text1)
    }

    .prevbutton {
        height: 50%;
        margin-left: 1%;
        background-color: var(--primary);
        width: 27%;
        float:left;
        font-size: large;
    }

    .prevbutton:active {
        background-color: var(--highlight1);

    }

    .nextButton {
        float: right;

        height: 50%;
        width: 27%;
        background-color: var(--primary);
    
    
        font-size: large;
    }

    .nextButton:active {
        background-color: var(--highlight1);
    }

    .toolbar {
        display: block;
        width: 100%;
        font-size: larger;
    }

    .calDateRow {
        display: flex;
    }

    .headerCell {
        margin: .3%;
        padding: .3%;
        height: 50px;
        width: 14%;
        color: black;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .card {
        transform: scale(.7, .7);
    }

    .headerRow {
        display: flex;
        height: 35px;
        text-align: center;
        width: 100%;
        font-size: larger;
    }

    .starIconCalendar {
        font-size: 20px;

    }

    .starIconCalendar2 {
        font-size: 30px;
    }

    .whiteText {
        color: white;
    }

    .blackText {
        color: black;
    }

    .transform {
        -webkit-transition: all 2s ease;
        -moz-transition: all 2s ease;
        -o-transition: all 2s ease;
        -ms-transition: all 2s ease;
        transition: all 2s ease;
    }

    .transform-active {
        height: 350px;
        width: 74%;
        background-color: white;
        margin: .3%;
        border: 1px solid black;
        padding: 1%;
        font-size: large;
    }

    .dayHighlightListWrapper {
        overflow-y: scroll;
        width: 100%;
        height: 80%;
        padding-top: 5%;
        padding: 5%;
    }

    .eventInDateDisplayHighlight {
        margin-bottom: 4%;
        box-shadow: rgb(6 24 44 / 40%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset
    }



}








.CaldayCell {
    height: 25px;
    width: 14%;
    margin: .3%;
    border: 1px solid black;
    padding: .3%;
    font-size: large;
}

.CaldayCell--disabled {
    background-color: #9b9b9b !important;
    color: #666;
    cursor: not-allowed;
}















@media screen and (max-width: 1199px) and (min-width: 768px)  {


    .toolbar{
        overflow: hidden;
        width:100%;
        display:flex;
    }



    .prevbutton{
        width:30%;
        margin-right: 0%;
        float:0

    }

    .monthTitle {
        margin: auto;
        font-size: 1rem;
        color: var(--text1)
    }

    .nextButton{
        float:0
    }


    .mainCal{
        font-size: 1rem;
        overflow: hidden;
    }
    .popupbtn{
       overflow: hidden;
        width:100%;
        display:flex;
    }
  }







@media screen and (max-width: 1199px) and (min-width: 768px) and (orientation: landscape) {


    .toolbar{
        overflow: hidden;
        width:100%;
        display:flex;
    }



    .prevbutton{
        width:30%;
        margin-right: 0%;
        float:0

    }

    .monthTitle {
        margin: auto;
        font-size: 1rem;
        color: var(--text1)
    }

    .nextButton{
        float:0
    }


    .mainCal{
        font-size: 1rem;
        overflow: hidden;
    }
    .popupbtn{
       overflow: hidden;
        width:100%;
        display:flex;
    }
  }
  
  
  @media screen and (max-width: 480px) {
 
  }
  
  @media screen and (min-width: 480px) and (max-width: 540px) {
 
  }
  
  @media screen and (min-width: 360px) and (max-width: 480px) {

  }
  
  @media screen and (min-width: 390px) and (max-width: 450px) {

  }
  
  @media screen and (min-width: 390px) and (max-width: 400px) {
 
  }
  
  @media screen and (min-width: 350px) and (max-width: 360px) {

  }
  
  @media screen and (min-height: 350px) and (max-height: 700px) {

  }
  
  @media screen and (min-height: 700px) and (max-height: 900px) {

  }
  
  @media screen and (min-width: 540px) and (max-width: 720px) {

  }
  
  @media screen and (min-width: 1015px) and (max-width: 1340px) {

  }
  
  @media (max-width: 768px) {

  }
  
  
  /* Add these specific fixes for iPad Air 2 and similar tablets */
  @media screen and (min-width: 768px) and (max-width: 1024px) {

  }
  
  /* Specific iPad Air 2 landscape mode */
  @media screen 
    and (min-width: 1024px) 
    and (max-height: 768px) 
    and (orientation: landscape) {

  }
  
  /* iPad Air 2 portrait specific adjustments */
  @media screen 
    and (max-width: 768px) 
    and (min-height: 1024px) 
    and (orientation: portrait) {

  }
  
  
  @media screen and (max-width: 375px) {

  }
  
  /* 19.5/9 aspect ratio for mobile */
  @media screen and (max-aspect-ratio: 19.5/9) {
  
  }
  
  
  
  