.reportingGroupForm {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 14.3%;
    left: 21.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.editArea {
    height: 400px;
}
.monthSection{
    display: inline-block;
    margin-left: -64%;
    margin-bottom: 4%;
    margin-top: 2%;
}

.taskInput {

    background-color: rgb(252, 239, 223);
    width: 193px;
    height: 48px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(189, 189, 189);
    border-radius: 3px;
    font-family: var(--font_default);
    font-size: 14px;
    font-weight: 400;
    color: rgb(61, 61, 61);
    opacity: 1;
}

.deleteBtn{
    height:47px;
    width:53px;

}

.addBtn {
    margin-left: 20%;
    margin-right: 3%;
    height: 47px;
    width: 53px;
}

.inputSection {

    width: 98%;
}


.blackLineDiv2 {
    color: black;
    height: 1px;
    background-color: black;
    margin-bottom: 1%;
    width: 104.1%;
    margin-top: 1.5%;
    left: -2%;
    position: relative;
    z-index: 1;
}

.taskName {}

.pageWrapper {
  text-align: left;
  display: block;
  position: relative;
  left: 0%;
  top: 0%;
  height: 98%;
  width: 90%;
  left: 0%;
  padding-left: 10%;
}

.flexBox {
    display: flex;
}

.addToGroups {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 16.3%;
    left: 22.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.inpLabel {
    width: 215px;
}

.defaultInp {
    margin-left: 2%;
    height: 30px;
    width: 265px;
    border-radius: 5px;
    background-color: #fceddf;
    border: 2px solid #8080805c;
}

.existingUser {
    margin-left: 1%;
}

.inlineBox {
    display: inline;
}


.createCatagoryTextBtn {
    margin-left: 22%;
    margin-right: 30%;
}


.destinationTypeSelect{
    margin-left: 6.5%;
    width: 280px;
}


.frequencySelect{
    margin-left: 7%;
    width: 280px;
}


.jobSelect {
    width: 30%;
    margin-left: 10%;
}

.userSelect {
    position: relative;
    box-sizing: border-box;
    padding-top: .7%;
    border-radius: 5px;
    margin-left: 11%;


    width: 193px;
    height: 48px;
    font-family: var(--font_default);
    font-size: 14px;
    font-weight: 400;
    color: rgb(61, 61, 61);
    opacity: 1;


}


.field {
    margin-top: 5%;
    margin-left: 1%;

    width: 100%;
    display: inline-flex;


}


.contactInfo {
    margin-left: 1%;
}


.leftCol {
    width: 40%;
    float: 0;
    text-align: left;
}

.rightCol {
    width: 60%;
    text-align: left;
    float: 1;
}



.availabilityDay {
    width: 36px;
    height: 20px;
    background-color: white;
    border: 2px solid black;
    padding: 1.1%;
    border-radius: 5px;
    margin-left: 3%;
    margin-top: 2%;
}

.rowOfBoxes {
    display: flex;
}

.availabilityBox {
    display: inline-block;

}

.inputArea {
    display: flex;
    margin-top: 1.5%;
}

.sectionSelect {
    margin-left: 10%;
}

.bottomSection {
    position: relative;
    width: 70%;
}

.selectGrouper {
    width: 57%;
    text-align: left;
    margin-left: 2%
}

.locationText {
    margin-top: 3%;

}

.formBtnDiv {
    width: 38%;
    margin-left:105%;
}


.formBtn {
    background-color: rgb(255, 255, 255);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_text_default);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(47, 47, 47);
    border-radius: 1px;
    padding: 12px 16px;
    opacity: 1;
    margin-right: 5%;
    cursor: pointer;
    top: 857px;
    left: 1064px;
    width: 135px;
    height: 38px;
    z-index: 6;
}


.formBtn2 {
    background-color: rgb(252, 143, 20);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_primary_contrast_default);
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    top: 857px;
    left: 1373px;
    width: 135px;
    height: 38px;
    z-index: 4;
}


.sectionContent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .dataEntrySection,
  .checklistSection,
  .visualSection,
  .responseSection {
    flex: 1;
    overflow-y: auto;
  }
  
  .dataEntryHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .dataEntryField,
  .checklistField,
  .visualField,
  .responseField {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .itemSelectContainer {
    width: 200px;
    margin-right: 10px;
  }
  
  .includeParCheckbox,
  .triggerPurchaseCheckbox {
    margin-left: 10px;
  }
  
  .parValueInput {
    width: 100px;
    margin-left: 10px;
  }
  
  .userResponseField {
    margin-left: 10px;
  }
  
  .userResponseInput {
    width: 200px;
  }
  
  .dataEntryActions,
  .responseActions {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .checklistDescription {
    width: 200px;
    margin-right: 10px;
  }
  
  .checklistAnswerChoices {
    display: flex;
    align-items: center;
  }
  
  .answerChoiceInput {
    width: 100px;
    margin-right: 10px;
  }
  
  .adminImage {
    width: 200px;
    margin-bottom: 10px;
  }
  
  .visualFieldContent {
    display: flex;
    align-items: center;
  }
  
  .visualFileUpload {
    margin-right: 10px;
  }
  
  .fileInput {
    display: none;
  }
  
  .fileInputText {
    cursor: pointer;
  }
  
  .uploadIcon {
    margin-left: 5px;
  }
  
  .visualDescription {
    flex: 1;
  }
  
  .visualDescriptionInput {
    width: 100%;
  }
  
  .responseColumn {
    margin-right: 10px;
  }
  
  .responseColumnInput {
    width: 200px;
  }



  .pageWrapper{
    text-align: left;
    display: block;
    position: relative;
    left: 0%;
    top: 0%;
    height: 100vh;
    width: 96vw;
    left: 0%;
    overflow-y: scroll;
    padding-left: 6%;
  }




  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Add a high z-index value */

  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
  }
  
  .modalButtons {
    margin-top: 20px;
  }
  
  .modalButtons button {
    margin: 0 10px;
  }





















@media (max-width:1024px){
.pageWrapper{
  text-align: left;
  display: block;
  position: relative;
  left: 0%;
  top: 0%;
  height: 100vh;
  width: 95vw;
  left: 0%;
  overflow-y: scroll;
  padding-left: 8%;
}

.inputSection{
  font-size: 1.5rem;
}
  
}



  
@media (max-width: 992px) {
  .flexBox {
      width: 80%;
  }
}

@media (max-width: 768px) {
  .pageWrapper {
      max-width: 100vw;
      padding-right: 3%;
      padding-left: 3%;
  }

  .selectandbuttonwrapper {
      flex-direction: column;
      align-items: stretch;
  }

  .selectWrapper {
      width: 100%;
      margin-bottom: var(--spacing-unit);
  }

  .createLocationWrapper {
      margin-left: 0;
  }
  .userForm{
      margin-left: 10vw;
  }

  .flexBox {
      width: 100%;
      flex-direction: column;
  }

  .locationInfoSection{
      margin-left: 12vw;
  }
  .leftCol, .rightCol {
      flex: 1 1 100%;
  }

  .formBtnDiv {
      flex-direction: column;
      margin-top: 2rem;
  }

  .formBtn, .formBtn2, .formBtn3 {
      width: 100%;
  }

  .defaultInp {
      width: 50vw;
  }
}

@media (max-width: 576px) {
  :root {
      --input-width: 100%;
      --wrapper-width: 100%;
  }

  .pageWrapper {
      padding-right: 2%;
      padding-left: 2%;
      width: 59vw;
      padding-right: 9%;
  
  }

  .blackLineDiv {
      width: 100vw;
      margin-left: -2%;
      z-index: 1;
  }
}




/* iPad Air 2 Portrait Mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {

    .pageWrapper {
        padding-left: 9%;
        padding-bottom: 10%;
        max-height: 85vh;
        width:92vw
    }

}

/* iPad Air 2 Landscape Mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
    .pageWrapper {
        padding-left: 10%;
        padding-bottom: 10%;
        max-height: 85vh;
    }
}