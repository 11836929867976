.pageWrapper {
    margin-left: 3%;
    max-width: 96vw;
    max-height: 93vh;
    min-height: 60vh;
    overflow-x: hidden;
    /* padding-right: 11%; */
    padding-left: 1%;
    min-width: 91vw;
    /* padding-bottom: 14%;*/

}

.pageWrapperScroll {
    overflow-y: auto;
}

.userForm {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding-right: 9%;
}

.userFormContent {
    width: 99%;
}

.existingUser {
    font-size: 1.5rem;
    color: #333;
    margin: 0 0 1rem 0;
    text-align: left;
}

.selectandbuttonwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.selectWrapper {
    width: 300px;
}

.reportingGroupSelect {
    width: 100%;
}

.createReportingGroupWrapper {
    margin-left: 1rem;
}

.reportingGroupInfoSection {
    margin-bottom: 1rem;
}

.contactInfo {
    font-size: 1.125rem;
    margin-bottom: 1rem;
    text-align: left;
}

.flexBox {
    display: flex;
    width: 18%;
    gap: 1rem;
    flex-wrap: wrap;
}

.leftCol, .rightCol {
    width: 100%;
}

.field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
}

.inpLabel {
    margin-bottom: 0.5rem;
    font-weight: 500;
    text-align: left;
}

.defaultInp {
    width: 281px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    text-align: left;
}



.wrapper {
    position: relative;
    margin-bottom: 1rem;
    width: 300px;
}


.icon {
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 19vw;
}

.addItemInp {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: white;

    background-color: black;
    color: white;
    width: 15vw;
    text-align: left;
    margin-left: 0vw;

    width: 13.3vw;

}

.cataSelectWrapper {
    margin-bottom: 1rem;
    width: 300px;
}

.bucketSelect {
    width: 100%;
}

.gridHolder {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.cardDiv {
    flex: 1 1 calc(33.333% - 1rem);
    min-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    background-color: #f9f9f9;
}

.centeredArea {
    text-align: center;
}

.blackLineDiv {
    height: 1px;
    background-color: #ccc;
    margin-left: -10%;
    width: 115vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.formBtnDiv {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    margin-top: 23%;
    padding-right: 12%;
}

.formBtn, .formBtn2, .formBtn3 {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9375rem;
    text-align: center;
    min-width: 100px;
}

.formBtn {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
}

.formBtn2 {
    background-color: #f97316;
    color: white;
    border: none;
}

.formBtn3 {
    background-color: #4a90e2;
    color: white;
    border: none;
}



@media (max-width: 768px) {
    .selectandbuttonwrapper {
        flex-direction: column;
        align-items: stretch;
    }

    .selectWrapper {
        width: 100%;
        margin-bottom: 1rem;
    }

    .createReportingGroupWrapper {
        margin-left: 0;
    }

    .cardDiv {
        flex: 1 1 100%;
    }

    .formBtnDiv {
        flex-direction: column;
    }

    .formBtn, .formBtn2, .formBtn3 {
        width: 100%;
    }

    .flexBox {
        width: 100%;
    }

    .defaultInp,
    .wrapper,
    .addItemInp,
    .cataSelectWrapper {
        width: 100%;
    }

    @media (max-width: 600px) {
        .pageWrapper {
            margin-left: 1%;
            max-width: 60vw;
            max-height: 93vh;
            min-height: 60vh;
            overflow-x: hidden;
            padding-right: 9%;
            padding-left: 1%;
        }
    }



}





.reportingGroupSection {
    width: 84%;
    position: relative;
    z-index: 1;
}

.reportingGroupText {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.fakeSelect {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    text-align: left;
    cursor: pointer;
}

.outsidePopup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 2;
}

.optionArea {
    padding: 0.5rem;
}

.subCategory {
    margin-left: 1.25rem;
    cursor: pointer;
}

.selectedArea {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.catagoryItem {
    border: 1px solid orange;
    color: orange;
    padding: 0.3125rem 0.625rem;
    border-radius: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
    .optionArea {
        text-align: left;
    }

    .subCategory {
        margin-top: .5vh;
        margin-left: 2.5rem;
    }
}

@media (max-width: 768px) {
    .reportingGroupSection {
        width: 100%;
    }

    .outsidePopup {
        max-height: 12vh;
    }
}

@media screen and (max-height: 600px) {
    .reportingGroupSection {
        width: 100%;
    }
}

@media screen and (min-height: 601px) and (max-height: 800px) {
    .reportingGroupSection {
        width: 100%;
    }
}

@media screen and (min-height: 801px) and (max-height: 1024px) {
    .reportingGroupSection {
        width: 100%;
    }
}

@media screen and (min-height: 1024px) and (max-height: 1230px) {
    .reportingGroupSection {
        width: 100%;
    }
}

@media screen and (min-height: 1230px) and (max-height: 1374px) {
    .reportingGroupSection {
        width: 100%;
    }

    .outsidePopup {
        max-height: 12vh;
    }
}

@media (min-aspect-ratio: 4/3) {
    .reportingGroupSection {
        width: 84%;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .reportingGroupSection {
        width: 100%;
    }
}



/* iPad Pro Vertical (1024x1366) */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .flexBox {
        width: 90%;
    }

    .selectWrapper{
        max-width: 26vw;
    }

    .locationSection{
        margin-left: 7vw;
    }

    .jobPositionSection{
        margin-right: 8.9vw;
    }

    .inpLabel{
        text-align:left;
    }
    .defaultInp {
        font-size: 16px;
    }

    .jobPositionSection {
        width: 100%;
        margin-right: 0;
    }

    .inputWrapper {
        max-width: 26vw;
    }

    .blackLineDiv {
        width: 105vw;
        margin-left: -5vw;
    }


    .pageWrapper{
        margin-left: 6%;
        max-width: 94vw;
        max-height: 93vh;
        min-height: 60vh;
        overflow-x: hidden;
        padding-left: 1%;
        min-width: 91vw;
    }


    .addItemInp{

        padding: 0.5rem 0.5rem 0.5rem 2rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;

        background-color: black;
        color: white;
        width: 15vw;
        text-align: left;
        margin-left: -10vw;
    }
}





/* iPad Pro Vertical (1024x1366) */
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .flexBox {
        width: 90%;
    }

    .selectWrapper{
        max-width: 26vw;
    }

    .locationSection{
        margin-left: 7vw;
    }

    .jobPositionSection{
        margin-right: 8.9vw;
    }

    .inpLabel{
        text-align:left;
    }
    .defaultInp {
        font-size: 16px;
    }

    .jobPositionSection {
        width: 100%;
        margin-right: 0;
    }

    .inputWrapper {
        max-width: 26vw;
    }

    .blackLineDiv {
        width: 105vw;
        margin-left: -5vw;
    }


    .pageWrapper{
        margin-left: 6%;
        max-width: 94vw;
        max-height: 93vh;
        min-height: 60vh;
        overflow-x: hidden;
        padding-left: 1%;
        min-width: 91vw;
    }


    .addItemInp{

        padding: 0.5rem 0.5rem 0.5rem 2rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;

        background-color: black;
        color: white;
        width: 15vw;
        text-align: left;
        margin-left: -10vw;
    }

    
    .icon{
        margin-left: 15.6vw;
    }


    .wrapper{
        position: relative;
        margin-bottom: 1rem;
        width: 291px;
        margin-left: 3.4vw;
    }
}


/* iPad Air 2 Portrait Mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {
    .icon {
        font-size: 2rem;
        margin-left: 39vw;
        right: -4rem;
    }

    .wrapper .icon:last-child {
        left: 1rem;
    }

    .addItemInp {
        font-size: 1.25rem;
        padding: 0.75rem 3rem;
    }
    .pageWrapper {
        padding-left: 10%;
        padding-bottom: 10%;
        max-height: 85vh;
    }
    .defaultInp {
        font-size: 1.125rem;
        padding: 0.625rem;
    }

    .formBtn,
    .formBtn2,
    .formBtn3 {
        font-size: 1rem;
        padding: 0.875rem 1.125rem;
        height: 48px;
    }

}

/* iPad Air 2 Landscape Mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
    .pageWrapper {
        padding-left: 10%;
        padding-bottom: 10%;
        max-height: 85vh;
    }
    .icon {
        font-size: 1.75rem;
        right: 0.75rem;
    }

    .wrapper .icon:last-child {
        left: 0.75rem;
    }

    .addItemInp {
        font-size: 1.125rem;
        padding: 0.625rem 2.5rem;
    }
    .defaultInp {
        font-size: 1.0625rem;
        padding: 0.5625rem;
    }

    .formBtn,
    .formBtn2,
    .formBtn3 {
        font-size: 0.96875rem;
        padding: 0.8125rem 1.0625rem;
        height: 44px;
    }
}

