:root {
  --transition-speed: 0.3s;
  --base-shadow: rgba(0, 0, 0, 0.09);
  --background-color: #f5f5f5;
  --secondary-background: #d0d1d3;
}

/* Base styles */
.App {
  overflow: hidden;
  margin-top: -4%;
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--background-color);
  display: flex;
  box-shadow:
    var(--base-shadow) 0px 2px 1px,
    var(--base-shadow) 0px 4px 2px,
    var(--base-shadow) 0px 8px 4px,
    var(--base-shadow) 0px 16px 8px,
    var(--base-shadow) 0px 32px 16px;
  transition: all var(--transition-speed) ease;
  overflow-y: scroll;
}

.background1 {
  background-color: var(--secondary-background);
  padding: 2%;
  transition: padding var(--transition-speed) ease;
}

.mainArea {
  width: 100%;
  transition: width var(--transition-speed) ease;
}

.welcomeText {
  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all var(--transition-speed) ease;
}

/* Logo styles */
.logo2 {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  transition: all var(--transition-speed) ease-in-out;
}

/* Large screens */
@media screen and (min-width: 1200px) {
  .App {
    margin: 0 auto;
    margin-top: -2%;
  }

  .logo2 {
    transform: scale(1);
    margin: auto;
  }
}

/* Tablets (landscape) */
@media screen and (max-width: 1199px) and (min-width: 768px) and (orientation: landscape) {
  .App {
    height: auto;
    min-height: 100vh;
    width: 100vw;
  }

  .background1 {
    padding: 1%;
  }


}

/* Tablets (portrait) */
@media screen and (max-width: 1199px) and (min-width: 768px) {
  .App {
    height: auto;
    min-height: 100vh;
    width: 100vw;
    max-height: 100vh;
    min-height: 100vh;  
    margin-left: -1%;
    margin-top: -1%;
  }

  .background1 {
    padding: 1%;
  }

}

/* Small tablets and large phones */
@media screen and (min-width: 481px) and (max-width: 767px) {
  .App {
    transform: scale(0.97);
    margin-left: -4%;
    width: 103vw;
    min-height: 103vh;
    margin-top: -2%;
  }


}

/* Phones */
@media screen and (max-width: 480px) {
  .App {
    height: auto;
    min-height: 100vh;
    width: 100vw;
    max-height: 100vh;
    min-height: 100vh; 
    margin: 1% auto;
    transform: scale(0.96);
  }

  .welcomeText {
    top: 20%;
  }


}

/* Small phones */
@media screen and (max-width: 420px) {
  .App {
    transform: scale(0.94);
  }


}

/* iPhone SE and similar-sized phones (portrait) */
@media only screen and (min-device-width: 360px) and (max-device-width: 432px) and (orientation: portrait) {
  .App {
    transform: scale(0.95);
    margin-left: -5%;
    margin-top: -7%;
    width: 105vw;
    height: 105vh;
    min-height: 105vh;
  }

  .sidebar {
    height: 100vh;
    padding-bottom: 3vh;
  }

  .expandbutton2 {
    margin-top: 2vh;
  }


  .welcomeText {
    top: 25%;
  }
}

/* iPhone 12 Pro and similar-sized phones (portrait) */
@media screen and (max-width: 375px) {
  .App {
    transform: scale(0.98);
    margin-left: -2%;
    margin-top: -2%;
    width: 108vw;
  }

  .sidebar {
    height: 102vh;
    padding-bottom: 5vh;
  }

  .expandbutton2 {
    margin-top: 1vh;
  }


}

/* 16:9 aspect ratio */
@media screen and (aspect-ratio: 16/9) {
  .App {
    height: auto;
    min-height: 100vh;
  }

  .welcomeText {
    top: 40%;
  }


}

/* 19.5/9 aspect ratio for mobile */
@media screen and (aspect-ratio: 19.5/9) {
  .App {
    height: auto;
    min-height: 100vh;
  }

  .welcomeText {
    top: 15%;
  }

}

/* Landscape mode */
@media (orientation: landscape) and (max-height: 500px) {
  .app{
    margin-left: -4%;
    width: 103vw;
    }

  .welcomeText {
    top: 10%;
  }


}




/* Progressive scaling for height changes */
@media screen and (min-height: 501px) and (max-height: 700px) {
  .welcomeText {
    top: 25%;
  }
}

@media screen and (min-height: 701px) and (max-height: 900px) {
  .welcomeText {
    top: 35%;
  }
}

/* Large landscape screens */
@media screen and (min-height: 768px) and (max-height: 1400px) and (orientation: landscape) {
  .App {
    width: 99vw;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;  


    margin-left: -4%;
    width: 103vw;
    min-height: 103vh;
    margin-top: -2%;
  
  }

}

/* Ensure smooth transitions during resize */
* {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

.welcome-large,
.welcome-medium,
.welcome-small {
  transition: all var(--transition-speed) ease-in-out;
}
@media (orientation: landscape) and (max-height: 375px) {
  .app{
    margin-left: -4%;
    width: 103vw;
    }
}



@media screen and (min-width: 550px) and (max-width: 1300px) and (max-height: 600px) {



}
