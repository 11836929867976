:root {
    --main-width: 80vw;
    --spacing-unit: 1rem;
    --input-width: 100%;
    --select-width: 300px;
    --transition-speed: 0.3s;
}

/* Base Layout */
.pageWrapper {
    margin-left: 2%;
    max-width: var(--main-width);
    max-height: 93vh;
    min-height: 60vh;
    overflow-x: hidden;
    padding-left: 9%;
    transition: all var(--transition-speed) ease;
}

.pageWrapperScroll {
    overflow-y: auto;
}

/* Form Layout */
.userForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 91.8%;
    transition: all var(--transition-speed) ease;
}

.existingUser {
    font-size: 24px;
    color: #333;
    margin: 0;
    transition: font-size var(--transition-speed) ease;
}

/* Select and Button Layout */
.selectandbuttonwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: all var(--transition-speed) ease;
}

.selectWrapper {
    width: var(--select-width);
    transition: width var(--transition-speed) ease;
}

.locationSelect {
    width: 100%;
}

.createLocationWrapper {
    margin-left: auto;
}

/* Location Info Section */
.locationInfoSection {
    margin-bottom: 20px;
    transition: margin var(--transition-speed) ease;
}

.contactInfo {
    font-size: 18px;
    margin-bottom: 15px;
    transition: all var(--transition-speed) ease;
}

.flexBox {
    display: flex;
    width: 43%;
    gap: 60px;
    transition: all var(--transition-speed) ease;
}

.leftCol, .rightCol {
    flex: 1;
    transition: all var(--transition-speed) ease;
}

/* Input Fields */
.field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5vh;
    transition: margin var(--transition-speed) ease;
}

.inpLabel {
    margin-bottom: 0.5vh;
    font-weight: 500;
}

.defaultInp {
    width: var(--input-width);
    padding: 1vh 1vw;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1vw;
    transition: all var(--transition-speed) ease;
}

/* Divider */
.blackLineDiv {
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
    width: 92vw;
    position: absolute;
    left: 0;
    transform: translateX(-2%);
    margin-left: 10%;
    transition: all var(--transition-speed) ease;
}

/* Bottom Section */
.bottomSection {
    display: flex;
    justify-content: flex-end;
    width: 82%;
    margin-top: 3%;
    transition: all var(--transition-speed) ease;
}

/* Buttons */
.formBtnDiv {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
    transition: all var(--transition-speed) ease;
}

.formBtn, .formBtn2, .formBtn3 {
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    line-height: 1;
    width: 135px;
    height: 42px;
    transition: all var(--transition-speed) ease;
}

.formBtn {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
}

.formBtn2 {
    background-color: #f97316;
    color: white;
    border: none;
}

.formBtn3 {
    background-color: #4a90e2;
    color: white;
    border: none;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modalButtons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modalButtons button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.modalButtons button:only-child {
    background-color: #007bff;
    color: white;
    border: none;
}

.modalButtons button:first-child {
    background-color: #f1f1f1;
    color: #333;
    border: none;
}

.modalButtons button:last-child {
    background-color: #f44336;
    color: white;
    border: none;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .pageWrapper {
        max-width: 95vw;
        padding-right: 5%;
    }

    .flexBox {
        width: 60%;
    }
}

@media (max-width: 768px) {
    .pageWrapper {
        padding-left: 5%;
    }

    .selectandbuttonwrapper {
        flex-direction: column;
        align-items: stretch;
    }

    .selectWrapper {
        width: 100%;
        margin-bottom: var(--spacing-unit);
    }

    .createLocationWrapper {
        margin-left: 0;
    }

    .flexBox {
        width: 100%;
        flex-direction: column;
        gap: 20px;
    }

    .formBtnDiv {
        flex-direction: column;
    }

    .formBtn, .formBtn2, .formBtn3 {
        width: 100%;
    }
}