:root {
    --spacing-unit: 1rem;
    --input-width: 325px;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.pageWrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 89vw;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8%;
    padding-left: 0%;
    box-sizing: border-box;
    left: 11%;
    
}

.pageWrapper::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    background-color: transparent;
}

.userForm {
    margin-top:3vh;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-unit);
}

.existingUser {
    font-size: 1.5rem;
    color: #333;
    margin: 0 0 var(--spacing-unit) 0;
}

.selectandbuttonwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.selectWrapper {
    width: 300px;
}

.vendorSelect {
    width: 345px;
}

.vendorInfoSection {
    margin-bottom: var(--spacing-unit);
}

.contactInfo {
    font-size: 1.125rem;
    margin-bottom: var(--spacing-unit);
    text-align: left;
}

.flexBox {
    display: flex;
    width: 100%;
    gap: var(--spacing-unit);
    flex-wrap: wrap;
}

.leftCol {
    flex: 1;
    min-width: 250px;
}

.threeInputRow, .twoInputRow {
    display: flex;
    gap: var(--spacing-unit);
    margin-bottom: var(--spacing-unit);
}

.threeInputRow > div, .twoInputRow > div {
    flex: 1;
}

.field {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-unit);
}

.inpLabel {
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.defaultInp {
    width: var(--input-width);
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.blackLineDiv {
    height: 1px;
    background-color: #ccc;
    margin: var(--spacing-unit) 0;
    width: 100%;
}

.bottomSection {
    display: flex;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit);
}

.rightBottomSection {
    margin-top: -1.4%;
}

.leftBottomSection, .rightBottomSection {
    flex: 1;
}

.orderDeadline, .paymentTerms {
    margin-top: var(--spacing-unit);
}

.availabilityBox {
    margin-top: 0.5rem;
}

.rowOfBoxes {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.availabilityDay {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
}

.formBtn1 {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    margin-right: 0.5rem;
}

.formBtn1.selected {
    background-color: orange;
    color: white;
}

.instructionsInput {
    width: 100%;
    height: 150px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.formBtnDiv {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 13%;
    width: 54%;
}

.formBtn, .formBtn2, .formBtn3 {
    padding: 0.75rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9375rem;
    text-align: center;
    line-height: 1;
    min-width: 135px;
    height: 42px;
}

.formBtn {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
}

.formBtn2, .formBtn3 {
    background-color: #f97316;
    color: white;
    border: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  
  .modalButtons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .modalButtons button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* For single-button popups, we can style it differently than the dual-button modals */
  .modalButtons button:only-child {
    background-color: #007bff;  /* Use your preferred primary color */
    color: white;
    border: none;
  }
  
  /* Keep your existing styles for two-button scenarios */
  .modalButtons button:first-child {
    background-color: #f1f1f1;
    color: #333;
    border: none;
  }
  
  .modalButtons button:last-child {
    background-color: #f44336;
    color: white;
    border: none;
  }

  



@media (max-width: 1200px) {
    :root {
        --input-width: 250px;
    }

    .userForm{
        width: 90vw;
    }

    .pageWrapper {
        padding-right: 1vw;
        width: 95vw;
        margin-left: -3vw;
        margin-top: 5vh;
    }

    .selectandbuttonwrapper {
        flex-direction: row;
        align-items: stretch;
        width: 86vw;
    }

    .selectWrapper, .vendorSelect {
        width: 100%;
        max-width: var(--input-width);
        margin-bottom: var(--spacing-unit);
    }

    .threeInputRow, .twoInputRow {
        flex-wrap: wrap;
    }

    .threeInputRow > div, .twoInputRow > div {
        flex: 0 0 calc(50% - var(--spacing-unit));
    }

    .defaultInp {
        width: 27vw;
    }

    .bottomSection {
        width: 88vw;
        flex-direction: column;
        text-align: left;
    }

    .formBtnDiv {
        width: 100%;
        justify-content: flex-end;
    }
}



@media (max-width: 768px) {
    :root {
        --input-width: 200px;
    }

    .pageWrapper {
        padding-right: 3%;
        padding-left: 3%;
        
    }

    .flexBox {
        flex-direction: column;
    }

    .leftCol, .rightCol {
        width: 100%;
    }

    .threeInputRow > div, .twoInputRow > div {
        flex: 0 0 100%;
    }

    .formBtnDiv {
        flex-direction: column;
        align-items: stretch;
    }

    .formBtn, .formBtn2, .formBtn3 {
        width: 100%;
        margin-bottom: var(--spacing-unit);
    }
}

@media (max-width: 480px) {
    :root {
        --input-width: 100px;
    }

    .existingUser {
        font-size: 1.25rem;
    }

    .contactInfo {
        font-size: 1rem;
    }

    .availabilityDay {
        width: 2rem;
        height: 2rem;
        font-size: 0.8rem;
    }

    .formBtn, .formBtn2, .formBtn3 {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    .defaultInp, .selectWrapper, .vendorSelect {
        width: 100%;
        max-width: var(--input-width);
    }

    .threeInputRow, .twoInputRow {
        flex-direction: column;
    }

    .threeInputRow > div, .twoInputRow > div {
        flex: 0 0 100%;
    }
}

@media (max-width: 320px) {
    :root {
        --input-width: 100px;
    }

    .existingUser {
        font-size: 1.25rem;
    }

    .contactInfo {
        font-size: 1rem;
    }

    .availabilityDay {
        width: 2rem;
        height: 2rem;
        font-size: 0.8rem;
    }

    .formBtn, .formBtn2, .formBtn3 {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    .defaultInp, .selectWrapper, .vendorSelect {
        width: 100%;
        max-width: var(--input-width);
    }

    .threeInputRow, .twoInputRow {
        flex-direction: column;
    }

    .threeInputRow > div, .twoInputRow > div {
        flex: 0 0 100%;
    }
}

