:root {
    --main-width: 90vw;
    --input-width: 43%;
}

.pageWrapper {
    margin-left: 2%;
    /* max-width: var(--main-width); */
    width: 92vw;
    max-height: 93vh;
    min-height: 60vh;
    overflow: hidden;
    padding-left: 9%;

}

.userForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-right: 13%;
}

.existingUser {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
}

.createUserWrapper {
    margin-right: 1%;
    margin-left: auto;
}

.selectWrapper {
    width: 100%;
    max-width: 300px;
}

.jobSelect {
    width: 100%;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  
  .modalButtons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .modalButtons button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* For single-button popups, we can style it differently than the dual-button modals */
  .modalButtons button:only-child {
    background-color: #007bff;  /* Use your preferred primary color */
    color: white;
    border: none;
  }
  
  /* Keep your existing styles for two-button scenarios */
  .modalButtons button:first-child {
    background-color: #f1f1f1;
    color: #333;
    border: none;
  }
  
  .modalButtons button:last-child {
    background-color: #f44336;
    color: white;
    border: none;
  }
.userInfoSection {
    margin-bottom: 2rem;
    width: var(--input-width);
}

.contactInfo {
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

.flexBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
}

.leftCol, .rightCol {
    flex: 1 1 calc(50% - 0.5rem);
    min-width: 250px;
}

.field {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
}

.inpLabel {
    margin-bottom: 0.3rem;
    font-weight: 500;
}

.defaultInp {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 42%;
}

.blackLineDiv {
    height: 1px;
    background-color: #ccc;
    margin: 2rem 0;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.bottomSection {
    display: grid;
    grid-template-columns: 0.22fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
}

.inputColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.permissionSection {
    margin-bottom: 1rem;
    height: 150px;
    overflow-y: auto;
    min-height: 280px;
    overflow-x: hidden;
}

.permissionText {
    font-size: 1.125rem;
    margin-bottom: 0.6rem;
}

.permissionWrapper {
    width: 100%;
}

.permissionBlock {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
}

.fixedTextWidth {
    margin-left: 0.6rem;
    width: 175px;
}

.formBtnDiv {
    grid-column: 2 / 3;
    display: flex;
    justify-content: flex-end;
    gap: 0.6rem;
    margin-top: 23.25rem;
    /* margin-left: -134%; */
    padding-right: 17%;
}

.formBtn, .formBtn2, .formBtn3 {
    padding: 0.75rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9375rem;
    text-align: center;
    line-height: 1;
    border: 1px solid rgb(47, 47, 47);
    opacity: 1;
    width: 135px;
    height: 42px;
    z-index: 6;
}

.formBtn2, .formBtn3 {
    background-color: #f97316;
    color: white;
    border: none;
}

@media (max-width: 1900px) {
    .pageWrapper{
        margin-left: 2%;
        max-width: var(--main-width);
        width: 98vw;
        max-height: 93vh;
        min-height: 60vh;
        overflow: hidden;
        padding-left: 9%;
        min-width: 92vw;
    }
}


@media (max-width: 1200px) {
    :root {
        --main-width: 90vw;
        --input-width: 100%;
    }

    .bottomSection {
        grid-template-columns: 1fr;
    }

    .formBtnDiv {
        grid-column: 1 / -1;
    }
}

@media (max-width: 768px) {
    .flexBox {
        flex-direction: column;
    }

    .leftCol, .rightCol {
        flex: 1 1 100%;
    }

    .formBtnDiv {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .userForm {
        flex-direction: column;
        align-items: flex-start;
    }

    .createUserWrapper {
        margin-left: 0;
        margin-top: 1rem;
    }

    .formBtn, .formBtn2, .formBtn3 {
        width: 100%;
    }
}


@media (min-aspect-ratio: 4/3) {

    .blackLineDiv{
        height: 1px;
        background-color: #ccc;
        margin: 20px 0;
        margin-bottom:3vh;
        width: 97vw;
        position: absolute;
        left: 0;
        transform: translateX(-2%);
        margin-left: 5%;
    }


}

