/* Base styles */
.reportingGroupForm {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 14.3%;
    left: 21.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
  }
  .custom-select {
    position: relative;
    width: 100%;
    user-select: none;
    touch-action: none;
}

.custom-select select {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    background-color: white;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select select.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Ensure smooth scrolling on iOS */
.custom-select select {
    -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.custom-select select::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-select select {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Prevent text selection during drag */
.custom-select select option {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  .otherFields2 {
    margin-left: 8%;
    display: flex;
    align-items: baseline;
    width: 100%;
    overflow-x: overlay;
  }
 
  .monthSection {
    display: inline-block;
    margin-left: -53%;
    margin-bottom: 4%;
    margin-top: 2%;
    text-align: left;
  }

  .headerWrapper{
     margin-top: 4vh;
     margin-left: 4.2%;
     margin-bottom: 10px;
  }
  
  .roundedGreyRec {
    border-radius: 5px;
    border: 1px solid lightgrey;
    height: 38px;
  }
  
  .calendarWrapper{
    z-index: 0;
    margin-left: 3vw;
  }

  .visualfieldSection{
    width: 370px;
    align-content: center;
    height: 46px 
          
  }

.checklistInput{
    margin-top: 0%; 
    text-align: center;
     width: 80px;

    margin-bottom: 3%

}



  .intervalSelect{
    width: 10vw;
    min-width:10vw;


  }
  .weekSection{
    margin-top: 10vh;
  }
  .monthSection{
    margin-top: 10vh;
  }
  .yearSection{
    margin-top: 10vh;

  }
  .imageUpload1{
    padding-right: 21% !important;
    width: 10vw;
    padding: 4.5%;
    text-align: center;
    align-content: center;
    display: block;
  }




  .assignUserSelect{
    /*  z-index: 2 !important;*/
  }

  .pageWrapper {
    padding-top: 1%;
    text-align: left;
    display: block;
    position: relative;
    left: 0%;
    top: 0%;
    padding-left: 1%;
    overflow-y: overlay;
    overflow-x: hidden;
    height: auto; /* 92.4vh;*/
    width: 98vw;
    min-height: 92.4vh;
    padding-bottom: 8vh;
    padding-left: 4%;
  }
  
  .typeOfSection {
    margin-top: 2%;
    margin-bottom: 3%;
    width: 22.1vw;
  }
  .pu3BoxDE{
    width: 30%;
    /* border: 1px solid grey; */
    padding: .5%;
    height: 100%;
    overflow-x: overlay;
    width: 98%;
    /* height: 112%; */
    /* max-height: 520px;*/
    width: 100%;
    
    height: 100%;
    overflow-y: visible;
    display:contents;
}
.sectionDiv.show-options {
    overflow-y: visible;
  }
  .bottomButtons {
    position: relative;
    display: flex;
    width: 100vw;
  }
  
  .buttonStyling {
    height: 2vh;
    width: 20vh;
    min-height: 4vh;
  }
  
  .itemSelect {
    position: relative;
    box-sizing: border-box;
    width: 90%;
    padding: 0%;
    margin-top: auto;
    margin-bottom: auto;

    margin-left: 1%;
    height: 30px;
    border-radius: 5px;
  }
  
  .saveBtn {
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    background-color: rgb(252, 143, 20);
    font-size: 15px;
    font-weight: 600;
    color: white;
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    align-self: flex-start;
    min-width: 135px;
    max-width: 135px;
    order: 9;
    min-height: 47px;
    width: 135px;
    flex-grow: 1;
    height: max-content;
    margin-left: 5%;
  }
  
  .cancelbtn {
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(163, 163, 163);
    border-radius: 3px;
    opacity: 1;
    cursor: pointer;
    align-self: flex-start;
    min-width: 135px;
    max-width: 135px;
    order: 8;
    min-height: 47px;
    width: 135px;
    flex-grow: 1;
    height: max-content;
  }
  
  .sectionDiv {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 101%;
    margin-left: 0%;
  }
  
  .includeParInput {
    height: 22px;
    border: 2px solid #cccccc;
    padding: 3%;
  }
  
  .closeButton {
    float: right;
    width: 30px;
    height: 30px;
    background-color: #ff0000ab;
    margin-bottom: -30%;
    border: 2px solid black;
    margin: 0%;
    padding: 0%;
    border-top: 0px;
    border-right: 0px;
  }
  
  .visualfieldSection {
    display: grid;
    margin-left: 1%;
    width: 170px;
  }
  
  .ChecklistfieldSection {
    display: grid;
    margin-left: 5%;
  }
  
  .sectionFieldWrapper {
    margin-bottom: 3%;
    margin-top: 2%;
    margin-left: 5%;
  }
  
  .DEfields {
    display: flex;
    padding-left: 4%;
    min-height: 100px;
  }

  .DEfieldSection {
    width: 15vw;
  }

  .triggerPurchaseCheckbox{
      align-content: center;

      margin-left: 2vw;
      margin-right: 2vw;
  }
  
  .ResfieldSection {
    display: grid;
    margin-left: 5%;
    width: 300px;
  }
  
  .checkboxes label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
  }
  
  .checkboxes input {
    vertical-align: middle;
  }
  
  .checkboxes label span {
    vertical-align: middle;
  }
  
  .addToGroups {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 16.3%;
    left: 22.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
  }
  .uploadIcon2{
    display: block;
    margin-top: -14%;
    margin-bottom: 12%;
    margin-left: 73%;
  }
  .uploadIcon{
    display: block;
    margin-top: -14%;
    margin-bottom: 12%;
    margin-left: 73%;
  }
  .inpLabel {
    width: 215px;
  }

  .enterPar{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0%;
    width:4vw;
    border-radius: 10px;
    background-color: white;
    border: 2px solid #8080805c;
    padding: .5%;
    align-content: center;
  }
  .ChecklistDescription{

    text-align: center;
    width: 100px;
    height: 45px;
    margin-top: 0%;
    margin-right: 2.2%;
    margin-bottom: 3% ;
  }

  .responseBox{
    width:11vw;
    height:3vw;
    border-radius:8px;
}

  .defaultInp {
    margin-top: 1%;
    margin-left: 0%;
    width: 300px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid #8080805c;
    padding: 1%;
  }
  
  .existingUser {
    margin-left: 1%;
  }
  
  .inlineBox {
    display: inline;
  }
  
  .userResponseText{
      margin-left: 8.4%;
  }

  .userResponse{
    height:2.2vh !important;
  }
  .checklistInput{
    height:2.2vh !important;
  }



  .createCatagoryTextBtn {
    margin-left: 22%;
    margin-right: 30%;
  }
  

  .onTheText{
    min-width: 2.6vw;
    margin-left: 0.1vw;
    margin-right: 0.5vw;
    margin-top: 0.6vh;
  }

  .userSelect {
    position: relative;
    box-sizing: border-box;
    padding: 0%;
    margin-top: 0.5%;
    margin-bottom: 2%;
    height: 30px;
    border-radius: 5px;
    width: 200px;
    margin-left: 0%;
  }
  
  .field {
    margin-top: 5%;
    margin-left: 1%;
    width: 100%;
    display: inline-flex;
  }
  
  .contactInfo {
    margin-left: 1%;
  }
  
  .leftCol {
    width: 40%;
    float: 0;
    text-align: left;
  }
  
  .rightCol {
    width: 60%;
    text-align: left;
    float: 1;
  }
  
  .availabilityDay {
    width: 36px;
    height: 20px;
    background-color: white;
    border: 2px solid black;
    padding: 1.1%;
    border-radius: 5px;
    margin-left: 3%;
    margin-top: 2%;
  }
  
  .rowOfBoxes {
    display: flex;
  }
  
  .availabilityBox {
    display: inline-block;
  }
  
  .inputArea {
    display: flex;
    margin-top: 1.5%;
  }
  
  .sectionSelect {
    margin-left: 10%;
  }
  
  .popup2FrequencySection {
    padding-left: 3vw;
    margin-top: 3vw;
  }
  
  .bottomSection {
    width: 100%;
    padding: 1%;
    left: 0px;
    top: 0px;
    position: relative;
  }
    
  .editArea{
    padding-left: 2vw;
}
  .selectGrouper {
    width: 57%;
    text-align: left;
    margin-left: 2%;
  }
  
  .locationText {
    margin-top: 3%;
  }
  
  .formBtnDiv {
    position: absolute;
    left: 60%;
    top: 87%;
    width: 38%;
  }
  
  .formBtn {
    background-color: rgb(255, 255, 255);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_text_default);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(47, 47, 47);
    border-radius: 1px;
    padding: 12px 16px;
    opacity: 1;
    margin-right: 5%;
    cursor: pointer;
    top: 857px;
    left: 1064px;
    width: 135px;
    height: 38px;
    z-index: 6;
  }
  
  .formBtn2 {
    background-color: rgb(252, 143, 20);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_primary_contrast_default);
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    top: 857px;
    left: 1373px;
    width: 135px;
    height: 38px;
    z-index: 4;
  }
  
  .popup2DestinationSection {
    margin-top: 3vw;
    padding-left: 3vw;
  }


  .pu2FrequencyWrapper{
      z-index: 50;
  }
  .popupMessage3 {
    z-index: 2;
    border: 2px solid black;
    width: 1300px;
    height: 750px;
    background-color: white;
    text-align: center;
    position: relative;
    left: 24vw;
    top: 8vh;
    position: absolute;
    visibility: visible;
    z-index:100;
    display:none;
    overflow: visible;
}

.popupMessage2 {
    z-index: 200;

    border: 2px solid black;
    width: 1000px;
    height: 650px;
    background-color: white;
    text-align: center;
    position: relative;
    left: 30vw;
    top: 6vh;
    position: absolute;
    visibility: visible;
    display: none;

    overflow-y: overlay;

    max-height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;

}

.popupMessage {
    z-index: 100;
    border: 2px solid black;
    width: 30vw;
    height: 30vw;
    background-color: white;
    text-align: center;
    position: relative;
    left: 45vw;
    top: 27vh;
    position: absolute;
    display:none;
    visibility: visible;
}

/* Base wrapper for the select */
.selectWrapperForm {
    display: flex;
    align-items: center;
    width: 15.4vw;
    min-width: 15.4vw;
    height: 5vh;
    margin-left: 1%;
    margin-right: 2%;
}

/* Parent wrapper for the entire row */
.editWrapper {
    display: flex;
    align-items: center;
    padding: 8px 0;
    gap: 8px;
    width: 100%;
    min-height: 38px;
    margin-bottom: 8px;
    overflow-x: scroll;
    overflow-y: visible;
}   

/* Checkbox styles */
.dataCheckbox {
    margin: 0 8px;
    align-self: center;
}

/* Select component styles */
.select2 {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    border-radius: 5px;
    margin: 0;
    display: flex;
    align-items: center;
}

/* Input field styles */
.enterPar {
    width: 4vw;
    min-width: 60px;
    height: 38px;
    margin: 0 8px;
    border-radius: 5px;
    border: 1px solid #d0d1d3;
    padding: 0 8px;
}

/* Checkbox container */
.triggerPurchaseCheckbox {
    display: flex;
    align-items: center;
    margin: 0 8px;
    height: 38px;
}

/* Make sure select height matches other elements */
select {
    height: 38px !important;
    padding: 0 8px;
    border: 1px solid #d0d1d3;
    border-radius: 5px;
    background-color: white;
    width: 100%;
}

.topAreaPu3{
    overflow: hidden;
    margin-left: 2.4%;
    text-align: left;
    margin-bottom: 1%;
}

/* Hover styles for the select */
.selectWrapperForm:hover {
    /* This ensures the container doesn't constrain the select's width */
    overflow: visible;
    z-index: 1000;
}

.selectWrapperForm:hover .itemSelect,
.selectWrapperForm:hover select {
    position: relative;
    min-width: max-content !important;
    width: auto !important;
    /* Add a slight shadow to make it stand out */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Style for the dropdown options */
.itemSelect select option {
    white-space: nowrap;
    overflow: visible;
    text-overflow: initial;
}

@media (min-aspect-ratio: 16/9) {
    .sectionFieldWrapper{
        margin-Bottom: 3%;
         margin-Top: 2%;
         margin-Left: 5%;
    }
    .popup2FrequencySection{
        margin-top: 3vw;
        padding-left: 3vw;

    }
    .popup2DestinationSection{
        margin-top: 3vw;
        padding-left: 3vw;

    }
    .roundedGreyRec{
        border-radius: 5px;
        border: 1px solid lightgrey; 
        height: 38px ;
    }
    .reportingGroupForm {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        top: 14.3%;
        left: 21.5%;
        position: absolute;
        text-align: left;
        width: 69.7%;
        padding: 0.5%;
    }



    .bottomButtons {
        left: 52%;
 
        display:flex;
        top: 3%;
        width: 700px;
    }

    .itemSelect {
        position: relative;
        box-sizing: border-box;
        width: 90%;
        padding: 0%;
        margin-left: 1%;
        height: 30px;
        border-radius: 5px;
    }

    .monthSection{
        margin-top: 8vh;
        margin-left: -38.5vw
    }

    .yearSection{
        margin-top: 8vh!important;
        margin-left: -33.5vw !important;

    }


    .weekSection{
     margin-top: 8vh;
     margin-bottom: 3%;
     width: 284px;
     text-align: left;
     margin-left: 2%;
    }

    .reportArea{
        padding-left: 0%;
        display: flex;
        /* flex-wrap: wrap; */
        /* align-content: space-evenly; */
        justify-content: space-between;
        display: flex;
        width: 107%;
    }


    .saveBtn {
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        background-color: rgb(252, 143, 20);
        font-size: 15px;
        font-weight: 600;
        color: white;
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        align-self: flex-start;
        min-width: 135px;
        max-width: 135px;
        order: 9;
        min-height: 47px;
        width: 135px;
        flex-grow: 1;
        height: max-content;

        margin-left: 5%
    }

    .cancelbtn {
        background-color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 400;
        color: rgb(0, 0, 0);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(163, 163, 163);
        border-radius: 3px;
        opacity: 1;
        cursor: pointer;
        align-self: flex-start;
        min-width: 135px;
        max-width: 135px;
        order: 8;
        min-height: 47px;
        width: 135px;
        flex-grow: 1;
        height: max-content;
        margin-left: 2%;
    }

    .sectionDiv {
        margin-top: 1%;
        margin-bottom: 1%;
        width: 101%;
        margin-left: 0%;
    }

    .includeParInput {
        height: 22px;
        border: 2px solid #cccccc;
        padding: 3%;
    }

    .visualfieldSection {
        display: grid;
        margin-left: 1%;
        width: 170px;
    }

    .ChecklistfieldSection {
        display: grid;
        margin-left: 5%;
    }

    .DEfields {
        display: flex;
        padding-left: 4%;
        padding-right: 2%;
        min-height: 100px;

    }

    .DEfieldSection {
        width: 25vw;
        min-width: 150px
    }

    .ResfieldSection {
        display: grid;
        margin-left: 5%;
        width: 300px;
    }

    .checkboxes label {
        display: inline-block;
        padding-right: 10px;
        white-space: nowrap;
    }

    .checkboxes input {
        vertical-align: middle;
    }

    .checkboxes label span {
        vertical-align: middle;
    }

    .addToGroups {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        top: 16.3%;
        left: 22.5%;
        position: absolute;
        text-align: left;
        width: 69.7%;
        padding: 0.5%;
    }

    .inpLabel {
        width: 215px;
    }

    .defaultInp {
        margin-top: 1%;
        margin-left: 0%;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
    }

    .existingUser {
        margin-left: 1%;
    }

    .inlineBox {
        display: inline;
    }


    .createCatagoryTextBtn {
        margin-left: 22%;
        margin-right: 30%;
    }

    .ResfieldSection{
        width: auto;
        height: auto;
    }

    .responseBox{
        width:10vw;
        height:3vw;
        border-radius:8px;
    }


    .userSelect {
        position: relative;
        box-sizing: border-box;
        padding: 0%;
        margin-top: .5%;
        margin-bottom: 2%;
        height: 30px;
        border-radius: 5px;
        width: 200px;
        margin-left: 0%;
    }


    .field {
        margin-top: 5%;
        margin-left: 1%;

        width: 100%;
        display: inline-flex;


    }


    .contactInfo {
        margin-left: 1%;
    }


    .leftCol {
        width: 40%;
        float: 0;
        text-align: left;
    }

    .rightCol {
        width: 60%;
        text-align: left;
        float: 1;
    }



    .availabilityDay {
        width: 36px;
        height: 20px;
        background-color: white;
        border: 2px solid black;
        padding: 1.1%;
        border-radius: 5px;
        margin-left: 3%;
        margin-top: 2%;
    }

    .rowOfBoxes {
        display: flex;
    }

    .availabilityBox {
        display: inline-block;

    }

    .inputArea {
        display: flex;
        margin-top: 1.5%;
    }

    .sectionSelect {
        margin-left: 10%;
    }

    .bottomSection {
        width: 100%;
        padding: 1%;
        left: 0px;
        top: 0px;
        position: relative;
    }

    .selectGrouper {
        width: 57%;
        text-align: left;
        margin-left: 2%
    }

    .locationText {
        margin-top: 3%;

    }

    .formBtnDiv {
        position: absolute;
        left: 60%;
        top: 87%;
        width: 38%;
    }


    .formBtn {
        background-color: rgb(255, 255, 255);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_text_default);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        border-radius: 1px;
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 857px;
        left: 1064px;
        width: 135px;
        height: 38px;
        z-index: 6;
    }




    .formBtn2 {
        background-color: rgb(252, 143, 20);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_primary_contrast_default);
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        top: 857px;
        left: 1373px;
        width: 135px;
        height: 38px;
        z-index: 4;
    }



    .userForm {
        position: relative;
        text-align: left;
        padding: 0.5%;
    }

    .inpLabel {
        width: 126px;
    }


    .dynamicFormSection {
        display: flex;
    }

    .container1 {
        border: 1px solid black;
        padding: 0%;
        width: 275px;
        height: 350px;
        border-radius: 6px;
        overflow-y: scroll;
        padding-left: 3%;
        background-color: white;
    }

    .optionHeader {
        text-align: left;
        padding: 2%;
        border-bottom: 1px solid black;
        margin-left: -3%;
    }

    .popupBackground {
        width: 100%;
        height: 105%;
        background-color: whitesmoke;
        margin-top: -6%;
        position: absolute;
        /* margin-left: -3%; */
        opacity: .8;
        visibility: hidden;
        z-index: 1;

    }

    .popupBackground2 {
        width: 100%;
        height: 105%;
        background-color: whitesmoke;
        margin-top: -6%;
        position: absolute;
        /* margin-left: -3%; */
        opacity: .8;
        visibility: hidden;
        z-index: 1;

    }

    .closeButton{
        float: right;
        width: 30px;
        height: 30px;
        background-color: #ff0000ab;
        margin-bottom: -30%;
        border: 2px solid black;
        margin: 0%;
        padding: 0%;
        border-top: 0px;
        border-right: 0px;
    }

    .popupBackground3 {
        width: 100%;
        height: 105%;
        background-color: whitesmoke;
        margin-top: -6%;
        position: absolute;
        /* margin-left: -3%; */
        opacity: .8;
        visibility: hidden;
        z-index: 1;
        display:none;
    }

    .pu3Box {
        width: 30%;
        /* border: 1px solid grey; */
        /*padding: .5%;*/
        height: 100%;
        overflow-x: overlay;
        width: 98%;
        /* height: 112%; */
        /* max-height: 520px;*/
        width: 100%;
        
        height: 100%;
        overflow-y: visible;
    }
    .pu3BoxDE{
        width: 30%;
        /* border: 1px solid grey; */
        padding: .5%;
        height: 100%;
        overflow-x: overlay;
        width: 98%;
        /* height: 112%; */
        /* max-height: 520px;*/
        width: 100%;
        
        height: 100%;
        overflow-y: visible;
        display:contents;
    }

    .popupMessage3 {
        z-index: 2;
        border: 2px solid black;
        width: 1300px;
        height: 750px;
        background-color: white;
        text-align: center;
        position: relative;
        left: 24vw;
        top: 8vh;
        position: absolute;
        visibility: visible;
        z-index:100;
        display:none;
        overflow: visible;
    }

    .popupMessage2 {
        z-index: 200;

        border: 2px solid black;
        width: 1000px;
        height: 650px;
        background-color: white;
        text-align: center;
        position: relative;
        left: 30vw;
        top: 6vh;
        position: absolute;
        visibility: visible;
        display: none;

        overflow-y: overlay;
        overflow-x: hidden;
    }

    .reportLine{
        margin-top: 1%;
        padding-left: 2%;
        display:flex;
            /* flex-wrap: wrap; */
    /* align-content: space-evenly; */
    justify-content: space-between;
    display: flex;
    width: 96%;
    }

    .select2 {
        position: relative;
        box-sizing: border-box;
        padding: 0%;

        border-radius: 5px;

        width: 100%;
        display:block;
    }

    .popupMessage {
        z-index: 100;
        border: 2px solid black;
        width: 500px;
        height: 300px;
        background-color: white;
        text-align: center;
        position: relative;
        left: 45vw;
        top: 27vh;
        position: absolute;
        display:none;
        visibility: visible;
    }

    .popupBtn {
        margin-top: 2%;
        width: 80px;
        height: 45px;
        margin-right: 2%;
        border-radius: 5px;
        background-color: #fdf0df;
    }

    .popupBtn3 {
        
        background-color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 0px;
        left: 0px;
        height: 44px;
        width: 150px;
        border-radius: 10px;
        z-index: 6;

        margin-left:8%;
        min-width: 120px;
    }



    .orangeButton {
        width: 80px;
        height: 45px;
        margin-right: 2%;
        border-radius: 5px;
        background-color: #fdf0df;

    }

    .orangeInput2 {
        background-color: #fdf0df;
        width: 200px;
        border-radius: 5px;
        height: 28px;
    }

    .orangeInput {
        background-color: #fdf0df;
        width: 600px;
        border-radius: 5px;
        height: 28px;

    }

    .otherFields {
        margin-left: 0%;
        display: flex;
        align-items: baseline;
    }

    .otherFields2 {
        margin-left: 0%;
        display: flex;
        align-items: baseline;
        overflow-x: overlay;
        width: 100%;
    }

    .DEfields {
        display: flex;
        min-height: 100px;
    }

    .DEfieldSection {
        width: 220px;
        text-align: left;
        min-width: 150px
    }

    .itemDiv {
        width: 300px;
        height: 200px;
        background-color: grey;
    }

    .titleSection {
        text-align: left;
        margin-top: 1%;
        margin-bottom: 3%;
        font-size: 22px;

    }

 

    .defaultInp {
        margin-top: 1%;
        margin-left: 0%;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
    }

    .existingUser {
        margin-left: 1%;
    }

    .userSelect {
        position: relative;
        box-sizing: border-box;
        padding: 0%;
        margin-top: .5%;
        margin-bottom: 2%;
        height: 30px;
        border-radius: 5px;
        width: 200px;
        margin-left: 0%;
        z-index: 5;

    }


    .field {
        margin-top: 3%;
        margin-left: 1%;
        width: 100%;
        display: inline-flex;
    }


    .contactInfo {
        margin-left: 1%;
    }

    .leftCol {
        width: 40%;
        padding-bottom: .5%;
        float: 0;
        text-align: left;
    }

    .rightCol {
        width: 60%;
        text-align: left;
        float: 1;
    }


    .availabilityDay {
        width: 36px;
        height: 20px;
        background-color: white;
        border: 2px solid black;
        padding: 1.1%;
        border-radius: 5px;
        margin-left: 3%;
        text-align: center;
        margin-top: 2%;
    }

    .rowOfBoxes {
        display: flex;
    }

    .availabilityBox {
        display: inherit;
        position: inherit;
        text-align: left;
        margin-left: 2%;
        margin-top: 2%;


    }

    .availabilityText {
        margin-left: 3%;
    }

    .createItem {
        margin-right: 20%;
    }

    .jobSelect {
        width: 100%;
        margin-bottom: 3%;
    }



    .vendorSelect {
        margin-bottom: 3%;
        width: 27%;
    }

    .locationSelect {
        width: 35%;
    }

    .createUserText {
        margin-left: 20%;
    }


    .itemSearch {
        margin-right: 25%;
    }

    .selectTextWrapper {

    }

    .jobPositionText {
        margin-left: 1%;
        margin-right: 8%
    }

    .rgText1 {
        margin-left: 1%;
        margin-right: 1.5%;
    }

    .bottomSection {
        width: 100%;
        padding: 1%;
        left: 0px;
        top: 0px;
        position: relative;
    }

    .selectGrouper {
        width: 57%;
        text-align: left;
        margin-left: 2%
    }

    .blackLineDiv2 {
        color: black;
        height: 1px;
        background-color: black;
        margin-bottom: 1%;
        width: 102.7%;
        margin-top: 1.5%;
        position: relative;
    }


    .locationSelect {}

    .locationText {
        margin-top: 5%;

    }

    .formBtnDiv {
        position: relative;
        width: 80%;
        margin-top: 4%;
        left: 92%;
        display: flex;
    }


    .formBtn {
        background-color: rgb(255, 255, 255);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_text_default);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        border-radius: 1px;
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 857px;
        left: 1064px;
        width: 135px;
        height: 38px;
        z-index: 6;
    }


    .formBtn2 {
        background-color: rgb(252, 143, 20);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_primary_contrast_default);
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        top: 857px;
        left: 1373px;
        width: 135px;
        height: 38px;
        z-index: 4;
    }





}






@media screen and (min-width: 1200px) and (max-width: 1380px)  and (max-height: 1025px){

    .includeParToggle2{
        margin-left: 16.3%!important;
    }
    .pageWrapper{
        margin-left: 2vw !important ;
    }
    .bottomButtons{
        width:25vw;
    }

}




@media screen and (min-width: 768px) and (max-width: 1024px) {

    .addASection{
        min-height: 3/7vh !important;
    }

    .buttonStyling{
        height: 10vh !important;
          width: 20vh !important;
          
       min-height: 4vh !important;

    }
    .typeOfSection{
        margin-top: 2%;
        margin-bottom: 3%;
        margin-left: 4%;
        width:88.1vw;
    }

    .pageWrapper {
      padding-left: 6%;
      width: 94%;
      font-size: 1.2rem;
    }
    .popupMessage{
        visibility: hidden;
        display: none;

    }    

    .addASection{
        height:4vh;
    }



    .selectWrapperForm{
        width: 15.4vw;
        min-width: 15.4vw;
        height: 5vh;
        margin-left: 1%;
        margin-right: 2%;

        align-content:center;
        margin-bottom:auto;
        margin-top:auto;
    }

    .popup2DestinationSection{
        padding-left: 3vw;
        margin-top: 8vw;
        display:flex;
    }
    .popup2FrequencySection{
        padding-left: 3vw;
        margin-top: 8vw;
    }
    .userSelect{
        position: relative;
        /* box-sizing: border-box; */
        /* padding: 0%; */
        /* margin-top: .5%; */
        /* margin-bottom: 2%; */
        height: 30px;
        border-radius: 5px;
        width: 30vw;
        /* margin-left: 0%;*/
    }

  
    .defaultInp{
        width:20vw!important;
        margin-left:1vw!important;
        margin-bottom:3vh!important;
        font-size: 1.5rem!important;
        padding:.5rem!important;
        height:3vh !important;
        min-height: 3.2vh;

        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-top: 1%;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
    }


    .defaultInp2{
        min-height: 4.6vh !important;
        height:4.6vh !important;

    }


    .includeParToggle{
        margin-left: 18.4vw !important;
    }

    .triggerPurchaseToggle{
        margin-left: 17vw !important;
    }
    
 
    .pu3boxWrap{
        text-align: left;
        margin-left: 1%;
        display: flex;
        height: 77%;
        margin-bottom: 3vh;
    }

    .popupMessage{
        display: block;
        width: 91vw;
        height: 41vh;
        /* background-color: red; */
        z-index: 100000000;
        visibility: visible;
        display: block;
        position: absolute;
        left: 6vw;
        border: 2px solid black;
        top: 19vw;
        font-size: 1.5rem;
        border-radius: 5px;
        background: #f5f5f5;
    }

    .bottomButtons {
      left: 10vw;
      top: 94%;
      width: 100%;
      justify-content: center;
    }
  
    .cancelbtn {
      margin-right: 2%;
    }
  
    .saveBtn {
      margin-left: 2%;
    }
  
    .pu3Box {
     /* max-height: 400px;*/
    }
  
    .popupMessage3 {
      width: 90%;
      height: 80%;
      left: 5%;
      top: 10%;
      visibility: hidden;
      display: none;
      overflow: visible;

    }

    .popupMessage{
        
        visibility: visible;
        display: none;
    }
  
    .popupMessage2 {
        display: block;
        left: 5vw;
        position: absolute;
        z-index: 1000;
        top: 14vh;
        width: 60vw;
        height: 40vh;
        font-size:1.5rem;
        background: #f5f5f5;

        border: 2px solid black;
        font-size: 1.5rem;
        border-radius: 5px;


        visibility: visible;
        display: none;
        
    }

    .userSelect{
        min-width: 25vw;
        width: 25vw;
        max-width: 25vw;
        font-size: 1rem;
    }
  
    .reportLine {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  
    .select2 {
      width: 150px;
      display:block
    }
  
    .monthSection {
      margin-left: 0;
      width: 100%;
    }
  
    .DEfields {
      flex-wrap: wrap;
      padding-right: 4%;
    }
  
    .DEfieldSection {
      width: 24vw;
      margin-bottom: 2%;
    }
  
    .ResfieldSection {
      width: 100%;
      margin-left: 0;
    }
  
    .popupBtn3 {
      min-width: 100px;
    }
  
    .formBtn,
    .formBtn2,
    .formBtn3 {
      min-width: 120px;
    }
  
    .checkboxHelper1{
        margin-left: 3%;
    }


    .editArea,
    .previewArea {
      padding: 2%;
    }

  


  
  
    .visualfieldSection {
      width: 100%;
      margin-left: 0;
      margin-bottom: 2%;
    }
  
    .sectionFieldWrapper {
      margin-left: 2%;
    }
  
    .blackLineDiv2 {
      width: 100%;
      left: 0;
    }

    .triggerPurchaseToggle{
        margin-left: 16vw !important;
    }

    .sectionDiv{
        margin-bottom: 4vh;
    }
    
  

  .align-center {
    align-items: center;
}

.overflow-x-overlay {
    overflow-x: overlay;
}

/* Form Type Header */
.form-type-header {
    font-size: 18px;
    margin-top: 1%;
}

/* Data Entry Form Styles */
.data-entry-form {
    height: 68%;
}

.data-entry-box {
    width: 98%;
}

.data-entry-row {
    height: auto;
    width: auto;
    margin-top: .4%;
    margin-bottom: 1.5%;
}

.item-select-wrapper {
    width: 168px;
    min-width: 168px;
    height: 30px;
    margin-left: 1%;
    margin-right: 2%;
}

.par-value-input {
    width: 85px;
    height: 15px;
    margin-left: 1%;
    margin-right: 3.7%;
    margin-top: -.2%;
}

.trigger-purchase-label {
    margin-right: 12%;
    margin-top: 1%;
}

.user-response-input {
    margin-left: 1%;
    margin-right: 2.3%;
    width: 180px;
}

.action-buttons {
    display: flex;
    align-items: center;
    width: 82px;
    justify-content: space-evenly;
}

.user-select-wrapper{
    margin-top: 5.5vh;
}

.userSelect{
    margin-right: 4vw;
}

.popup-header{
    padding-left: 2vw;
    text-align: left;
}

.frequency-select{
    margin-right: 9.5vw;
}

.frequency-section{
    margin-top: 7vh;
}
.display-flex{
    display:flex;
}

.justify-space-between{
    justify-content: space-inbetween;
}


.select2{
    width: 100%;
}
/* General Styles */
.text-left { text-align: left; }
.display-flex { display: flex; }
.justify-space-between { justify-content: space-between; }
.justify-space-evenly { justify-content: space-evenly; }
.align-center { align-items: center; }
.margin-top-2 { margin-top: 2%; }
.margin-top-3 { margin-top: 3%; }
.margin-left-3 { margin-left: 3%; }
.margin-right-3 { margin-right: 3%; }
.padding-left-3 { padding-left: 3%; }

/* Layout */
.pageWrapper {
    padding-top: 1%;
    text-align: left;
    position: relative;
    width: 100%;
    height: 809px;
    overflow-y: overlay;
    overflow-x: hidden;
}

.bottomSection {
    width: 100%;
    padding: 1%;
    position: relative;
}

/* Form Elements */
.userSelect, .select2 {
    position: relative;
    box-sizing: border-box;
    height: 30px;
    border-radius: 5px;
    width: 200px;
    margin-top: .5%;
    margin-bottom: 2%;
}


.roundedGreyRec {
    border-radius: 5px;
    border: 1px solid lightgrey;
    height: 38px;
}

/* Popup Styles */
.popupBackground, .popupBackground2, .popupBackground3 {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    margin-top: -6%;
    position: absolute;
    opacity: .8;
    visibility: hidden;
    z-index: 1;
    display:none;

}

.popupMessage, .popupMessage2, .popupMessage3 {
    border: 2px solid black;
    background-color: white;
    text-align: center;
    position: absolute;
    visibility: visible;
    display: none;
    overflow: visible;

}

.closeButton {
    float: right;
    width: 30px;
    height: 30px;
    background-color: #ff0000ab;
    border: 2px solid black;
    border-top: 0;
    border-right: 0;
}

/* Specific Sections */
.assign-section {
    text-align: left;
    margin-left: 3%;
}

.frequency-section {
    display: block;
    margin-top: 8%;
    margin-left: 3%;
}

.destination-section {
    display: block;
    margin-top: 1%;
    margin-bottom: 2%;
    margin-left: 6%;
}

.due-date-select{
    margin-left: 3vw;
}
/* Buttons */
.popupBtn3, .formBtn, .formBtn2 {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    padding: 12px 16px;
    opacity: 1;
    cursor: pointer;
    min-width: 120px;
    border-radius: 10px;
}

.display-flex{
    display:flex;
}

.popup2MessageMain{
    width: 91vw;
    height: 61vh;
    border: 2px solid black;
    padding-left: 3vw;
    padding-right: 3vw;
}

.popup-header{
    text-align: left !important;
}
.userSelect{
    margin-top: 5vh;
}
}


.assignmentHeader{
    text-align:left;
}
.popup2AssignSection {
    padding-left: 3vw;
}

.popup2InputWrapper{
    display:flex;
}

.userSelect{
    margin-top: 0px;
}



.frequency-select{
    margin-left: 0%;
}




.destination-select-wrapper{
    display:flex;
}

.reoccurenceWrapper{
    display: block;
    margin-left:-1vw;
}

.topSect{display:flex} .botSect{ display:'flex'}


.popupMessage3{
 z-index: 10000;   
 overflow: visible;

}


.editWrapper{
    display: flex;
    height: auto;
    overflow: visible;

    width: auto;
    margin-top: 1.4%;
    margin-bottom: 1.5%;
    margin-left: 1.3vw;
}

.triggerPurchaseToggle2{
    margin-left:2.7%;
    margin-right:2.4%;

}
.includeParToggle2{
    margin-left: 13.8%;
    margin-right: 1.4%;
}


@media (min-aspect-ratio: 4/3) and (orientation:landscape) and (max-width: 1024px){


    .pageWrapper{
        margin-left: 5%;
    }
    .addASection{
        min-height: 3vh;
        height:3vh;
    }
}

@media (min-aspect-ratio: 4/3) {

    .visualDescription{
        width: 12vw;
        margin-left: 5vw;
        height:4vh;
        border-radius:8px;
    }
 
    .topAreaPu3{
        margin-left: 3.4%;
    }

    .imageUpload1{
        width: 66%;
        text-align: center;
        align-content: center;
        display: block;
        border: 2px solid black;
        border-radius: 8px;
        padding: 2.3%;     
    }



    .includeParToggle{
        margin-left: 15.8% !important;  
      }

    .selectWrapperForm{
        width: 9vw;
        min-width: 9vw;
        height: 3vh;
        margin-left: 1%;
        margin-right: 2%;

        align-content:center;
        margin-bottom:auto;
        margin-top:auto;
    }

    .defaultInp {
        margin-left: 1%;
        margin-right: 3.7%;
        height: 0.4vh;
        margin-top: .8%;
        width:4vw;
    
    }

    .defaultInp2{
        margin-left: 1%;
        margin-right: 3.7%;
        height: 1vh;
        margin-top: .8%;
        width: 10.8vw;
    }
    .buttonStyling{
        height: 2vh ;
        width: 20vh ;
        min-height: 4vh ;

    }




    
    .pu3boxWrap{
        text-align: left;
        margin-left: -4vw;
        display: flex;
        height: 81vh; /* 63*/
        margin-top: 2vh;
        width: 90vw;

        }

    .assignUserSelect{
        margin-left: 31%;
        min-width: 13vw;
    }

    .pu2FrequencyWrapper{
        min-width: 10.5vw;
        width: 10.5vw;
    }
    
    .includeParToggle{
        margin-left: 10.8%;
        margin-right: 1.5%;
    }

    .triggerPurchaseToggle{
        margin-left: 2.8vw; 
        margin-right: 2% ;
    }

    .userResponseText{
        margin-left: 13.6%;
    }

    .bottomButtons{
        left:35vw;
    }

    .popupBackground1{
        /* background-color: red; */
        width: 100vw;
        height: 105vh;
    }  

    .popupBackground2{
        display: none;
        visibility: hidden;
        /* background-color: red; */
        width: 100vw;
        height: 105vh;
    }

    .popupBackground3{
        visibility: hidden;
        display:block;
        /* background-color: red; */
        width: 100vw;
        display:none;

        height: 105vh;
    }
    .pageWrapper{
        padding-left: 4.6% !important;    }
/*
    .selectWrapperForm{
        width: 11vw;
        min-width: 11vw;
        height: 3vh;
        margin-left: 1%;
        margin-right: 2%;
        margin-top: 1.4vh;
        margin-top: 0vh !important;
    }*/


    .typeOfSection{
        margin-top: 2%;
        margin-bottom: 3%;
        width: 29.1vw;
    }

    .popupMessage{
        z-index: 200;
        border: 2px solid black;
        width: auto;
        height: auto;
        background-color: white;
        text-align: center;
        position: relative;
        left: 36%;
        top: 9vh;
        position: absolute;
        visibility: none;
        display: none;
        overflow-y: overlay;
        padding-bottom: 2%;
        overflow-x: hidden;
    }

    .popupMessage3 {
        z-index: 200;
        border: 2px solid black;
        width: 63vw;
        height: auto;
        background-color: white;
        text-align: center;
        position: relative;
        left: 21vw;
        top: 9vh;
        position: absolute;
        visibility: none;
        display: none;
        overflow-y: overlay;
        padding-bottom: 2%;
        overflow-x: hidden;
        max-height: 67vh;
        overflow: visible;
    }

    .popupMessage2 {
        z-index: 200;
        border: 2px solid black;
        width: 63vw;
        height: auto;
        background-color: white;
        text-align: center;
        position: relative;
        left: 21vw;
        top: 9vh;
        position: absolute;
        visibility: none;
        display: none;
        overflow-y: overlay;
        padding-bottom: 2%;
        overflow-x: hidden;
    }

    .yearSection{
        width: 26.04vw;
        display: inline-block;
        margin-left: -27vw;
        margin-bottom: 4vh;
        margin-top: 5vh;
    }


    .onText{
        min-width: 2.6vw;
        margin-left: 0.1vw;
        margin-right: 0.5vw;
        margin-top: .84vh;
    }

    .popup2DestinationSection{
        padding-left: 3vw;
        margin-top: 5vw;
    }
}





.scheduledEditArea{
    margin-left: 8.5vw;
    margin-bottom:2vh;
}


.popupBackground4 {
    width: 100%;
    height: 105%;
    background-color: whitesmoke;
    margin-top: -6%;
    position: absolute;
    /* margin-left: -3%; */
    opacity: .8;
    visibility: hidden;
    z-index: 1;

}

.popupMessage4 {
    z-index: 200;
    border: 2px solid black;
    width: 80vw;
    height: 65vh;
    background-color: white;
    text-align: center;
    position: relative;
    left: 13vw;
    top: 13vh;
    position: absolute;
    visibility: visible;
    display: none;
    overflow-y: overlay;
    overflow-x: hidden;
}


.pu3boxWrap{
        text-align: left;
        margin-left: 1%;
        display: flex;
        height: 68%;
        overflow-x: auto;

}

.pu3box{
    overflow-x: auto;

}

@media (min-aspect-ratio: 19.5/9) {

    .defaultInp {
        margin-left: 1%;
        margin-right: 3.7%;
        height: 1vh;
        margin-top: .8%;
        width: 20.8vw;
        padding:1rem

}

.defaultInp2{
        margin-left: 1%;
        margin-right: 3.7%;
        height: 1vh;
        margin-top: .8%;
        width: 40.8vw;
        padding:1rem
}
.buttonStyling{
    height: 2vh ;
      width: 20vh ;
   min-height: 4vh ;
}
.pu3boxWrap{
    text-align: left;
    margin-left: -4vw;
    display: flex;
    height: 81vh; /* 63*/
    margin-top: 2vh;
    width: 90vw;

    }

.assignUserSelect{
    margin-left: 31%;
    min-width: 13vw;
}

.pu2FrequencyWrapper{
    min-width: 10.5vw;
    width: 10.5vw;
}

.includeParToggle{
    margin-left: 10.8%;
    margin-right: 1.5%;
}

.triggerPurchaseToggle{
    margin-left: 5.7%; 
    margin-right: 1% ;
}

.userResponseText{
    margin-left: 5.6%;
}

.bottomButtons{
    left:35vw;
}

.popupBackground1{
    /* background-color: red; */
    width: 100vw;
    height: 105vh;
}  

.popupBackground2{
    display: none;
    visibility: hidden;
    /* background-color: red; */
    width: 100vw;
    height: 105vh;
}

.popupBackground3{
    display: none;
    visibility: hidden;
    /* background-color: red; */
    width: 100vw;
    height: 105vh;
}

/*
.selectWrapperForm{
    width: 11vw;
    min-width: 11vw;
    height: 3vh;
    margin-left: 1%;
    margin-right: 2%;
    margin-top: 1.4vh;
    margin-top: 0vh !important;
}*/

.popupMessage{
    display: none;
    visibility: hidden;
    left: 5vw;
    top: 5vh;
    position: absolute;
    z-index: 100;
    border: 2px solid black;
    width: 500px;
    height: 300px;
    background-color: white;
    text-align: center;
    position: relative;
    left: 34vw;
    top: 27vh;
    position: absolute;


    visibility: hidden;
    display:none;
}
.popupMessage3 {
    z-index: 2;
    border: 2px solid black;
    width: 94vw;
    height: 82vh;
    background-color: white;
    text-align: center;
    position: relative;
    left: 5vw;
    top: 8vh;
    position: absolute;
    visibility: none;
    z-index: 100;
    display: none;
    
}

.popupMessage2 {
    z-index: 200;
    border: 2px solid black;
    width: 63vw;
    height: 88vh;
    background-color: white;
    text-align: center;
    position: relative;
    left: 21vw;
    top: 9vh;
    position: absolute;
    visibility: none;
    display: none;
    overflow-y: overlay;
    overflow-x: hidden;
}

.yearSection{
    width: 26.04vw;
    display: inline-block;
    margin-left: -27vw;
    margin-bottom: 4vh;
    margin-top: 5vh;
}


.onText{
    min-width: 2.6vw;
    margin-left: 0.1vw;
    margin-right: 0.5vw;
    margin-top: .84vh;
}

.popup2DestinationSection{
    margin-top: 1vw;
    padding-left: 3vw;

}




}



/* tablet portrait */

@media screen and (min-height: 768px) and (max-height: 1324px) and (orientation:portrait) {
    .pageWrapper {
      padding-left: 8%;
      width: 94%;
    }
    .select2{
        min-width: 20vw !important;
    }


    .topAreaPu3{
        margin-left: 7vw !important;
    }

    .triggerPurchaseToggle{
        margin-left: 6.7vw !important;

    }

    .buttonStyling{
        font-size: .8rem!important;
        height:4vh !important;
        max-height: 10vh!important;
        min-height:5vh!important;
        margin-bottom: 0% !important;

    }

    .popupMessage{
        visibility: hidden;
        display: none;
        height:55vh !important;
    }
    .popup2DestinationSection{
        padding-left: 3vw;
        margin-top: 8vw;
        display:flex;
    }
    .popup2FrequencySection{
        padding-left: 3vw;
        margin-top: 8vw;
    }
    .userSelect{
        position: relative;
        /* box-sizing: border-box; */
        /* padding: 0%; */
        /* margin-top: .5%; */
        /* margin-bottom: 2%; */
        height: 30px;
        border-radius: 5px;
        margin-top: 0px;
        width: 30vw;
        /* margin-left: 0%;*/
    }
  
    .userResponseText{
        margin-left: 5vw;
    }
    .enterPar{
        margin-top: -2vh;
        max-height: 2.2vh;
    }
    .itemSelect{
        min-width: 17vw !important;
        width: 17vw !important;

    }

    .selectWrapperForm{
        overflow-y: overlay;
        overflow-y: visible;
    }
    .editWrapperOutside{
        overflow-y: visible;
        height:auto;
    }

    
    .editWrapper{
        overflow: scroll;
        padding: 1rem;
        overflow-y: overlay;
        height:auto;
    }
    
    .defaultInp{
        width:25vw!important;
        margin-left:1vw!important;
        margin-bottom:3vh!important;
        font-size: 1rem!important;
        padding:.5rem!important;
        height:3vh !important;
        min-height: 3.2vh;
        min-width: 8vw !important;
    }
    .defaultInp2{
        min-height: 4.6vh !important;
        height:4.6vh !important;

    }

    .popupMessage{
        display: block;
        width: 91vw;
        height: 41vh;
        /* background-color: red; */
        z-index: 100000000;
        visibility: visible;
        display: block;
        position: absolute;
        left: 6vw;
        border: 2px solid black;
        top: 19vw;
        font-size: 1.5rem;
        border-radius: 5px;
        background: #f5f5f5;
    }

    .bottomButtons {
      left: 10vw;
      top: 94%;
      width: 100%;
      justify-content: center;
    }
  
    .cancelbtn {
      margin-right: 2%;
    }
  
    .saveBtn {
      margin-left: 2%;
    }
  
    .pu3Box {
      max-height: 550px;
      width: 89vw !important;
      max-width: 89vw;
      height: 100%;
      overflow: scroll !important;
      overflow-x: scroll !important;
    }

    .dataCheckbox{
        font-size: 1rem;
    }
  
    .popupMessage3 {
      width: 90%;
      height: 80%;
      left: 5%;
      top: 10%;
      visibility: hidden;
      display: none;
      padding: .5%;
      overflow-x: scroll;
    }

    .popupMessage{
        
        visibility: visible;
        display: none;
    }
  
    .popupMessage2 {
        display: block;
        left: 5vw;
        position: absolute;
        z-index: 1000;
        top: 14vh;
        width: 93vw;
        height: 66vh;
        font-size:1.5rem;
        background: #f5f5f5;

        border: 2px solid black;
        font-size: 1.5rem;
        border-radius: 5px;


        visibility: visible;
        display: none;
        
    }
  
    .reportLine {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  
    .select2 {
      width: 150px;
    }
  
    .monthSection {
      margin-left: 0;
      width: 100%;
    }
  
    .DEfields {
      flex-wrap: wrap;
      padding-right: 4%;
    }
  
    .DEfieldSection {
      width: 25vw;
      margin-bottom: 2%;
    }
  
    .ResfieldSection {
      width: 100%;
      margin-left: 0;
    }
  
    .popupBtn3 {
      min-width: 100px;
    }
  
    .formBtn,
    .formBtn2,
    .formBtn3 {
      min-width: 120px;
    }
  
    .editArea,
    .previewArea {
      padding: 2%;
    }
  
    .defaultInp {
      width: 100%;
    }
  
    .visualfieldSection {
      width: 100%;
      margin-left: 0;
      margin-bottom: 2%;
    }
  
    .sectionFieldWrapper {
      margin-left: 2%;
    }
  
    .blackLineDiv2 {
      width: 100%;
      left: 0;
    }


    
  

  .align-center {
    align-items: center;
}

.overflow-x-overlay {
    overflow-x: overlay;
}

/* Form Type Header */
.form-type-header {
    font-size: 18px;
    margin-top: 1%;
}

/* Data Entry Form Styles */
.data-entry-form {
    height: 68%;
}

.data-entry-box {
    width: 98%;
}

.data-entry-row {
    height: auto;
    width: auto;
    margin-top: .4%;
    margin-bottom: 1.5%;
}

.item-select-wrapper {
    width: 168px;
    min-width: 168px;
    height: 30px;
    margin-left: 1%;
    margin-right: 2%;
}

.par-value-input {
    width: 85px;
    height: 15px;
    margin-left: 1%;
    margin-right: 3.7%;
    margin-top: -.2%;
}

.trigger-purchase-label {
    margin-right: 12%;
    margin-top: 1%;
}

.user-response-input {
    margin-left: 1%;
    margin-right: 2.3%;
    width: 180px;
}

.action-buttons {
    display: flex;
    align-items: center;
    width: 82px;
    justify-content: space-evenly;
}

.user-select-wrapper{
    margin-top: 5.5vh;
}

.userSelect{
    margin-right: 4vw;
}

.popup-header{
    padding-left: 2vw;
    text-align: left;
}

.frequency-select{
    margin-right: 9.5vw;
}

.frequency-section{
    margin-top: 7vh;
}
.display-flex{
    display:flex;
}

.justify-space-between{
    justify-content: space-inbetween;
}


.select2{
    width: 100%;
}
/* General Styles */
.text-left { text-align: left; }
.display-flex { display: flex; }
.justify-space-between { justify-content: space-between; }
.justify-space-evenly { justify-content: space-evenly; }
.align-center { align-items: center; }
.margin-top-2 { margin-top: 2%; }
.margin-top-3 { margin-top: 3%; }
.margin-left-3 { margin-left: 3%; }
.margin-right-3 { margin-right: 3%; }
.padding-left-3 { padding-left: 3%; }

/* Layout */
.pageWrapper {
    padding-top: 1%;
    text-align: left;
    position: relative;
    width: 94vw;
    height: 85vh;
    overflow-y: overlay;
    overflow-x: hidden;
}

.bottomSection {
    width: 100%;
    padding: 1%;
    position: relative;
}

/* Form Elements */
.userSelect, .select2 {
    position: relative;
    box-sizing: border-box;
    height: 30px;
    border-radius: 5px;
    width: 200px;
    margin-top: .5%;
    margin-bottom: 2%;
}

.defaultInp {
    margin-top: 1%;
    width: 300px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid #8080805c;
    padding: 1%;
}

.roundedGreyRec {
    border-radius: 5px;
    border: 1px solid lightgrey;
    height: 38px;
}

/* Popup Styles */
.popupBackground, .popupBackground2, .popupBackground3 {
    width: 100%;
    height: 105vh;
    background-color: whitesmoke;
    margin-top: -6%;
    position: absolute;
    opacity: .8;
    visibility: hidden;
    z-index: 1;
    display:none;

}

.popupMessage, .popupMessage2, .popupMessage3 {
    border: 2px solid black;
    background-color: white;
    text-align: center;
    position: absolute;
    visibility: visible;
    display: none;
}

.closeButton {
    float: right;
    width: 30px;
    height: 30px;
    background-color: #ff0000ab;
    border: 2px solid black;
    border-top: 0;
    border-right: 0;
}

/* Specific Sections */
.assign-section {
    text-align: left;
    margin-left: 3%;
}

.frequency-section {
    display: block;
    margin-top: 8%;
    margin-left: 3%;
}

.destination-section {
    display: block;
    margin-top: 1%;
    margin-bottom: 2%;
    margin-left: 6%;
}

.due-date-select{
    margin-left: 3vw;
}
/* Buttons */
.popupBtn3, .formBtn, .formBtn2 {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    padding: 12px 16px;
    opacity: 1;
    cursor: pointer;
    min-width: 120px;
    border-radius: 10px;
}

.display-flex{
    display:flex;
}

.popup2MessageMain{
    width: 91vw;
    height: 61vh;
    border: 2px solid black;
    padding-left: 3vw;
    padding-right: 3vw;
}

.popup-header{
    text-align: left !important;
}
.userSelect{
   /* margin-top: 5vh;*/
}




}

  
 @media screen and (max-width: 480px) {


    .userSelect{
        width: 40vw;
        min-width: 40vw;
        font-size: .7rem;
    }
    .assignUserSelect{
                width: 40vw;
        min-width: 40vw;
        font-size: .7rem;
    }


    .defaultInp {
        margin-left: 1%;
        margin-right: 3.7%;
        height: 1vh;
        margin-top: .8%;
        width: 20.8vw;
        padding:1rem

}

.defaultInp2{
        margin-left: 1%;
        margin-right: 3.7%;
        height: 1vh;
        margin-top: .8%;
        width: 40.8vw;
        padding:1rem
}
.buttonStyling{
    height: 2vh ;
      width: 20vh ;
   min-height: 4vh ;
   
}
.pu3boxWrap{
    text-align: left;
    margin-left: -4vw;
    display: flex;
    height: 81vh; /* 63*/
    margin-top: 2vh;
    width: 90vw;

    }

    .popupMessage{
        width: 87vw !important;
        height: 70vw!important;
        background-color: white;
        text-align: center;
        position: relative;
        left: 12vw!important;
        top: 10vh!important;
    }


.assignUserSelect{
    margin-left: 31%;
    min-width: 13vw;
}

.pu2FrequencyWrapper{
    min-width: 10.5vw;
    width: 10.5vw;
}

.includeParToggle{
    margin-left: 10.8%;
    margin-right: 1.5%;
}

.triggerPurchaseToggle{
    margin-left: 5.7%; 
    margin-right: 1% ;
}

.userResponseText{
    margin-left: 5.6%;
}

.bottomButtons{
    left:35vw;
}

.popupBackground1{
    /* background-color: red; */
    width: 100vw;
    height: 105vh;
}  

.popupBackground2{
    display: none;
    visibility: hidden;
    /* background-color: red; */
    width: 100vw;
    height: 105vh;
}

.popupBackground3{
    display: none;
    visibility: hidden;
    /* background-color: red; */
    width: 100vw;
    height: 105vh;
}
.pageWrapper{
    padding-left: 15% !important;
}
/*
.selectWrapperForm{
    width: 11vw;
    min-width: 11vw;
    height: 3vh;
    margin-left: 1%;
    margin-right: 2%;
    margin-top: 1.4vh;
    margin-top: 0vh !important;
}*/

.closeButton{
    position:relative;
    z-index:1000
}
h
.typeOfSection{
    margin-top: 2%;
    margin-bottom: 3%;
    width: 79.1vw;
}
.popupMessage{
    display: none;
    visibility: hidden;
    left: 5vw;
    top: 5vh;
    position: absolute;
    z-index: 100;
    border: 2px solid black;
    width: 500px;
    height: 300px;
    background-color: white;
    text-align: center;
    position: relative;
    left: 34vw;
    top: 27vh;
    position: absolute;


    visibility: hidden;
    display:none;
}
.popupMessage3 {
    z-index: 2;
    border: 2px solid black;
    width: 94vw;
    height: 82vh;
    background-color: white;
    text-align: center;
    position: relative;
    left: 5vw;
    top: 8vh;
    position: absolute;
    visibility: none;
    z-index: 100;
    display: none;
    
}

.popupMessage2 {
    z-index: 200;
    border: 2px solid black;
    width: 92vw;
    height: 70vh;
    background-color: white;
    text-align: center;
    position: relative;
    left: 5vw;
    top: 9vh;
    position: absolute;
    visibility: none;
    display: none;
    overflow-y: overlay;
    overflow-x: hidden;
    z-index: 1999;
}

.yearSection{
    width: 26.04vw;
    display: inline-block;
    margin-left: -27vw;
    margin-bottom: 4vh;
    margin-top: 5vh;
}


.onText{
    min-width: 2.6vw;
    margin-left: 0.1vw;
    margin-right: 0.5vw;
    margin-top: .84vh;
}

.popup2DestinationSection{
    margin-top: 1vw;
    padding-left: 3vw;

}


  }
  
  @media screen and (min-width: 480px) and (max-width: 540px) {
 
  }
  
  @media screen and (min-width: 360px) and (max-width: 480px) {
 
  }
  
  @media screen and (min-width: 390px) and (max-width: 450px) {

  }
  
  @media screen and (min-width: 390px) and (max-width: 400px) {

  }
  
  @media screen and (min-width: 350px) and (max-width: 360px) {

  }
  
  @media screen and (min-height: 350px) and (max-height: 700px) {

  }
  
  @media screen and (min-height: 700px) and (max-height: 900px) {

  }
  
  @media screen and (min-width: 540px) and (max-width: 720px) {
  
  }
  
  @media screen and (min-width: 1015px) and (max-width: 1340px) {

  }
  
  @media (max-width: 768px) {

  }
  
  
  /* Add these specific fixes for iPad Air 2 and similar tablets */
  @media screen and (min-width: 768px) and (max-width: 1024px) {


    .includeParToggle{
        margin-left: 15vw !important;
    }

    .defaultInp{
        padding: 0px;  
        width: 25%;      
        max-width:25%;
    }


    .dataCheckbox{
        width:3vw;
        height:3vh;
    }
    .enterPar{
        margin-top: 1%;
        margin-left: 0%;
        width: 10vw;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
      }
      
  }
  
  /* Specific iPad Air 2 && ipad 8th gen landscape mode */
  /*and certain laptops i guess*/
  @media screen 
    and (min-width: 1024px) 
    and (max-height: 768px) 
    and (orientation: landscape) {

        .pageWrapper {
            padding-left: 5%;
            overflow-y: auto;
            /* border: 2px solid black; */
            width: 90vw;

            height:auto;
        }

        .popup3BtnWrapper{
            margin-top:5%;
        }
        .popupBtn3 {
        
        background-color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 0px;
        left: 0px;
        height: 44px;
        width: 150px;
        border-radius: 10px;
        z-index: 6;

        margin-left: 5%;
        min-width: 120px;
        }

        .monthSection{
            margin-top: 15vh;
            margin-left: 1.5vw;
        }
    
        .yearSection{
            margin-top: 15vh;
            margin-left: -43.5vw;
    
        }
    
        .yearSelect1{
            min-width: 20vw;
           width: 20vw;
           margin-left: 2vw;
           margin-right:2vw;

        }

        .includeParToggle{
            margin-left: 17.5vw !important;
        }
        .triggerPurchaseToggle{
            margin-left: 4vw !important;

        }

        .checklistInput{
            margin-left: 0%;
            width: 5vw !important;
            height: 7vh !important;
            border-radius: 7px !important;
            font-size: .8rem !important;
            text-align: center !important;
        }

        .ChecklistDescription{

            text-align: center;
            width: 10vw;
            height: 45px;
            margin-top: 0%;
            margin-right: 2.2%;
            margin-bottom: 3% ;
          }
        .weekSection{
         margin-top: 12vh;
         margin-bottom: 3%;
         width: 284px;
         text-align: left;
         margin-left: 2%;
        }
        .imageUpload1{
            width: 9vw;
            padding: 1%;
            text-align: center;
            align-content: center;
            display: block;
            height: 5vh;
            padding-left: 3.5%;
            margin-left: 1vw;
          }

          .uploadIcon{
            margin-left: 92%;
            margin-top: -24.5%;
        }

        .visualfieldSection{
            width:12vw;
        }
    

        .visualDescription{
            width:25vw;
            height:5vh;
        }
        .popup2DestinationSection{
            margin-top: 10vh;
        }
        .visualCheckbox{
            margin-top: 0%;
        }
    
        .editWrapper{
            overflow-y: visible !important;
        }
        .defaultInp2{
            min-height: 6vh !important;

        }
        .defaultInp{
            height: 5vh !important;
            min-height: 5vh !important;
            max-height: 5vh !important;
            max-width:100%;
        }

        .userResponse{
            width:10vw !important;
            min-width:10vw !important;

        }
        .selectWrapperForm{
            height:6vh;
            margin-left: 3vw;
        }

            .typeOfSection{
                width:72vw;
            }
.bottomButtons{
    margin-top: 3%;
    left:3vw;
}
        .popupMessage{
            display: none;
            visibility: hidden;
            left: 5vw;
            top: 5vh;
            position: absolute;
            z-index: 100;
            border: 2px solid black;
            width: 60vw;
            height: 50vh;
            background-color: white;
            text-align: center;
            position: relative;
            left: 22vw;
            top: 15vh;
            position: absolute;
        
        
            visibility: hidden;
            display:none;
        }
        .popupMessage3 {
            z-index: 2;
            border: 2px solid black;
            width: 91vw;
            height: 83vh;
            background-color: white;
            text-align: center;
            position: relative;
            left: 8vw;
            top: 13vh;
            position: absolute;
            visibility: none;
            z-index: 100;
            display: none;
            
            overflow: scroll;
        }
        .editArea{
            margin-left: -6%;
        }

        .enterPar{
            width:5vw;
        }
        
        .popupMessage2 {
            display: none;
            visibility: hidden;
            left: 5vw;
            top: 5vh;
            position: absolute;
            z-index: 100;
            border: 2px solid black;
            width: 91vw;
            height: 83vh;
            background-color: white;
            text-align: center;
            position: relative;
            left: 7vw;
            top: 13vh;
            position: absolute;
        
        
            visibility: hidden;
            display:none;
        }

        .showOptionsWidthAdjust{
            width:auto;
            min-width: 10vw;
        }

        .select2{
            width:145px;
        }
        .selectWrapperForm{
            width:6vw; 
            margin-right: 5vw;

        }
        .showOptions{

            overflow: visible !important;
        }
        .includeParToggle2{
            margin-left: 22%;
        }

        .itemSelect{
            min-width: 125px;
            z-index: 100;
        }
        .nameSectionTitle{
            max-height: 2vh !important;
            height:2vh !important; 


            font-size: 1rem;
        }

   
        


  }
  

  
  @media screen and (max-width: 375px) {
 
  }
  
  /* 19.5/9 aspect ratio for mobile */
  @media screen and (max-aspect-ratio: 19.5/9) {

  }

  
  @media screen and (min-width: 550px) and (max-width: 1300px) and (max-height: 600px) {
    .pageWrapper {
        padding-left: 5%;
        height: 92vh;
        overflow-y: auto;
    }

    .buttonStyling {
        height: 4vh;
        min-height: 4vh;
        width: 20vh;
    }

    .popupMessage {
        width: 80vw;
        height: 80vh;
        left: 10vw;
        top: 10vh;
    }

    .popupMessage2 {
        width: 80vw;
        height: 80vh;
        left: 10vw;
        top: 10vh;
    }

    .popupMessage3 {
        width: 80vw;
        height: 80vh;
        left: 10vw;
        top: 10vh;
    }

    .defaultInp {
        height: 2vh;
        padding: 0.5rem;
    }

    .defaultInp2 {
        height: 2vh;
        padding: 0.5rem;

        min-height: 6vh !important;
        
    }

    .pu3boxWrap {
        height: 100%;
        margin-top: 2vh;
    }

    .bottomButtons {
        position: fixed;
        bottom: 2vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .typeOfSection {
        width: 40vw;
        margin-bottom: 2vh;
    }

    .userSelect,
    .assignUserSelect {
        width: 25vw;
        min-width: 150px;
        height: 4vh;
    }
}



select {
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    /* Prevent zoom on iOS */
    font-size: 16px !important;
  }




  @media screen and (max-width: 1249px) and (max-height: 767px) {
    /* Add your styles for screens under 1250px width and under 768px height */
    
    .pageWrapper {
      /* Adjust the padding and dimensions as needed */
      padding-left: 5%;
      height: auto;
      overflow-y: auto;
    }
    
    .buttonStyling {
      /* Modify button styles */
      height: 4vh;
      min-height: 4vh;
      width: 20vh;
    }
    
    .popupMessage,
    .popupMessage2,
    .popupMessage3 {
      /* Adjust popup dimensions and positioning */
      width: 80vw;
      height: 80vh;
      left: 10vw;
      top: 10vh;
    }
    
    .defaultInp,
    .defaultInp2 {
      /* Modify input field styles */
      height: 2vh;
      padding: 0.5rem;
    }
    
    .editArea{
        margin-left: -1%;
    }
    .pu3boxWrap {
      /* Adjust pu3boxWrap styles */
      height: 100%;
      margin-top: 2vh;
    }
    
    .bottomButtons {
      /* Modify bottom buttons positioning */
      position: relative;
      bottom: 2vh;
      left: 63%;
      transform: translateX(-50%);
    }
    
    .typeOfSection {
      /* Adjust typeOfSection styles */
      width: 80vw;
      margin-bottom: 2vh;
    }
    
    .userSelect,
    .assignUserSelect {
      /* Modify user select styles */
      width: 25vw;
      min-width: 150px;
      height: 4vh;
    }

    .cancelbtn{
        margin-left: 1vw;
    }
    .sectionDiv{
        overflow: auto;
        width: 88vw;
    }
    .headerWrapper{
        margin-left:9.2%;
    }
    .includeParToggle2{
        margin-left:16.3%;
    }
    .triggerPurchaseToggle2{
        margin-left: 5.2%;
        margin-right:2%;
    }
    .userResponseText{
        margin-left: 14%;
    }
    .defaultInp{
        width:13vw;
    }

    .custom-select select {
        /* Your existing select styles */
        height: 30px; /* Default height when closed */
        transition: height 0.3s ease; /* Add a smooth transition effect */
    }
    
    .custom-select.open select {
        height: 400px !important; /* Expand the height when the dropdown is open */
        max-height: 400px !important; /* Set a maximum height for the expanded dropdown */
        overflow-y: auto; /* Add a vertical scrollbar if the options exceed the max height */
    }
     .open {
        height: 400px !important; /* Expand the height when the dropdown is open */
        max-height: 400px !important; /* Set a maximum height for the expanded dropdown */
        overflow-y: auto; /* Add a vertical scrollbar if the options exceed the max height */
    }

    .custom-select select.expanded {
        height: 400px !important; /* Expand the height when the dropdown is open */
        max-height: 400px !important; /* Set a maximum height for the expanded dropdown */
        overflow-y: auto; /* Add a vertical scrollbar if the options exceed the max height */
    }
  }