.speech-bubble-container {
    position: fixed;
    z-index: 50;
  }
  
  .speech-bubble {
    width: 100%;
    max-width: 20rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .speech-bubble.open {
    display: block;
  }
  
  .speech-bubble-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #3b82f6;
    color: #fff;
    font-weight: 700;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  
  .close-button {
    color: #fff;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #e5e7eb;
  }
  
  .speech-bubble-messages {
    max-height: 24rem;
    overflow-y: auto;
    padding: 1rem;
  }
  
  .message {
    margin-bottom: 0.5rem;
  }
  
  .speech-bubble-input {
    padding: 0.5rem 1rem;
    background-color: #f3f4f6;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  
  .message-input {
    width: 100%;
    padding: 0.25rem 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    outline: none;
  }
  
  .chat-button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #3b82f6;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
  }
  
  .chat-button:hover {
    background-color: #2563eb;
  }
  
  .chat-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #ef4444;
    color: #fff;
    font-weight: 700;
    font-size: 0.75rem;
    border-radius: 9999px;
    margin-right: 0.5rem;
  }