@media(max-width:600px){
  .raise{
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      margin-bottom:'1%'
  }
}
@media (min-width: 600px) {
  .taskHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    align-items: center;
    padding: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
  }

  .pageWrapper{
    height: 81%;
    padding-left: 7%;
    overflow-y: scroll;
    width: 91vw;
  }

  .taskItem {
    grid-template-columns: 1fr 1fr 1fr auto;
    grid-template-areas: "form display status actions";
  }

  .spanWidth2 {
    display: flex;
    flex-direction: column;
  }

  .dateRange {
    display: inline;
  }

  .taskStatus, .taskActions {
    text-align: center;
  }


}

  /* Responsive styles for screens with a width of 300px and above */
  @media screen and (min-width: 300px) {
    .summaryItem {
      width: 100%;
      margin: 1% 0;
    }
  
    .cardHeader {
      padding: 2%;
      font-size: 16px;
    }
  
    .topCard {
      padding: 2%;
    }
  
    .midCard {
      padding: 2%;
    }
  
    .cardRow {
      flex-direction: column;
      margin-bottom: 2%;
    }
  
    .cardLineItem {
      width: 100%;
      margin-bottom: 1%;
    }
  
    .leftItem1
    {
        max-width: 17%;
        text-align: left;
        min-width: 17%;
      text-align: left;
    }
  
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.modalButtons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modalButtons button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

/* For single-button popups, we can style it differently than the dual-button modals */
.modalButtons button:only-child {
  background-color: #007bff;  /* Use your preferred primary color */
  color: white;
  border: none;
}

/* Keep your existing styles for two-button scenarios */
.modalButtons button:first-child {
  background-color: #f1f1f1;
  color: #333;
  border: none;
}

.modalButtons button:last-child {
  background-color: #f44336;
  color: white;
  border: none;
}
    .blackLineDiv2 {
      width: 100%;
      margin-left: 0;
    }
    .submitCancelWrapper{
      display: 'flex';
      margin-left: 24vw;
     /* margin-bottom: 15vh;*/
  }
    .bottomCard {
      flex-direction: column;
      padding: 2%;
    }
  
    .leftHalfBC,
    .rightHalfTC {
      width: 100%;
      margin-bottom: 2%;
    }
  
    .donebtn {
      width: 100%;
      margin-top: 2%;
    }
  }
  
  /* Base styles */
.mainHeader, .mainHeader2 {
    background-color: #f5f5f5;
    width: 100%;
    margin-left: 0%;
    border-bottom: 2px solid grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    height: 60px;
    overflow: visible;
  }
  
  .toolbarSection {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    position: relative;
    height: 100%;
  }
  .profileButton, .profileButtonNoAnim {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 25px 25px 25px 25px;
    padding: 0.5rem 1rem;
    box-shadow: 
      0 -2px 6px 1px rgba(60, 64, 67, 0.15),
      2px 0 6px 1px rgba(60, 64, 67, 0.15),
      -2px 0 6px 1px rgba(60, 64, 67, 0.15);
    transition: all 0.3s ease-in-out, border-radius 0s;    cursor: pointer;
    height: 30px;
    width: 225px;
    margin-right: 4%;
    position: relative;
    z-index: 2;
    overflow: visible;




    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    z-index: 1000;



  }
  
  .profileButton:hover {
   /* height: auto;*/
    border-radius: 25px 25px 0px 0px;
    transition: all 0.3s ease-in-out, border-radius 0s;

  }
  
  .profileButton::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    height: 0;
    transition: height 0.3s ease-in-out 0.1s;
    z-index: -1;
    border-radius: 0 0 25px 25px;
    box-shadow: 2px 2px 5px 1px rgba(60, 64, 67, 0.15), -2px 2px 6px 1px rgba(60, 64, 67, 0.15);
    opacity: 0;
    clip-path: inset(0px -10px -10px -10px);
  }
  
  .profileButton:hover::after {
    height: 220px; /* Adjust this value to match your desired expanded height */
    margin-top: 0%;
    border: 0px solid white;
    /* box-shadow: none; */
    clip-path: inset(0px -10px -10px -10px);
    opacity: 1;

  }
  
  .usernameSpan, .usernameSpan2 {
    position: relative;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    z-index: 3;
  }
  
  .blackLineDiv {
    height: 1px;
    background-color: black;
    opacity: 0;
    transition: opacity 0.3s  ease-in-out 0.1s ;
    margin: 10px -6rem 0;
    position: relative;
    z-index: 3;
    width: calc(100% + 12rem);
    transition-delay: 0.1ms;

  }
  
  .profileButton:hover .blackLineDiv {
    opacity: 1;
    transition-delay: 0.2s;

  }
  
  .pageOption {
    text-align: left;
    padding-left: 5%;
    opacity: 0;

    transform: translateY(-20px);
    position: relative;
    transition: opacity 0s, transform 0.3s ease-in-out;  
      z-index: 3;
  }
  
  .profileButton:hover .pageOption {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
    transition: opacity 0s 0.3s, transform 0.3s ease-in-out 0.3s;
  }
  
  /* Responsive styles */
  @media screen and (min-width: 768px) {
    .toolbarSection {
      width: auto;
      margin-left: auto;
    }

  }
  
  @media screen and (min-width: 1024px) {
    .toolbarSection {
      margin-left: 90%;
    }
    .mainHeader{
      width: 99%;
      margin-left: 1%;
    }
  }
    
  @media screen and (max-width: 1024px) {

    .mainHeader{
      width: 95.8%;
      margin-left:4.3%;
    }
  }






  /* Mobile styles */
  @media screen and (max-width: 767px) {
    .hideFromMobile {
      display: none;
    }
  
    .toolbarSection{
        margin-left: 94%;
    }

    .mainHeader {
      height: 7%;
 
    }
  
    .profileButton, .profileButtonNoAnim {
      width: 125px;
      margin-right: 0;
    }
  
    .usernameSpan, .usernameSpan2 {
      justify-content: flex-start;
    }
  
    .goodMorning {
      width: 250px;
      font-size: 23px;
    }

    .blackLineDiv{
        width: calc(100% + 7rem);
        margin-left: -128%;
    }
 
  }
  
  /* Other styles (login form, popup) remain unchanged */




  
  body {}

  .container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
  }

  .screen {
    position: relative;
    height: 425px;
    width: 555px;
    border:2px solid black;
    border-radius: 12px;
    margin-top: -9%;
    background-color: white;
    padding-top: 1%;
    padding: 1%;
    margin-top: '23%';
    margin-left:'30%';

  }

  .screen__content {
      z-index: 1;
      position: relative;
      height: 100%;
  }

  .screen__background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      -webkit-clip-path: inset(0 0 0 0);
      clip-path: inset(0 0 0 0);
  }

  .screen__background__shape {
      position: absolute;
  }

 

  .login {
      width: 320px;
      padding: 30px;
      padding-top: 156px;
  }

  .login__field {
      padding: 20px 0px;
      position: relative;
  }

  .login__icon {
      position: absolute;
      top: 30px;
      color: #7875B5;
  }

  .login__input {
      border: none;
      border-bottom: 2px solid #D1D1D4;
      background: none;
      padding: 10px;
      padding-left: 24px;
      font-weight: 700;
      width: 75%;
      transition: .2s;
  }

  .login__input:active,
  .login__input:focus,
  .login__input:hover {
      outline: none;
      border-bottom-color: #ff8f16;
  }

  .login__submit {
    margin-left: 30%;
    font-size: 14px;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 20px;
    border: 1px solid #D4D3E8;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 40%;
    color: #4C489D;
    /* box-shadow: 0px 1px 0px 0px #ff8f16; */
    cursor: pointer;
    transition: .2s;
    margin-left: -35%;
    background-color: white;
  }

  .login__submit:active,
  .login__submit:focus,
  .login__submit:hover {
      border-color:#ff8f16;
      outline: none;
  }

  .button__icon {
      font-size: 24px;
      margin-left: auto;
      color: #ff8f16;
  }

  .social-login {
      position: absolute;
      height: 140px;
      width: 160px;
      text-align: center;
      bottom: 0px;
      right: 0px;
      color: #fff;
  }

  .social-icons {
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .social-login__icon {
      padding: 20px 10px;
      color: #fff;
      text-decoration: none;
      text-shadow: 0px 0px 8px #7875B5;
  }

  .social-login__icon:hover {
      transform: scale(1.5);
  }

  .popup-inner button {
      margin-top: 20px;
  }

  .popup-inner button[type="button"] {
      margin-top: 20px;
      background-color: #f44336;
  }

  .popup-inner button[type="button"]:hover {
      background-color: #da190b;
  }

  .popup-inner button:disabled {
      background-color: #bfbfbf;
      color: #ffffff;
      cursor: not-allowed;
  }

  .popup-inner button[type="button"]:disabled {
      background-color: #bfbfbf;
      color: #ffffff;
      cursor: not-allowed;
  }
  @media screen and (max-width: 767px) {
    .screen {
      width: 90%;
      height: auto;
      margin-top: 10%;
      padding: 5%;
    }
  
    .login {
      width: 100%;
      padding: 20px;
      padding-top: 100px;
    }
  
    .login__input {
      width: 100%;
    }
  
    .login__submit {
      width: 60%;
      margin-left: 20%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .screen {
      width: 84%;
      margin-top:-50%;
      padding: 3%;
    }
  
    .login {
      padding-top: 50px;
    }
  
    .login__input{
        width:75%
    }


    .login__submit {
        margin-top: 23%;
        margin-left: 11%;
      width: 80%;
    }
  }

  .cardHeader{
    text-align: left;
    background-color: #0c1620;
    padding: .6%;
    color: white;
    border-radius: 4px 4px 10px 10px;
    }
    
    .midCard{
        width:100%;
        padding:1%;
        background-color: white;
        padding-top:.5%;
    }
    .cardRow{
        display:flex;
        margin-bottom: 1%;
        padding:0%;
        margin:0%;
    }
    
    .cardLineItem {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1%;
      }
      
      .leftItem1,
      .rightItem1 {
        flex: 1;
        margin-right: 1%;
      }
      
   

    .cardLineItem{
        width:800px;
        display:flex;
        text-align: left;
    }
    
    .summaryArea{
        display: block;
        width: 100%;
        margin-left: 0%;
        height: 100%;
        overflow: scroll;
        margin-top: .5%;
    }
    
    
    
    .topCard{
        background-color: white;
        display: block;
        padding-left:1%;
        width:93%
    }
    
    .summaryItem{
        background-color: white;
        width:40%;
        min-height:200px;
        margin-top:1%;
        margin-bottom:2%;
        border:1px solid black;
        border-radius: 8px 10px 0px 0px;
        margin-left:auto;
        margin-right: auto;
    }
    
    .leftHalfBC{
        float:0;
        width:70%;
        margin:.5%; 
        display: inline-grid;
        text-align: left;

    }
    
    .rightHalfTC{
        float:1;
        width:30%;
        margin:.5%; 
        display: inline-grid;
        text-align: left;

    }
    
    
    
    
    .donebtn{
        background-color: rgb(255, 255, 255);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_text_default);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        padding: 12px 16px;
        opacity: 1;
        /* margin-right: 5%; */
        cursor: pointer;
        top: 857px;
        left: 1064px;
        /* height: 44px; */
        width: 150px;
        border-radius: 10px;
    
    }
    .blackLineDiv2 {
        color: black;
        height: 1px;
        background-color: black;
        margin-bottom: 1%;
        width: 10%;
        margin-top: .5%;
        left: 4%;
        position: relative;
        margin-left: -4%;
    
    }
    .bottomCard{
        width:93%;
        min-height:100px;
        background-color: white;
        display:flex;
        padding-left: 1.5%;
    }
    
    
    
.popupBackground {
    width: 1800px;
    height: 1000px;
    background-color: whitesmoke;
    margin-top: -5%;
    position: absolute;
    /* margin-left: -3%; */
    opacity: .8;
    visibility: hidden;
    z-index: 1;

}

.popupMessage {
    z-index: 2;
    border: 2px solid black;
    width: 500px;
    height: 200px;
    background-color: white;
    text-align: center;
    position: relative;
    left: 44vw;
    top: 25vh;
    position: absolute;
    visibility: hidden;
    padding-top: 1%;
}

.orangeInput2 {
    background-color: #fdf0df;
    width: 200px;
    border-radius: 5px;
    height: 28px;
}

.mb2{
  margin-right: 3vw;
}

.orangeInput {
    background-color: #fdf0df;
    width: 600px;
    border-radius: 5px;
    height: 28px;

}

.checkboxDescription{
  width: 3h5vw;
  text-align: left;
}

.responseWrapper{
  display:flex !important;
}

.otherFields {
    margin-left: 0%;
    display: flex;
    align-items: baseline;
    overflow-x: overlay;
}


.customScroll {
    max-height: 400px; /* Adjust this value as needed */
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */
  }
  
  /* For Webkit browsers like Chrome */
  .customScroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .customScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .customScroll::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .customScroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }



.itemDiv {
    width: 300px;
    height: 200px;
    background-color: grey;
}

.titleSection {
    text-align: left;
    margin-top: 1%;
    margin-bottom: 3%;
    font-size: 22px;

}


.existingUser {
    margin-left: 1%;
}

.userSelect {
    position: relative;
    box-sizing: border-box;
    width: 16%;
    padding: 0%;
    margin-top: .5%;

    margin-bottom: 2%;
    height: 30px;
    border-radius: 5px;



}


.field {
    margin-top: 3%;
    margin-left: 1%;
    width: 100%;
    display: inline-flex;
}


.contactInfo {
    margin-left: 1%;
}

.leftCol {
    width: 40%;
    padding-bottom: .5%;
    float: 0;
    text-align: left;
}

.rightCol {
    width: 60%;
    text-align: left;
    float: 1;
}


.availabilityDay {
    width: 36px;
    height: 20px;
    background-color: white;
    border: 2px solid black;
    padding: 1.1%;
    border-radius: 5px;
    margin-left: 3%;
    text-align: center;
    margin-top: 2%;
}

.rowOfBoxes {
    display: flex;
}

.availabilityBox {
    display: inherit;
    position: inherit;
    text-align: left;
    margin-left: 2%;
    margin-top: 2%;


}

.availabilityText {
    margin-left: 3%;
}

.createItem {
    margin-right: 20%;
}

.jobSelect {
    width: 100%;
    margin-bottom: 3%;
}



.vendorSelect {
    margin-bottom: 3%;
    width: 27%;
}

.locationSelect {
    width: 35%;
}

.createUserText {
    margin-left: 20%;
}


.itemSearch {
    margin-right: 25%;
}



.jobPositionText {
    margin-left: 1%;
    margin-right: 8%
}

.rgText1 {
    margin-left: 1%;
    margin-right: 1.5%;
}

.blackLineDiv2 {
    color: black;
    height: 1px;
    background-color: black;
    margin-bottom: 1%;
    width: 101.7%;
    margin-top: 1.5%;
    left: -2%;
    left:4%;
    position: relative;
}


.locationText {
    margin-top: 5%;

}


.formBtn2 {
    background-color: rgb(252, 143, 20);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_primary_contrast_default);
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    top: 857px;
    left: 1373px;
    width: 135px;
    height: 38px;
    z-index: 4;
}

.reportingGroupForm {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 14.3%;
    left: 21.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.notificationBody {
    transition: 0.3s ease-in-out;
    height: 10%;
    overflow: hidden;
    cursor: pointer;
    max-width: 100%;
    width: calc(100% - 2rem);
    margin: 0px 1rem;
    
    min-height: 10vh;
    display:flex;
}

.notificationWrapper {
    position: relative;
  }


.dropdownHolder{
    margin-top: 10px;
    padding: 1%;
}

.expanded {
    height: auto; /* or a specific height if you prefer */
    /* Add any additional styles for the expanded state */
}

.expandedNote {
    display: block;
    margin-top: 10px;
    padding-bottom:10vh;
    /* Add any styles for the expanded note */
}
.midbuffer{
    width: 100%;
text-align: center;
vertical-align: middle;
line-height: 35px;

}




.raise {
    box-shadow: none;
    transition: 0.25s;
}

.raise:hover {
    box-shadow: rgb(0 0 0) 0px 2px 5px;
    transition: 0.25s;
    transform: translateY(-.3em);

}



.pagebody{
    justify-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 1200px;
    display:flow;
}
.iconBuffer{
    width: 80px;
}
.saveBtn {
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    background-color: rgb(252, 143, 20);
    font-size: 15px;
    font-weight: 600;
    color: white;
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    align-self: flex-start;
    min-width: 135px;
    max-width: 135px;
    order: 9;
    min-height: 47px;
    width: 135px;
    flex-grow: 1;
    height: max-content;

    margin-left: 5%
}

.cancelbtn {
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(163, 163, 163);
    border-radius: 3px;
    opacity: 1;
    cursor: pointer;
    align-self: flex-start;
    min-width: 135px;
    max-width: 135px;
    order: 8;
    min-height: 47px;
    width: 135px;
    flex-grow: 1;
    height: max-content;
}

.sectionDiv {
    margin-top: 1%;
    margin-bottom: 1%;
    padding-bottom: 2%;
    border-bottom: 2px solid grey;
}

.includeParInput {
    height: 22px;
    border: 2px solid #cccccc;
    padding: 3%;
}

.visualfieldSection {
    display: grid;
    margin-left: 5%;
    width: 170px;
}

.ChecklistfieldSection {
    display: grid;
    margin-left: 5%;
}

.ResfieldSection {
    display: grid;
    margin-left: 5%;
    width: 385px;
}

.checkboxes label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
}

.checkboxes input {
    vertical-align: middle;
}

.checkboxes label span {
    vertical-align: middle;
}

.addToGroups {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 16.3%;
    left: 22.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.inpLabel {
    width: 215px;
}

.inlineBox {
    display: inline;
}


.createCatagoryTextBtn {
    margin-left: 22%;
    margin-right: 30%;
}




.userSelect {
    position: relative;
    box-sizing: border-box;
    width: 16%;
    padding: 0%;
    margin-top: .5%;
    margin-left: 1%;


    height: 30px;
    border-radius: 5px;



}


.field {
    margin-top: 5%;
    margin-left: 1%;

    width: 100%;
    display: inline-flex;


}


.contactInfo {
    margin-left: 1%;
}


.leftCol {
    width: 40%;
    float: 0;
    text-align: left;
}

.rightCol {
    width: 60%;
    text-align: left;
    float: 1;
}



.availabilityDay {
    width: 36px;
    height: 20px;
    background-color: white;
    border: 2px solid black;
    padding: 1.1%;
    border-radius: 5px;
    margin-left: 3%;
    margin-top: 2%;
}

.rowOfBoxes {
    display: flex;
}

.availabilityBox {
    display: inline-block;

}

.inputArea {
    display: flex;
    margin-top: 1.5%;
}

.sectionSelect {
    margin-left: 10%;
}

.locationText {
    margin-top: 3%;

}


.popupBtn {
    width: 80px;
    height: 45px;
    margin-right: 2%;
    border-radius: 5px;
    background-color: #fdf0df;
}



@media (min-aspect-ratio: 16/9) {



    .popupBtn {
        width: 200px;
        height: 35px;
        margin-right: 2%;
        border-radius: 5px;
        background-color: #fdf0df;
        margin-top: 3%;
    }

    .popupBackground {
        width: 1800px;
        height: 1000px;
        background-color: whitesmoke;
        margin-top: -5%;
        position: absolute;
        /* margin-left: -3%; */
        opacity: .8;
        visibility: hidden;
        z-index: 1;
    
    }
    
    .popupMessage {
        z-index: 2;
        border: 2px solid black;
        width: 500px;
        height: 200px;
        background-color: white;
        text-align: center;
        position: relative;
        left: 44vw;
        top: 25vh;
        position: absolute;
        visibility: hidden;
        padding-top: 1%;
    }
    .defaultInp {
        margin-top: 1%;
        margin-left: 0%;
        height: 44px;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
    }
    .createAnotherRequestBtn {
        font-size: 13px;
        background-color: rgb(255, 255, 255);
        font-family: var(--font_default);
        font-weight: 600;
        color: var(--color_text_default);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        border-radius: 1px;
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        left: 1064px;
        width: 300px;
        height: 45px;
        z-index: 6;
    }



    .reportingGroupForm {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        top: 14.3%;
        left: 21.5%;
        position: absolute;
        text-align: left;
        width: 69.7%;
        padding: 0.5%;
    }

    .itemSelect {
        position: relative;
        box-sizing: border-box;
        width: 90%;
        padding: 0%;
        margin-top: .5%;
        margin-left: 1%;
        height: 30px;
        border-radius: 5px;


    }

    .saveBtn {
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        background-color: rgb(252, 143, 20);
        font-size: 15px;
        font-weight: 600;
        color: white;
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        align-self: flex-start;
        min-width: 135px;
        max-width: 135px;
        order: 9;
        min-height: 47px;
        width: 135px;
        flex-grow: 1;
        height: max-content;

        margin-left: 5%
    }

    .cancelbtn {
        background-color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 400;
        color: rgb(0, 0, 0);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(163, 163, 163);
        border-radius: 3px;
        opacity: 1;
        cursor: pointer;
        align-self: flex-start;
        min-width: 135px;
        max-width: 135px;
        order: 8;
        min-height: 47px;
        width: 135px;
        flex-grow: 1;
        height: max-content;
        margin-left: 2%;
    }

    .sectionDiv {
        margin-top: 1%;
        margin-bottom: 1;
        padding-bottom: 2%;
        border-bottom: 2px solid grey;
        width: 98%
    }

    .includeParInput {
        height: 22px;
        border: 2px solid #cccccc;
        padding: 3%;
    }

    .visualfieldSection {
        display: grid;
        margin-left: 5%;
        width: 170px;
    }

    .ChecklistfieldSection {
        display: grid;
        margin-left: 5%;
    }

    .ResfieldSection {
        display: grid;
        margin-left: 5%;
        width: 385px;
    }

    .checkboxes label {
        display: inline-block;
        padding-right: 10px;
        white-space: nowrap;
    }

    .checkboxes input {
        vertical-align: middle;
    }

    .checkboxes label span {
        vertical-align: middle;
    }

    .addToGroups {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        top: 16.3%;
        left: 22.5%;
        position: absolute;
        text-align: left;
        width: 69.7%;
        padding: 0.5%;
    }

    .inpLabel {
        width: 215px;
    }

    .inlineBox {
        display: inline;
    }


    .createCatagoryTextBtn {
        margin-left: 22%;
        margin-right: 30%;
    }




    .userSelect {
        position: relative;
        box-sizing: border-box;
        width: 16%;
        padding: 0%;
        margin-top: .5%;
        margin-left: 1%;


        height: 30px;
        border-radius: 5px;



    }


    .field {
        margin-top: 5%;
        margin-left: 1%;

        width: 100%;
        display: inline-flex;


    }


    .contactInfo {
        margin-left: 1%;
    }


    .leftCol {
        width: 40%;
        float: 0;
        text-align: left;
    }

    .rightCol {
        width: 60%;
        text-align: left;
        float: 1;
    }



    .availabilityDay {
        width: 36px;
        height: 20px;
        background-color: white;
        border: 2px solid black;
        padding: 1.1%;
        border-radius: 5px;
        margin-left: 3%;
        margin-top: 2%;
    }

    .rowOfBoxes {
        display: flex;
    }

    .availabilityBox {
        display: inline-block;

    }

    .inputArea {
        display: flex;
        margin-top: 1.5%;
    }

    .sectionSelect {
        margin-left: 10%;
    }

    .bottomSection {
        position: relative;
        left: 12.5%;
        top: 600px;
        width: 70%;

    }

    .selectGrouper {
        width: 57%;
        text-align: left;
        margin-left: 2%
    }

    .locationText {
        margin-top: 3%;

    }

    .formBtnDiv {
        position: relative;
        width: 46%;
        margin-top: 1%;
        left: 61%;
        display: flex;
    }


    .formBtn {

        background-color: #fff;
        border: 1px solid #2f2f2f;
        border-radius: 10px;
        color: var(--color_text_default);
        cursor: pointer;
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        height: 44px;
        left: 1064px;
        line-height: 1;
        margin-right: 5%;
        opacity: 1;
        padding: 12px 16px;
        text-align: center;
        top: 857px;
        width: 300px;
        z-index: 6;
    }


    .formBtn2 {
        background-color: rgb(252, 143, 20);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_primary_contrast_default);
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        top: 857px;
        left: 1373px;
        width: 135px;
        height: 38px;
        z-index: 4;
    }



    .userForm {
        position: relative;
        text-align: left;
        padding: 0.5%;
    }

    .inpLabel {
        width: 126px;
    }


    .dynamicFormSection {
        display: flex;
    }

    .container1 {
        border: 1px solid black;
        padding: 0%;
        width: 760px;
        height: 350px;
        border-radius: 6px;
        overflow-y: scroll;
        padding-left: 3%;
        background-color: white;
    }

    .optionHeader {
        text-align: left;
        padding: 2%;
        border-bottom: 1px solid black;
        margin-left: -3%;
    }

    .orangeButton {

        height: 44px;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
        margin-top: 0%;
        text-align: center;
        width: 80px;
    
    
    }

    .orangeInput2 {
        background-color: #fdf0df;
        width: 200px;
        border-radius: 5px;
        height: 28px;
    }

    .orangeInput {
        background-color: #fdf0df;
        width: 450px;
        border-radius: 5px;
        height: 28px;

    }

    .otherFields {
        margin-left: 0%;
        display: flex;
        align-items: baseline;
        overflow-x: overlay;
    }

    .otherFields2 {
        margin-left: 8%;
        display: flex;
        align-items: baseline;
        overflow-x: overlay;
        width: 100%;
    }

    .otherFields3 {
        margin-left: 0%;
        display: flex;
        align-items: baseline;
        overflow-x: overlay;
        width: 100%;
    }
    .otherFields3 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .itemDiv {
        width: 300px;
        height: 200px;
        background-color: grey;
    }

    .titleSection {
        text-align: left;
        margin-top: 1%;
        margin-bottom: 3%;
        font-size: 22px;

    }

    .userSub {
        width: 1470px;
    }


    .defaultInp {
        margin-top: 1%;
        margin-left: 0%;
        height: 44px;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
    }

    .existingUser {
        margin-left: 1%;
    }

    .userSelect {
        position: relative;
        box-sizing: border-box;
        width: 16%;
        padding: 0%;
        margin-top: .5%;

        margin-bottom: 2%;
        height: 30px;
        border-radius: 5px;



    }


    .field {
        margin-top: 3%;
        margin-left: 1%;
        width: 100%;
        display: inline-flex;
    }


    .contactInfo {
        margin-left: 1%;
    }

    .leftCol {
        width: 40%;
        padding-bottom: .5%;
        float: 0;
        text-align: left;
    }

    .rightCol {
        width: 60%;
        text-align: left;
        float: 1;
    }


    .availabilityDay {
        width: 36px;
        height: 20px;
        background-color: white;
        border: 2px solid black;
        padding: 1.1%;
        border-radius: 5px;
        margin-left: 3%;
        text-align: center;
        margin-top: 2%;
    }

    .rowOfBoxes {
        display: flex;
    }

    .availabilityBox {
        display: inherit;
        position: inherit;
        text-align: left;
        margin-left: 2%;
        margin-top: 2%;


    }

    .availabilityText {
        margin-left: 3%;
    }

    .createItem {
        margin-right: 20%;
    }

    .jobSelect {
        width: 100%;
        margin-bottom: 3%;
    }



    .vendorSelect {
        margin-bottom: 3%;
        width: 27%;
    }

    .locationSelect {
        width: 35%;
    }

    .createUserText {
        margin-left: 20%;
    }


    .itemSearch {
        margin-right: 25%;
    }

    .jobPositionText {
        margin-left: 1%;
        margin-right: 8%
    }

    .rgText1 {
        margin-left: 1%;
        margin-right: 1.5%;
    }

    .bottomSection {
        width: 81%;
    }

    .selectGrouper {
        width: 57%;
        text-align: left;
        margin-left: 2%
    }
    .subPageHeaders{
        display:flex;
        margin-top: 1%;
        margin-bottom: 5vh;
    }
    .blackLineDiv2 {
        color: black;
        height: 1px;
        background-color: black;
        margin-bottom: 1%;
        width: 100%;
        margin-top: 1.5%;
        left: 4%;
        position: relative;
    }


    .locationText {
        margin-top: 5%;

    }



    .formBtn {

        background-color: #fff;
        border: 1px solid #2f2f2f;
        border-radius: 10px;
        color: var(--color_text_default);
        cursor: pointer;
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        height: 44px;
        left: 1064px;
        line-height: 1;
        margin-right: 5%;
        opacity: 1;
        padding: 12px 16px;
        text-align: center;
        top: 857px;
        width: 300px;
        z-index: 6;
    }


    .formBtn2 {
        background-color: rgb(252, 143, 20);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_primary_contrast_default);
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        top: 857px;
        left: 1373px;
        width: 135px;
        height: 38px;
        z-index: 4;
    }





}


/* Base styles */
body {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .userForm {
    position: relative;
    text-align: left;
    padding: 0.5%;
  }
  
  .inpLabel {
    width: 100%;
    max-width: 215px;
  }
  
  .defaultInp {
    margin-top: 1%;
    margin-left: 0;
    height: 2.75rem;
    width: 100%;
    max-width: 300px;
    border-radius: 0.625rem;
    background-color: white;
    border: 2px solid rgba(128, 128, 128, 0.36);
    padding: 1%;
  }
  
  .dynamicFormSection {
    display: flex;
    flex-wrap: wrap;
  }
  
  .container1 {
    border: 1px solid black;
    padding: 0;
    width: 100%;
    max-width: 760px;
    height: 21.875rem;
    border-radius: 0.375rem;
    overflow-y: auto;
    padding-left: 3%;
    background-color: white;
  }
  
  .optionHeader {
    text-align: left;
    padding: 2%;
    border-bottom: 1px solid black;
    margin-left: -3%;
  }
  
  .orangeButton {
    height: 2.75rem;
    width: 100%;
    max-width: 300px;
    border-radius: 0.625rem;
    background-color: white;
    border: 2px solid rgba(128, 128, 128, 0.36);
    padding: 1%;
    margin-top: 0;
    text-align: center;
    max-width: 5rem;
  }
  
  .groupDiv {
    display: contents;
  }
  
  .spanWidthtable1{
    display: inline;
    padding-left: 16%;
    max-width:150px;
    width: 150px;
    min-width: 150px;
  }

  .spanWidthtable2{
    display: inline;

    max-width:200px;
    width: 200px;
    min-width: 200px;
    margin-right: 2vw;
  }

  .spanWidth1 {
    width: 100%;
    max-width: 225px;
    display: inline;
  }
  
  .spanWidth2 {
    width: 100%;
    max-width: 100px;
    margin-left: 8.75vw;
    margin-right: 2.75vw;

    display: inline;
  }
  
  .spanWidth3 {
    width: 100%;
    max-width: 250px;
    display: inline;
  }
  
  .popupBackground {
    width: 100%;
    height: 100vh;
    background-color: whitesmoke;
    margin-top: -5%;
    position: fixed;
    opacity: 0.8;
    visibility: hidden;
    z-index: 1;
  }
  
  .popupMessage {
    z-index: 2;
    border: 2px solid black;
    width: 90%;
    max-width: 500px;
    height: auto;
    min-height: 200px;
    background-color: white;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    padding-top: 1%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  
    .container1 {
      height: 18.75rem;
    }
  
 .orangeButton {
      max-width: 100%;
    }
  
    .spanWidth1, .spanWidth2, .spanWidth3 {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    body {
      font-size: 12px;
    }
  
    .container1 {
      height: 15.625rem;
    }
  
    .inpLabel {
      max-width: 100%;
    }
    .pageWrapper{
        height: 66%;
        padding-bottom: 7%;
        overflow-y: scroll;
    }
    
  }
  
  /* Additional responsive styles */
  .pageWrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 98%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2% 1%;
  }
  .pageWrapper2 {
    overflow-y: scroll;
    padding-left: 0vw;
    width: 100vw;
    height: 82vh;
    max-width: 90vw;
    margin-left: 6vw;
  }
  

  .formBtnDiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2rem;
  }
  
  .formBtn, .formBtn2 {
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    font-size: 0.9375rem;
    cursor: pointer;
  }
  
  .formBtn {
    background-color: #fff;
    border: 1px solid #2f2f2f;
    color: var(--color_text_default);
    margin-right: 1rem;
  }
  
  .formBtn2 {
    background-color: rgb(252, 143, 20);
    color: white;
    border: none;
  }
  
  /* Responsive table-like structure */

  .DEfields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
 
  .DEfieldSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  /* Responsive form layout */
  .field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1rem;
  }
  
  .leftCol, .rightCol {
    flex: 1 1 100%;
  }
  
  @media (min-width: 768px) {
    .leftCol {
      flex: 0 0 40%;
    }
    .rightCol {
      flex: 0 0 60%;
    } 
    .pageWrapper2 {
        width: 100vw !important;
        height: 82vh !important;
        max-width: 90vw !important;
        margin-left: 6vw !important;
    }
    .mobileContainer {
      flex-direction: row;
      gap: 1rem;
    }
  
    .otherFields3 {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .DEfieldSection {
      flex: 1 1 auto;
    }
  }
  
  /* Responsive select inputs */
  .userSelect, .itemSelect {
    width: 100%;
    max-width: 300px;
    height: 2rem;
    border-radius: 0.3125rem;
    margin-top: 0.5rem;
  }
  
  /* Responsive button styles */
  .saveBtn, .cancelbtn {
    padding: 0.75rem 1.5rem;
    border-radius: 0.3125rem;
    font-size: 0.9375rem;
    cursor: pointer;
    min-width: 8.4375rem;
  }
  
  .saveBtn {
    background-color: rgb(252, 143, 20);
    color: white;
    border: none;
  }
  
  .cancelbtn {
    background-color: white;
    color: black;
    border: 1px solid #a3a3a3;
    margin-right: 1rem;
  }
  
  /* Responsive section dividers */
  .sectionDiv {
    margin: 1rem 0;
    padding-bottom: 1rem;
    border-bottom: 2px solid grey;
    width: 100%;
  }
  
  /* Responsive checkbox groups */
  .checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .checkboxes label {
    display: flex;
    align-items: center;
  }
  
  .checkboxes input {
    margin-right: 0.3rem;
  }

  /* Base styles */
body {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .userForm {
    position: relative;
    text-align: left;
    padding: 0.5%;
  }
  
  .inpLabel {
    width: 100%;
    max-width: 215px;
  }
  
  .defaultInp {
    margin-top: 1%;
    margin-left: 0;
    height: 2.75rem;
    width: 100%;
    max-width: 300px;
    border-radius: 0.625rem;
    background-color: white;
    border: 2px solid rgba(128, 128, 128, 0.36);
    padding: 1%;
  }
  
  .dynamicFormSection {
    display: flex;
    flex-wrap: wrap;
  }
  
  .container1 {
    border: 1px solid black;
    padding: 0;
    width: 100%;
    max-width: 760px;
    height: 21.875rem;
    border-radius: 0.375rem;
    overflow-y: auto;
    padding-left: 3%;
    background-color: white;
  }
  
  .optionHeader {
    text-align: left;
    padding: 2%;
    border-bottom: 1px solid black;
    margin-left: -3%;
  }
  
  .orangeButton {
    height: 2.75rem;
    width: 100%;
    max-width: 300px;
    border-radius: 0.625rem;
    background-color: white;
    border: 2px solid rgba(128, 128, 128, 0.36);
    padding: 1%;
    margin-top: 0;
    text-align: center;
    max-width: 5rem;
  }
  
  /* ... (other existing styles) ... */
  
  /* Responsive adjustments */
  @media (max-width: 1500px) {
    .pageWrapper {
      max-width: 95%;
    }
  
    .container1 {
      max-width: 90%;
    }
  
    .DEfields {
      justify-content: space-between;
    }
  
    .DEfieldSection {
      flex: 1 1 200px;
    }
  }
  
  @media (max-width: 1200px) {
    body {
      font-size: 15px;
    }
  
    .container1 {
      height: 20rem;
    }
  
    .formBtnDiv {
      justify-content: flex-end;
    }
  
    .formBtn, .formBtn2 {
      padding: 0.6rem 0.8rem;
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 1000px) {
    body {
      font-size: 14px;
    }
  
    .pageWrapper {
      padding: 1%;
    }
  
    .container1 {
      height: 18rem;
      max-width: 100%;
    }
  
 .orangeButton {
      max-width: 250px;
    }
  
    .DEfieldSection {
      flex: 1 1 150px;
    }
  
    .formBtnDiv {
      flex-direction: column;
      align-items: flex-end;
    }
  
    .formBtn, .formBtn2 {
      width: 100%;
      max-width: 200px;
      margin-top: 0.5rem;
    }
  }
  
  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  
    .container1 {
      height: 18.75rem;
    }
  
   .orangeButton {
      max-width: 100%;
    }
  
    .spanWidth1, .spanWidth2, .spanWidth3 {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  
    .DEfieldSection {
      flex: 1 1 100%;
    }
  
    .leftCol, .rightCol {
      flex: 1 1 100%;
    }
  }
  
  @media (max-width: 480px) {
    body {
      font-size: 12px;
    }
  
    .container1 {
      height: 15.625rem;
    }
  
    .summaryArea{
        padding-right: 5%;
    }
    .inpLabel {
      max-width: 100%;
    }
    .pageWrapper{
        height: 66%;
        padding-bottom: 7%;
        overflow-y: scroll;
    }

    .midCard{
        width:97%
    }  
     .bottomCard{
        width:95%
    }

    .pageWrapper2{
        overflow-y: scroll;
        padding-left: 0vw;
        width: 100vw !important;
        height: 82vh !important;
        display: flex !important;
        flex-wrap: wrap !important;
        max-width: 90vw !important;
        margin-left: 3vw !important;
      }

              
    .summaryItem{
        background-color: white;
        min-height:200px;
        margin-top:1%;
        margin-bottom:2%;
        border:1px solid black;
        border-radius: 8px 10px 0px 0px;
        margin-left:auto;
        margin-right: auto;
        width: auto;
    }
  }
  
  /* Additional responsive styles */
  .pageWrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 98%;
    max-width: 90vw;
    margin: 0 auto;
    padding: 2% 1%;
    margin-left: 7%;
  }
  
  .formBtnDiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2rem;
  }
  
  .formBtn, .formBtn2 {
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    font-size: 0.9375rem;
    cursor: pointer;
  }


  .dashboardWrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .notificationBody {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin-bottom: 1rem;
    display:flex;
    min-height: 10vh;

  }
  
  .midbuffer {
    padding: 1rem;
  }
  
  .notifMiddle {
    font-weight: bold;
    text-align: center;
    /* margin: auto; */
    margin-left: auto;
    margin-top: 2.5%;
    margin-right: auto;
  }
  
  .expanded {
    max-height: 1000px; /* Adjust as needed */
  }
  
  .taskHeader, .taskItem {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr auto;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 0;
  }
  
  .taskDetails {
    display: flex;
    flex-direction: column;
  }
  
  .spanWidth1, .spanWidth2, .spanWidth3 {
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .taskStatus {
    text-align: center;
  }
  
  .viewTaskBtn {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .iconBuffer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background-color: #f0f0f0;
    height: 107%;
  }
  
  .raise:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
  }
  
  @media (max-width: 768px) {
    .taskHeader, .taskItem {
      grid-template-columns: 1fr 1fr;
    }
  
    .taskStatus {
      grid-column: 1 / -1;
    }
  
    .viewTaskBtn {
      grid-column: 1 / -1;
    }
  }
  
  @media (max-width: 480px) {
    .taskHeader, .taskItem {
      grid-template-columns: 1fr;
    }
  
    .taskDetails, .taskStatus, .viewTaskBtn {
      grid-column: 1;
    }

    .notificationBody{
        transition: 0.3s ease-in-out;
        height: 10%;
        cursor: pointer;
        max-width: 93%;
        width: calc(100% - 2rem);
        margin: 0px 1rem 1rem;
        margin-left: 12%;
        overflow-y: scroll;
        padding-bottom:10%
    }

    .notifHolder{
        width: 93%;
        margin-left: 7%;
    }

    .spanWidth1{
        max-width: 25%;
    }
    .spanWidth2{
        max-width: 25%;
    }
    .spanWidth3{
        max-width: 25%;
    }
        
    .pageWrapper{
        height: 66%;
        padding-bottom: 7%;
        overflow-y: scroll;
    }
  }

  .taskHeader, .taskItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
  }

  .taskHeader {
    font-weight: bold;
  }

  .taskDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 60%;
  }

  .spanWidth1, .spanWidth2, .spanWidth3 {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }

  .taskStatus {
    width: 20%;
    text-align: center;
  }

  .taskActions {
    width: 20%;
    text-align: right;
  }

  @media (max-width: 768px) {
    .taskDetails {
      flex-wrap: wrap;
      width: 100%;
    }

    .spanWidth1, .spanWidth2, .spanWidth3 {
        flex-basis: 100%;
        padding-right: 0;
        min-width: 85px;
        margin-bottom: 2.7rem;

    }

    .taskStatus, .taskActions {
      width: 100%;
      text-align: left;
      margin-top: 0.5rem;
    }
  }
  .dateRange {
    display: none;
  }

  @media (min-width: 600px) {
    .dateRange {
      display: inline;
    }
  }

  .taskHeader {
    display: none;
  }
  .taskHeader {
    display: none;
  }

  .taskItem {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "form status"
      "form actions";
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
  }

  .taskDetails {
    grid-area: form;
  }

  .taskStatus {
    grid-area: status;
    text-align: right;
    width:100%;

  }

  .taskActions {
    grid-area: actions;
    text-align: right;
    width:100%;

  }

  .spanWidth2 {
    display: none;
  }

  .dateRange {
    display: none;
  }



  .raiseNoOutline {
    transition: 0.25s;
  }
  
  .raiseNoOutline:hover,
  .raise:hover {
    transform: translateY(-0.3em);
  }
  
  .raise {
    transition: 0.25s;
  }
  
  .raise:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Base styles */
.DEfields {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .groupDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .DEfieldSection {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  .defaultInp {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .orangeButton {
    width: auto;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  /* Responsive styles */
  @media (min-width: 768px) {
    .DEfields {
      flex-direction: row;
      flex-wrap: wrap;

      width:25vw
      
    }
  
    .groupDiv {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .DEfieldSection {
      width: auto;
      margin-right: 1rem;
    }
  }
  
  /* Specific field adjustments */
  .DEfieldSection[style*="width: 10%"] {
    width: 100% !important;
  }
  
  .DEfieldSection[style*="width: 45px"],
  .DEfieldSection[style*="width: 90px"],
  .DEfieldSection[style*="width: 240px"] {
    width: 100% !important;
    min-width: auto !important;
  }
  
  /* Responsive input and button styles */
  @media (max-width: 480px) {
    .orangeButton {
      font-size: 14px;
      padding: 0.5rem;
    }
  
    .orangeButton {
      min-width: 60px;
    }

        .otherFields{
            width:85vw;
        }
        .midbuffer{
          overflow: scroll;
        }
    .pageWrapper{
        height: 66%;
        padding-bottom: 7%;
        overflow-y: scroll;

 
    /* padding-left: 0vw; */
    width: 100vw;
    height: 82vh;
    display: flex;
    flex-wrap: wrap;
    max-width: 92vw;
    /* padding-right: 25vw; */
    margin-left: 13vw;
    padding: 0px;
    padding-bottom: 6vh;

    }

    .DEfieldSection{
        display:contents
    }

    .defaultInp{
        width:75px
    }
    .notificationBody{
        margin-left: 0%;
        overflow-y: scroll;
    }

      /* Expanded state styles */
  .notificationBody.expanded {
    height: 90%;
    padding-bottom:20vh;

  }

    .midbuffer{
        padding:0px
    }
  }
  
  
  /* Adjustments for very small screens */
  @media (max-width: 320px) {
    .orangeButton {
      font-size: 12px;
      padding: 0.4rem;
    }
    .midbuffer{
      overflow: scroll;
    }

    .orangeButton {
      min-width: 50px;
    }
    .notificationBody{
        margin-left: 0%;
    }
  }
  
  /* Utility classes */
  .w-full {
    width: 100%;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .overflow-x-auto {
    overflow-x: auto;
  }

  .DEfields {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-bottom: 3rem;

    width: 100%;
    flex-direction: row;
    width: 75vw;
    flex-wrap: nowrap;

  }
  
  .flexRow {
    flex-direction: row;
  }
  
  .alignItemsCenter {
    align-items: center;
  }
  
  .overflowXAuto {
    overflow-x: auto;
  }
  
  .displayFix{
    display:flex
  }

  .DEfieldSection {
    padding: 0.5rem;
    width:10vw !important;
  }
  
  .flexGrow1 {
    flex-grow: 1;
  }
  
  .minWidth0 {
    min-width: 0;
  }
  
  .flex {
    display: flex;
  }
  
  .flexWrap {
    flex-wrap: wrap;
  }
  
  .justifyEnd {
    justify-content: flex-end;
  }
  
  .mb2 {
    margin-bottom: 0.5rem;
  }
  
  .mr2 {
    margin-right: 0.5rem;
  }
  
  .textSm {
    font-size: 0.875rem;
  }
  
  .blockEllipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .responsiveButton {
    min-width: 60px;
    padding: 0.5rem;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 1vw;
    width:auto;
    max-width: max-content;
  }
  
  @media (max-width: 640px) {
    .DEfields {
        flex-direction: column;

          /* width: 94%; */
        border: 2px solid black;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 88% !important;
        border: 2px solid black;
        padding: 3%;
        border-radius: 13px;

    }
  
    .justifyEnd {
      justify-content: flex-start;
    }
  
    .responsiveButton {
      min-width: 50px;
      padding: 0.4rem;
      font-size: 12px;
    }
    .DEfields {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width:100vw
    }
}


  .groupDiv {
    display: flex;
    flex-wrap: wrap;
  }
  
  .DEfieldSection {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .itemSection,
  .parSection,
  .triggerSection,
  .responseSection,
  .orderSection,
  .customResponseSection {
    width: 100%;
  }
  
  .itemName,
  .triggerIcon,
  .responseAnswer {
    margin-top: 10px;
  }
  
  .defaultInp {
    width: 100%;
  }
  
  .submissionInfo {
 
    /* margin-left: 5%; */
    /* margin-top: 2%; */
    margin-bottom: 3%;

  }
  .mobileContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
  }
  @media screen and (min-width: 768px) {
    .groupDiv {
      flex-wrap: nowrap;
    }
  
    .itemSection,
    .parSection {
      width: 10%;
    }
  
    .triggerSection,
    .responseSection {
      width: 45px;
      min-width: auto;
    }
  
    .orderSection {
      width: 90px;
      min-width: 90px;
    }
  
    .customResponseSection {
      width: 240px;
      min-width: auto;
    }
  }
  @media (max-width: 767px) {
    .desktopOnly {
      display: none !important
    }
  }
  
  @media (min-width: 768px) {
    .mobileOnly {
      display: none !important
    }
  }


  .mobileFlexWrapper {
    display: flex;
    flex-direction: column;
    width: 86vw;
  }
  
  .mobileRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

  }
  
  .DEfieldSection {
    flex: 1 1 100%;
    margin-right: 10px;
  }
  
  @media (min-width: 768px) {
    .mobileOnly {
      display: none !important;
    }
  }

  /* iPad Air 2 Portrait Mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {


  .subPageHeaders{
    display:flex;
    margin-top: 1%;
    margin-bottom: 5vh;
}
.pageWrapper {
  padding-left: 10%;
  padding-bottom:10%;
  max-height: 85vh;
}

.flexBox {
  width: 90%;
}

.jobPositionSection{
    min-height: 10vh;
}
.inputWrapper {
  max-width: 40vw;
}

.selectWrapper {
  max-width: 40vw;
}

.bottomSection {
  grid-template-columns: 0.4fr 1fr;
}

.blackLineDiv {
  margin-left: 15%;
}
.jobPositionSection{
  max-height: 10vh;
}
.locationSection{
  max-height: 10vh;

}
.titleSection{
  margin-bottom:4%;
}
}

/* iPad Air 2 specific styles */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
  body {
    font-size: 14px;
  }
  .subPageHeaders{
    display:flex;
    margin-top: 1%;
    margin-bottom: 5vh;
}
  .titleSection{
    margin-bottom:4%;
  }
  .pageWrapper {
    max-width: 90%;
    margin: 0 auto;
    padding: 2% 1%;
    padding-left: 11vw;
  }
  
  .container1 {
    max-width: 100%;
    height: 18.75rem;
  }
  
  .formBtnDiv {
    justify-content: center;
  }
  
  .formBtn, .formBtn2 {
    padding: 0.6rem 0.8rem;
    font-size: 0.875rem;
  }
  
  .DEfields {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .DEfieldSection {
    flex: 1 1 40%;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  
  .orangeButton {
    max-width: 100%;
  }
  
  .spanWidth1, .spanWidth2, .spanWidth3 {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  
  .defaultInp {
    max-width: 100%;
  }
  
  .jobPositionSection, .locationSection {
    max-height: 15vh;
  }
  
  .blackLineDiv {
    display: none;
  }
  
  .availabilityBox {
    margin-top: 1%;
  }
  
  .bottomSection {
    grid-template-columns: 1fr;
  }
  
  .leftCol, .rightCol {
    flex: 1 1 100%;
  }
  
  .notificationBody {
    max-width: 100%;
    margin-left: 0;
    padding-bottom: 5%;
  }
  
  .midbuffer {
    overflow: auto;
  }
  
  .notificationBody.expanded {
    height: auto;
    padding-bottom: 10%;
  }
  
  .mobileContainer {
    flex-direction: column;
  }
  
  .mobileRow {
    flex-direction: column;
  }
  
  .DEfieldSection {
    margin-right: 0;
  }
  
  .mobileFlexWrapper {
    width: 100%;
  }



  .summaryItem{
    background-color: white;
    width: 85%;
    min-height: 200px;
    margin-top: 1%;
    margin-bottom: 2%;
    border: 1px solid black;
    border-radius: 8px 10px 0px 0px;
    margin-left: auto;
    margin-right: auto;
    margin-left: 5vw;
  }

  .summaryArea{
    overflow-x: hidden;
  }
 
  .pageWrapper{
    overflow-x: hidden;
  }
  .topCard{
    padding-left: 5%;
  }
}

/* iPad Air 2 Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
  .topCard{
    padding-left: 5%;
  }
  .pageWrapper{
    height: 90vh;
  }
  .titleSection{
    margin-bottom:4%;
  }
}
