/* Base styles */
body {}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.screen {
  position: relative;
  height: 425px;
  width: 555px;
  border: 2px solid black;
  border-radius: 12px;
  margin-top: -9%;
  background-color: white;
  padding-top: 1%;
  padding: 1%;
  z-index: 20000;
}

.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  position: absolute;
}

.login {
  width: 320px;
  padding: 30px;
  padding-top: 156px;
}

.login__field {
  padding: 20px 0px;
  position: relative;
}

.login__field label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.login__field label:hover .login__icon {
  color: #ff8f16;
}

.login__icon {
  position: absolute;
  top: 30px;
  color: #7875B5;
}

.login__input {
  border: none;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 75%;
  transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #ff8f16;
}

.login__submit {
  margin-left: 30%;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 20px;
  border: 1px solid #D4D3E8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 40%;
  color: #4C489D;
  cursor: pointer;
  transition: .2s;
  margin-left: -35%;
  background-color: white;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #ff8f16;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #ff8f16;
}

.social-login {
  position: absolute;
  height: 140px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fff;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-login__icon {
  padding: 20px 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
  transform: scale(1.5);
}

.popup-inner button {
  margin-top: 20px;
}

.popup-inner button[type="button"] {
  margin-top: 20px;
  background-color: #f44336;
}

.popup-inner button[type="button"]:hover {
  background-color: #da190b;
}

.popup-inner button:disabled {
  background-color: #bfbfbf;
  color: #ffffff;
  cursor: not-allowed;
}

.popup-inner button[type="button"]:disabled {
  background-color: #bfbfbf;
  color: #ffffff;
  cursor: not-allowed;
}

.mainHeader,
.mainHeader2 {
  background-color: #f5f5f5;
  width: 100%;
  margin-left: 0%;
  border-bottom: 2px solid grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  height: 60px;
  overflow: visible;
}

.toolbarSection {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  position: relative;
  height: 100%;
}

.profileButton,
.profileButtonNoAnim {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 25px 25px 25px 25px;
  padding: 0.5rem 1rem;
  box-shadow: 0 -2px 6px 1px rgba(60, 64, 67, 0.15),
    2px 0 6px 1px rgba(60, 64, 67, 0.15),
    -2px 0 6px 1px rgba(60, 64, 67, 0.15);
  transition: all 0.3s ease-in-out, border-radius 0s;
  cursor: pointer;
  height: 30px;
  width: 225px;
  margin-right: 4%;
  position: relative;
  z-index: 2;
  overflow: visible;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 1000;
}

.profileButton:hover {
  border-radius: 25px 25px 0px 0px;
  transition: all 0.3s ease-in-out, border-radius 0s;
}

.profileButton::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  height: 0;
  transition: height 0.3s ease-in-out 0.1s;
  z-index: -1;
  border-radius: 0 0 25px 25px;
  box-shadow: 2px 2px 5px 1px rgba(60, 64, 67, 0.15), -2px 2px 6px 1px rgba(60, 64, 67, 0.15);
  opacity: 0;
  clip-path: inset(0px -10px -10px -10px);
}

.profileButton:hover::after {
  height: 220px;
  margin-top: 0%;
  border: 0px solid white;
  clip-path: inset(0px -10px -10px -10px);
  opacity: 1;
}

.usernameSpan,
.usernameSpan2 {
  position: relative;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.blackLineDiv {
  height: 1px;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.1s;
  margin: 10px -6rem 0;
  position: relative;
  z-index: 3;
  width: calc(100% + 12rem);
  transition-delay: 0.1ms;
}

.profileButton:hover .blackLineDiv {
  opacity: 1;
  transition-delay: 0.2s;
}

.pageOption {
  text-align: left;
  padding-left: 5%;
  opacity: 0;
  transform: translateY(-20px);
  position: relative;
  transition: opacity 0s, transform 0.3s ease-in-out;
  z-index: 3;
}

.profileButton:hover .pageOption {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
  transition: opacity 0s 0.3s, transform 0.3s ease-in-out 0.3s;
}

/* Responsive styles */
@media screen and (min-width: 768px) {
  .toolbarSection {
    width: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1024px) {
  .toolbarSection {
    margin-left: 90%;
  }

  .mainHeader {
    width: 99%;
    margin-left: 1%;
  }
}

@media screen and (max-width: 1024px) {
  .mainHeader {
    width: 95.8%;
    margin-left: 4.3%;
  }
}

@media screen and (max-width: 767px) {
  .hideFromMobile {
    display: none;
  }

  .toolbarSection {
    margin-left: 94%;
  }

  .mainHeader {
    height: 7%;
  }

  .profileButton,
  .profileButtonNoAnim {
    margin-right: 5vw !important;
    width: 125px;
    margin-right: 0;
  }

  .usernameSpan,
  .usernameSpan2 {
    justify-content: flex-start;
  }

  .goodMorning {
    width: 250px;
    font-size: 23px;
  }

  .blackLineDiv {
    width: calc(100% + 7rem);
    margin-left: -128%;
  }
}

@media screen and (max-width: 480px) {
  .screen {
    width: 80%;
    margin-top: -50%;
    padding: 3%;
    margin-left: -6vw;
    z-index: 20000;
  }

  .login {
    padding-top: 50px;
  }

  .login__input {
    width: 75%;
  }

  .login__submit {
    margin-top: 23%;
    margin-left: 11%;
    width: 80%;
  }

  .login__field label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
  }

  .login__field label span {
    margin-bottom: 5px;
    color: #7875B5;
    font-weight: bold;
  }

  .login__field label:hover span {
    color: #ff8f16;
  }
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .login__submit {
    margin-left: 28%;
    margin-top: 5vh;
    font-size: 1.2em;
  }

  .mainHeader {
    height: 8vh;
  }

  .screen {
    position: relative;
    height: 71vh;
    width: 72vw;
    border: 2px solid black;
    border-radius: 12px;
    /* margin-top: -31%; */
    background-color: white;
    padding-top: 1%;
    padding: 1%;
  }

  .login__field {
    padding: 20px 0px; 
   }

  .login__input {
    font-size: 1.2em;
  }

  .needToReg {
    margin-top: 5vh;
    font-size: 1.2em;
  }
}

@media (min-aspect-ratio: 4/3) {
  .login__submit {
    margin-left: 30%;
    font-size: 14px;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 20px;
    border: 1px solid #D4D3E8;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 40%;
    color: #4C489D;
    cursor: pointer;
    transition: .2s;
    margin-left: 5vw;
    background-color: white;
  }
}

@media screen and (max-width: 375px) {
  .blackLineDiv {
    margin-left: -149%;
    margin-top: 59%;
  }
}

@media screen and (max-height: 375px) {
  .blackLineDiv {
    margin-left: -149%;
    margin-top: 59%;
  }
  .screen{
    position: relative;
    height: 71vh;
    width: 72vw;
    border: 2px solid black;
    border-radius: 12px;
    /* margin-top: -31%; */
    background-color: white;
    padding-top: 1%;
    padding: 1%;
  }
}