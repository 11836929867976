:root {
    --main-width: 80vw;
    --spacing-unit: 1rem;
    --input-width: 200px;  /* or whatever width your inputs currently have */
    --transition-speed: 0.3s;
}

.pageWrapper {
    margin-left: 2%;
    /* max-width: var(--main-width); */
    width: 92vw;
    max-height: 93vh;
    min-height: 60vh;
    overflow: hidden;
    padding-left: 9%;
    transition: all var(--transition-speed) ease;
}

.popupMessage{
    margin-top: 5vh;
}
.pageWrapperScroll {
    overflow-y: auto;
}

.userForm {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-unit);
    transition: padding var(--transition-speed) ease;
}

.existingUser {
    font-size: 1.5rem;
    color: #333;
    margin: 0 0 var(--spacing-unit) 0;
}

.selectandbuttonwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.selectWrapper {
    flex-grow: 1;
    max-width: 300px;
    width: 294px;
}

.userSelect, .jobSelect, .locationSelect {
    width: 100%;
}

.userInfoSection {
    margin-bottom: var(--spacing-unit);
}

.contactInfo {
    font-size: 1.125rem;
    margin-bottom: var(--spacing-unit);
}

.flexBox {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.leftCol, .rightCol {
    flex: 1 1 100%;  /* Each column takes full width of the flexbox */
    min-width: 250px;
}

.field {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-unit);
    width: 100%;  /* Ensure the field takes full width of its container */
}

.inpLabel {
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.defaultInp {
    width: 83%;  /* Keep the current input width */
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.blackLineDiv {
    height: 1px;
    background-color: #ccc;
    margin: 2rem 0;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.bottomSection {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit);
    transition: all var(--transition-speed) ease;
}

.jobPositionSection, .locationSection {
    margin-bottom: var(--spacing-unit);
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
}
.vendorSelect {
    z-index: 5;
    width: 100%;  /* This will make it take the full width of its container */
}

.jobPositionText, .locationText, .availabilityText {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.availabilityBox {
    width: 100%;
    grid-column: 1 / -1;
}

.rowOfBoxes {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.availabilityDay {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
}

.formBtnDiv {
    grid-column: 1 / -1;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: var(--spacing-unit);
    transition: all var(--transition-speed) ease;
}

.formBtn, .formBtn2, .formBtn3 {
    padding: 0.75rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9375rem;
    text-align: center;
    line-height: 1;
    min-width: 135px;
    height: 42px;
}

.formBtn {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
}

.formBtn2, .formBtn3 {
    background-color: #f97316;
    color: white;
    border: none;
}
.reportingGroupSection {
    width: 84%;
    position: relative;
    z-index: 1;
}

.reportingGroupText {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.fakeSelect {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    text-align: left;
    cursor: pointer;
}

.popupBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popupMessage {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  
  .popupMessage h3 {
    margin-top: 0;
  }
  
  .popupMessage p {
    margin-bottom: 20px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  
  .modalButtons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .modalButtons button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* For single-button popups, we can style it differently than the dual-button modals */
  .modalButtons button:only-child {
    background-color: #007bff;  /* Use your preferred primary color */
    color: white;
    border: none;
  }
  
  /* Keep your existing styles for two-button scenarios */
  .modalButtons button:first-child {
    background-color: #f1f1f1;
    color: #333;
    border: none;
  }
  
  .modalButtons button:last-child {
    background-color: #f44336;
    color: white;
    border: none;
  }
.outsidePopup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 2;
}

.optionArea {
    padding: 0.5rem;
}

.subCategory {
    margin-left: 1.25rem;
    cursor: pointer;
}

.selectedArea {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.catagoryItem {
    border: 1px solid orange;
    color: orange;
    padding: 0.3125rem 0.625rem;
    border-radius: 20px;
    cursor: pointer;
}
.field .selectWrapper {
    width: var(--input-width);  /* Match the width of other inputs */
}
@media (max-width: 1200px) {
    :root {
        --main-width: 90vw;
    }
    .flexBox {
        width: 45%;  /* Full width on smaller screens */
    }
    .bottomSection {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .selectandbuttonwrapper {
        flex-direction: column;
        align-items: stretch;
    }

    .selectWrapper {
        max-width: none;
        margin-bottom: var(--spacing-unit);
    }

    .createUserWrapper {
        margin-left: 0;
    }

    .flexBox {
        flex-direction: column;
    }

    .leftCol, .rightCol {
        flex: 1 1 100%;
    }

    .formBtnDiv {
        flex-direction: column;
    }

    .formBtn, .formBtn2, .formBtn3 {
        width: 100%;
    }
}

@media (max-width: 480px) {
    :root {
        --spacing-unit: 0.75rem;
    }

    .existingUser {
        font-size: 1.25rem;
    }

    .contactInfo, .jobPositionText, .locationText, .availabilityText, .reportingGroupText {
        font-size: 1rem;
    }

    .availabilityDay {
        width: 2rem;
        height: 2rem;
    }
}


:root {
    --main-width: 80vw;
    --spacing-unit: 1rem;
    --input-width: 50%; /* New variable for input width */
    --select-width: 300px; /* New variable for select width */
}

.pageWrapper {
    margin-left: 2%;
    max-width: var(--main-width);
    max-height: 93vh;
    min-height: 60vh;
    max-width: 89vw;
    overflow-x: hidden;
}

.pageWrapperScroll {
    overflow-y: auto;
}

.userForm {
    display: flex;
    flex-direction: column;
    padding-right: 10%;
    margin-bottom: var(--spacing-unit);
}

.existingUser {
    font-size: 1.5rem;
    color: #333;
    margin: 0 0 var(--spacing-unit) 0;
}

.selectandbuttonwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.selectWrapper {
    width: var(--select-width);
}

.userSelect, .jobSelect, .locationSelect {
    width: 100%;
}

.userInfoSection {
    margin-bottom: var(--spacing-unit);
}

.contactInfo {
    font-size: 1.125rem;
    margin-bottom: var(--spacing-unit);
}

.flexBox {
    display: flex;
    width: 45%;
    gap: var(--spacing-unit);
    flex-wrap: wrap;
}

.leftCol, .rightCol {
    flex: 1 1 calc(50% - var(--spacing-unit));
    min-width: 250px;
}

.field {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-unit);
}

.inpLabel {
    margin-bottom: 0.5rem;
    font-weight: 500;
}



/* ... Rest of the CSS remains the same ... */

@media (max-width: 1200px) {
    :root {
        --main-width: 90vw;
    }

    .bottomSection {
        grid-template-columns: 1fr;
    }
}


  @media screen and (max-width: 1199px) and (min-width: 768px) {
    .optionArea{
        text-align: left;
    }

    .subCategory{
        margin-top: .5vh;
        margin-left: 2.5rem;
    }

    .userForm{
        padding-right: 3vw;
    }

    .pageWrapper{
        height:100vh;
        max-height: 100vh;
        
      }
      .formBtnDiv {
        grid-column: 1 / -1;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        transition: margin-top 0.3s ease; /* Add smooth transition */
        margin-right: 3vw;
    }
  }

@media (max-width: 768px) {
    .selectandbuttonwrapper {
        flex-direction: column;
        align-items: stretch;
    }


    .selectWrapper {
        width: 100%;
        max-width: var(--select-width);
        margin-bottom: var(--spacing-unit);
    }

    .createUserWrapper {
        margin-left: 0;
    }

    .flexBox {
        flex-direction: column;
    }

    .leftCol, .rightCol {
        flex: 1 1 100%;
    }

    .formBtnDiv {
        grid-column: 1 / -1;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        transition: margin-top 0.3s ease; /* Add smooth transition */
        margin-right: 12%;

        margin-right:10vw;
    }

    .formBtn, .formBtn2, .formBtn3 {
        width: 100%;
    }

    .defaultInp {
        width: var(--input-width);
    }
}

@media (max-width: 480px) {
    :root {
        --spacing-unit: 0.75rem;
    }

    .existingUser {
        font-size: 1.25rem;
    }

    .contactInfo, .jobPositionText, .locationText, .availabilityText, .reportingGroupText {
        font-size: 1rem;
    }

    .availabilityDay {
        width: 2rem;
        height: 2rem;
    }

    .defaultInp {
        width: var(--input-width);
    }
}


/* Small tablets (portrait) */
@media screen and (max-height: 600px) {
    .formBtnDiv {
        margin-top: 5vh !important;
    }
    .blackLineDiv{
        margin: 1rem;
    }  
      .flexBox{
        width: 88vw;
        display: flex;
        flex-direction: row;
    }
    .leftCol{
        flex: 0;
    }
    .rightCol{
        flex:0;
        
    }
  }
  
  /* Medium tablets (portrait) */
  @media screen and (min-height: 601px) and (max-height: 800px) {
    .formBtnDiv {
        margin-top: 5vh !important;
    }
    .blackLineDiv{
        margin: 1rem;
    }
    .flexBox{
        width: 88vw;
        display: flex;
        flex-direction: row;
    }
    .leftCol{
        flex: 0;
    }
    .rightCol{
        flex:0;

    }
  }
  
  /* Large tablets (portrait) */
  @media screen and (min-height: 801px) and (max-height: 1024px) {
    .formBtnDiv {
      margin-top: 5vh !important;
    }
    .blackLineDiv{
        margin: 1rem;
    }
    .flexBox{
        width: 88vw;
        display: flex;
        flex-direction: row;
    }
    .leftCol{
        flex: 0;
    }
    .rightCol{
        flex:0;
        
    }
  }  



  @media screen and (min-height: 1230px) and (max-height: 1374px) {
    .pageWrapper {
      font-size: 1rem;
    }
  
    .formBtnDiv {
      margin-top: 5vh !important;
    }
  
    .blackLineDiv {
      margin: 1rem;
    }
  
    .flexBox {
      width: 88vw;
      display: flex;
      flex-direction: row;
    }
  
    .leftCol, .rightCol {
      flex: 1 1 50%; /* Allow columns to grow and take up equal space */
    }
  
    .defaultInp {
      width: 80%;
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
    }
  
    body {
      font-size: 1.125rem;
    }
  
    h1 {
      font-size: 1.75rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.25rem;
    }
  
    h4 {
      font-size: 1.125rem;
    }
  
    button {
      font-size: 1.125rem;
    }
  
    .small-text {
      font-size: 0.875rem;
    }


    .field{
        margin-bottom: 2.6vh;
    }

    .outsidePopup{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: white;
        max-height: 12vh;
        overflow-y: auto;
        z-index: 2;
    }

}


@media screen and (min-height: 1024px) and (max-height: 1230px) {
    .field{
        margin-bottom: 2.3vh;
    }
}

@media (min-aspect-ratio: 4/3) {


    .pageWrapper{

        margin-left: 2%;
        max-width: var(--main-width);
        max-height: 97vh;
        min-height: 60vh;
        max-width: 89vw;
        overflow-x: hidden;
        height: 93vh;
    }


    .formBtnDiv{
        margin-right: 10vw;
    }

    .vendorSelect{
        max-width: 16.3vw;
    }

    .blackLineDiv{
        left:47%;
    }



}@media (max-width: 1024px) {
    .flexBox {
        flex-direction: column;
    }
    
    .leftCol, .rightCol {
        width: 100%;
    }
}


/* iPad Pro Vertical (1024x1366) */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .flexBox {
        width: 90%;
    }

    .vendorSelect{
        min-width: 16.7vw;
    }

    .selectWrapper{
        max-width: 26vw;
    }


    .locationSection{
        margin-left: 7vw;
    }

    .jobPositionSection{
        margin-right: 8.9vw;
    }


    .inpLabel{
        text-align:left;
    }
    .defaultInp {
        font-size: 16px;
    }

    .jobPositionSection {
        width: 100%;
        margin-right: 0;
    }

    .inputWrapper {
        max-width: 26vw;
    }

    .blackLineDiv {
        width: 95vw;
        margin-left: 5%;
    }
}









/* iPad Air 2 Portrait Mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {

    .pageWrapper {
        padding-left: 10%;
        padding-bottom: 10%;
        max-height: 85vh;
    }

}

/* iPad Air 2 Landscape Mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
    .pageWrapper {
        padding-left: 10%;
        padding-bottom: 10%;
        max-height: 85vh;
    }
}