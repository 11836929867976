body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:hidden;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 900px) {

body { 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:hidden;
  width: 100%;
  height: 100%;
  padding:0;
  margin:0;
}
html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding:0;
  margin:0;
}
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{

  --offWhite: '#f5f5f5';
  --orangeText: '#fd8e15';
  --appBackground: '#d0d1d3';

}      

