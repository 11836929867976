.reportingGroupForm {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 14.3%;
    left: 21.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.editArea {
    height: 310px;
    overflow-y: scroll;
}
.monthSection{
    display: inline-block;
    /* border: 2px solid black; */
    height: auto;
    width: auto;
    margin-left: 15%;
    margin-top: -1%;
    z-index: 100000;
}

.taskInput {

    background-color: rgb(252, 239, 223);
    width: 193px;
    height: 48px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(189, 189, 189);
    border-radius: 3px;
    font-family: var(--font_default);
    font-size: 14px;
    font-weight: 400;
    color: rgb(61, 61, 61);
    opacity: 1;
}

.deleteBtn{
    height:47px;
    width:53px;

}

.addBtn {
    margin-left: 20%;
    margin-right: 3%;
    height: 47px;
    width: 53px;
}

.inputSection {

    width: 98%;
}


.blackLineDiv2 {
    color: black;
    height: 1px;
    background-color: black;
    margin-bottom: 1%;
    width: 104.1%;
    margin-top: 1.5%;
    left: -2%;
    position: relative;
}

.taskName {
    margin-bottom:2%
}


.popupBackground {
    width: 2000px;
    height: 100%;
    background-color: whitesmoke;
    margin-top: -4%;
    position: absolute;
    /* margin-left: -3%; */
    opacity: .8;
    visibility: hidden;
    z-index: 200000000;

}

.popupBackground2 {
    width: 2000px;
    height: 100%;
    background-color: whitesmoke;
    margin-top: -4%;
    position: absolute;
    /* margin-left: -3%; */
    opacity: .8;
    visibility: hidden;
    z-index: 200000000;

}

.closeButton{
    float: right;
    width: 30px;
    height: 30px;
    background-color: #ff0000ab;
    margin-bottom: -30%;
    border: 2px solid black;
    margin: 0%;
    padding: 0%;
    border-top: 0px;
    border-right: 0px;
}

.popupBackground3 {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    margin-top: -6%;
    position: absolute;
    /* margin-left: -3%; */
    opacity: .8;
    visibility: hidden;
    z-index: 1;

}

.pu3Box {
    width: 30%;
   /* border: 1px solid grey;*/
    padding: .5%;
    height: 300px;
    overflow-x: overlay;
    width: 98%;
    height: 99%;
    max-height: 520px;
    overflow-y: visible;
    overflow:  visible;

}

.popupMessage3 {
    z-index: 200000000000;

    z-index: 2;
    border: 2px solid black;
    width: 1300px;
    height: 750px;
    background-color: white;
    text-align: center;
    position: relative;
    left: 24vw;
    top: 8vh;
    position: absolute;
    visibility: visible;
    z-index:100;
    display:none;
}

.popupMessage2 {
    z-index: 200000000000;

    border: 2px solid black;
    width: 1000px;
    height: 650px;
    background-color: white;
    text-align: center;
    position: relative;
    left: 30vw;
    top: 6vh;
    position: absolute;
    visibility: visible;
    overflow-y: overlay;
    overflow-x: hidden;
    display: none;
}

.pageWrapper {
    text-align: left;
    display: block;
    position: relative;
    left: 0%;
    top: 0%;
    height: 98%;
    width: 90%;
    left: 0%;
    padding-left: 10%;
}

.flexBox {
    display: flex;
}

.addToGroups {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 16.3%;
    left: 22.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.inpLabel {
    width: 215px;
}

.defaultInp {
    margin-left: 2%;
    height: 30px;
    width: 265px;
    border-radius: 5px;
    background-color: #fceddf;
    border: 2px solid #8080805c;
}

.existingUser {
    margin-left: 1%;
}

.inlineBox {
    display: inline;
}


.createCatagoryTextBtn {
    margin-left: 22%;
    margin-right: 30%;
}


.destinationTypeSelect{
    margin-left: 6.5%;
    width: 280px;
}


.frequencySelect{
    margin-left: 7%;
    width: 280px;
}


.jobSelect {
    width: 30%;
    margin-left: 10%;
}

.userSelect {
    position: relative;
    box-sizing: border-box;
    padding-top: .7%;
    border-radius: 5px;
    margin-left: 11%;


    width: 193px;
    height: 48px;
    font-family: var(--font_default);
    font-size: 14px;
    font-weight: 400;
    color: rgb(61, 61, 61);
    opacity: 1;


}


.field {
    margin-top: 5%;
    margin-left: 1%;

    width: 100%;
    display: inline-flex;


}


.contactInfo {
    margin-left: 1%;
}


.leftCol {
    width: 40%;
    float: 0;
    text-align: left;
}

.rightCol {
    width: 60%;
    text-align: left;
    float: 1;
}



.availabilityDay {
    width: 36px;
    height: 20px;
    background-color: white;
    border: 2px solid black;
    padding: 1.1%;
    border-radius: 5px;
    margin-left: 3%;
    margin-top: 2%;
}

.rowOfBoxes {
    display: flex;
}

.availabilityBox {
    display: inline-block;

}

.inputArea {
    display: flex;
    margin-top: 1.5%;
}

.sectionSelect {
    margin-left: 10%;
}

.bottomSection {
    height: 309px;
    padding-top: 0%;
    z-index: 0;
    overflow-y: scroll;
}

.selectGrouper {
    width: 57%;
    text-align: left;
    margin-left: 2%
}

.locationText {
    margin-top: 3%;

}

.formBtnDiv {
    width: 38%;
    margin-left:105%;
}


.formBtn {
    background-color: rgb(255, 255, 255);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_text_default);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(47, 47, 47);
    border-radius: 1px;
    padding: 12px 16px;
    opacity: 1;
    margin-right: 5%;
    cursor: pointer;
    top: 857px;
    left: 1064px;
    width: 135px;
    height: 38px;
    z-index: 6;
}


.formBtn2 {
    background-color: rgb(252, 143, 20);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_primary_contrast_default);
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    top: 857px;
    left: 1373px;
    width: 135px;
    height: 38px;
    z-index: 4;
}


.reportingGroupForm {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 14.3%;
    left: 21.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.otherFields2 {
    margin-left: 8%;
    display: flex;
    align-items: baseline;
    width: 100%;

    overflow-x: overlay;
}

.monthSection{
    display: inline-block;
    /* border: 2px solid black; */
    height: auto;
    width: auto;
    margin-left: 15%;
    margin-top: -1%;
    z-index: 100000;

}

.bottomButtons {
    position: absolute;
    left: 50%;
    top: 76%;
    width: 700px;
}

.itemSelect {
    position: relative;
    box-sizing: border-box;
    width: 90%;
    padding: 0%;
    margin-top: .5%;
    margin-left: 1%;
    height: 30px;
    border-radius: 5px;


}

.saveBtn {
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    background-color: rgb(252, 143, 20);
    font-size: 15px;
    font-weight: 600;
    color: white;
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    align-self: flex-start;
    min-width: 135px;
    max-width: 135px;
    order: 9;
    min-height: 47px;
    width: 135px;
    flex-grow: 1;
    height: max-content;

    margin-left: 5%
}

.cancelbtn {
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(163, 163, 163);
    border-radius: 3px;
    opacity: 1;
    cursor: pointer;
    align-self: flex-start;
    min-width: 135px;
    max-width: 135px;
    order: 8;
    min-height: 47px;
    width: 135px;
    flex-grow: 1;
    height: max-content;
}

.sectionDiv {
    margin-top: 1%;
    margin-bottom: 1%;
    padding-bottom: 2%;
    border-bottom: 2px solid grey;
}

.includeParInput {
    height: 22px;
    border: 2px solid #cccccc;
    padding: 3%;
}
.closeButton{
    float: right;
    width: 30px;
    height: 30px;
    background-color: #ff0000ab;
    margin-bottom: -30%;
    border: 2px solid black;
    margin: 0%;
    padding: 0%;
    border-top: 0px;
    border-right: 0px;
}
.visualfieldSection {
    display: grid;
    margin-left: 5%;
    width: 170px;
}

.ChecklistfieldSection {
    display: grid;
    margin-left: 5%;
}

.DEfields {
    display: flex;
    padding-left: 4%;
    min-height: 100px;

    padding-top: 2%;
}

.DEfieldSection {
    width: 385px;

}

.ResfieldSection {
    display: grid;
    margin-left: 5%;
    width: 300px;
}

.checkboxes label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
}

.checkboxes input {
    vertical-align: middle;
}

.checkboxes label span {
    vertical-align: middle;
}

.addToGroups {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 16.3%;
    left: 22.5%;
    position: absolute;
    text-align: left;
    width: 69.7%;
    padding: 0.5%;
}

.inpLabel {
    width: 215px;
}

.defaultInp {
    margin-top: 1%;
    margin-left: 0%;
    height: 44px;
    width: 300px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid #8080805c;
    padding: 1%;
}

.existingUser {
    margin-left: 1%;
}

.inlineBox {
    display: inline;
}


.createCatagoryTextBtn {
    margin-left: 22%;
    margin-right: 30%;
}




.userSelect {
    position: relative;
    box-sizing: border-box;
    padding: 0%;
    margin-top: .5%;
    margin-bottom: 2%;
    height: 30px;
    border-radius: 5px;
    width: 200px;
    margin-left: 0%;

}


.field {
    margin-top: 5%;
    margin-left: 1%;

    width: 100%;
    display: inline-flex;


}


.contactInfo {
    margin-left: 1%;
}


.leftCol {
    width: 40%;
    float: 0;
    text-align: left;
}

.rightCol {
    width: 60%;
    text-align: left;
    float: 1;
}



.availabilityDay {
    width: 36px;
    height: 20px;
    background-color: white;
    border: 2px solid black;
    padding: 1.1%;
    border-radius: 5px;
    margin-left: 3%;
    margin-top: 2%;
}

.rowOfBoxes {
    display: flex;
}

.availabilityBox {
    display: inline-block;

}

.inputArea {
    display: flex;
    margin-top: 1.5%;
}

.sectionSelect {
    margin-left: 10%;
}

.bottomSection {
    height: 309px;
    padding-top: 0%;
    z-index: 0;
    overflow-y: scroll;
}

.selectGrouper {
    width: 57%;
    text-align: left;
    margin-left: 2%
}

.locationText {
    margin-top: 3%;

}

.formBtnDiv {
    position: absolute;
    left: 60%;
    top: 87%;
    width: 38%;
}


.formBtn {
    background-color: rgb(255, 255, 255);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_text_default);
    text-align: center;
    line-height: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(47, 47, 47);
    border-radius: 1px;
    padding: 12px 16px;
    opacity: 1;
    margin-right: 5%;
    cursor: pointer;
    top: 857px;
    left: 1064px;
    width: 135px;
    height: 38px;
    z-index: 6;
}


.formBtn2 {
    background-color: rgb(252, 143, 20);
    font-family: var(--font_default);
    font-size: 15px;
    font-weight: 600;
    color: var(--color_primary_contrast_default);
    text-align: center;
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer;
    top: 857px;
    left: 1373px;
    width: 135px;
    height: 38px;
    z-index: 4;
}






@media (min-aspect-ratio: 16/9) {
    .taskName {
        margin-bottom:2%
    }
    .reportingGroupForm {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        top: 14.3%;
        left: 21.5%;
        position: absolute;
        text-align: left;
        width: 69.7%;
        padding: 0.5%;
    }

    .pageWrapper {
        padding-top: 1%;
        text-align: left;
        display: block;
        position: relative;
        left: 0%;
        top: 0%;
        height: 98%;
        width: 100%;
        left: 0%;
        padding-left: 1%;
    }
    }

    .bottomButtons {
        position: absolute;
        left: 62%;
        top: 86%;
        width: 700px;
    }

    .itemSelect {
        position: relative;
        box-sizing: border-box;
        width: 90%;
        padding: 0%;
        margin-top: .5%;
        margin-left: 1%;
        height: 30px;
        border-radius: 5px;
    }


    .weekSection{
     margin-bottom: 3%;
     width: 284px;
     text-align: left;
     margin-left: 2%;
    }

    .reportArea{
        padding-left: 0%;
        display: flex;
        /* flex-wrap: wrap; */
        /* align-content: space-evenly; */
        justify-content: space-between;
        display: flex;
        width: 107%;
    }


    .saveBtn {
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        background-color: rgb(252, 143, 20);
        font-size: 15px;
        font-weight: 600;
        color: white;
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        align-self: flex-start;
        min-width: 135px;
        max-width: 135px;
        order: 9;
        min-height: 47px;
        width: 135px;
        flex-grow: 1;
        height: max-content;

        margin-left: 5%
    }

    .cancelbtn {
        background-color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 400;
        color: rgb(0, 0, 0);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(163, 163, 163);
        border-radius: 3px;
        opacity: 1;
        cursor: pointer;
        align-self: flex-start;
        min-width: 135px;
        max-width: 135px;
        order: 8;
        min-height: 47px;
        width: 135px;
        flex-grow: 1;
        height: max-content;
        margin-left: 2%;
    }

    .sectionDiv {
        margin-top: 1%;
        margin-bottom: 1%;
        padding-bottom: 2%;
        border-bottom: 2px solid grey;
        width: 98%
    }

    .includeParInput {
        height: 22px;
        border: 2px solid #cccccc;
        padding: 3%;
    }

    .visualfieldSection {
        display: grid;
        margin-left: 5%;
        width: 170px;
    }

    .ChecklistfieldSection {
        display: grid;
        margin-left: 5%;
    }

    .DEfields {
        display: flex;
        padding-left: 4%;
        padding-top: 2%;
        padding-right: 2%;
        min-height: 100px;

    }

    .DEfieldSection {
        width: 385px;
        min-width: 150px
    }

    .ResfieldSection {
        display: grid;
        margin-left: 5%;
        width: 300px;
    }

    .checkboxes label {
        display: inline-block;
        padding-right: 10px;
        white-space: nowrap;
    }

    .checkboxes input {
        vertical-align: middle;
    }

    .checkboxes label span {
        vertical-align: middle;
    }

    .addToGroups {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        top: 16.3%;
        left: 22.5%;
        position: absolute;
        text-align: left;
        width: 69.7%;
        padding: 0.5%;
    }

    .inpLabel {
        width: 215px;
    }

    .defaultInp {
        margin-top: 1%;
        margin-left: 0%;
        height: 44px;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
    }

    .existingUser {
        margin-left: 1%;
    }

    .inlineBox {
        display: inline;
    }


    .createCatagoryTextBtn {
        margin-left: 22%;
        margin-right: 30%;
    }




    .userSelect {
        position: relative;
        box-sizing: border-box;
        padding: 0%;
        margin-top: .5%;
        margin-bottom: 2%;
        height: 30px;
        border-radius: 5px;
        width: 200px;
        margin-left: 0%;
    }


    .field {
        margin-top: 5%;
        margin-left: 1%;

        width: 100%;
        display: inline-flex;


    }


    .contactInfo {
        margin-left: 1%;
    }


    .leftCol {
        width: 40%;
        float: 0;
        text-align: left;
    }

    .rightCol {
        width: 60%;
        text-align: left;
        float: 1;
    }



    .availabilityDay {
        width: 36px;
        height: 20px;
        background-color: white;
        border: 2px solid black;
        padding: 1.1%;
        border-radius: 5px;
        margin-left: 3%;
        margin-top: 2%;
    }

    .rowOfBoxes {
        display: flex;
    }

    .availabilityBox {
        display: inline-block;

    }

    .inputArea {
        display: flex;
        margin-top: 1.5%;
    }

    .sectionSelect {
        margin-left: 10%;
    }

    .bottomSection {
        height: 309px;
        padding-top: 0%;
        z-index: 0;
        overflow-y: scroll;
    }

    .selectGrouper {
        width: 57%;
        text-align: left;
        margin-left: 2%
    }

    .locationText {
        margin-top: 3%;

    }

    .formBtnDiv {
        position: absolute;
        left: 60%;
        top: 87%;
        width: 38%;
    }


    .formBtn {
        background-color: rgb(255, 255, 255);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_text_default);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        border-radius: 1px;
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 857px;
        left: 1064px;
        width: 135px;
        height: 38px;
        z-index: 6;
    }


    .formBtn2 {
        background-color: rgb(252, 143, 20);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_primary_contrast_default);
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        top: 857px;
        left: 1373px;
        width: 135px;
        height: 38px;
        z-index: 4;
    }


    .addRowBtn{
        background-color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 0px;
        left: 0px;
        height: 40px;
        width: 130px;
        border-radius: 10px;
        z-index: 6;
        min-width: 120px;
        margin-left: 8%;
        margin-top: .5%
    }

    .userForm {
        position: relative;
        text-align: left;
        padding: 0.5%;
    }

    .inpLabel {
        width: 126px;
    }


    .dynamicFormSection {
        display: flex;
    }

    .container1 {
        border: 1px solid black;
        padding: 0%;
        width: 275px;
        height: 350px;
        border-radius: 6px;
        overflow-y: scroll;
        padding-left: 3%;
        background-color: white;
    }

    .optionHeader {
        text-align: left;
        padding: 2%;
        border-bottom: 1px solid black;
        margin-left: -3%;
    }

    .popupBackground {
        width: 2000px;
        height: 100%;
        background-color: whitesmoke;
        margin-top: -4%;
        position: absolute;
        /* margin-left: -3%; */
        opacity: .8;
        visibility: hidden;
        z-index: 200000000;

    }

    .popupBackground2 {
        width: 2000px;
        height: 100%;
        background-color: whitesmoke;
        margin-top: -4%;
        position: absolute;
        /* margin-left: -3%; */
        opacity: .8;
        visibility: hidden;
        z-index: 200000000;

    }

    .closeButton{
        float: right;
        width: 30px;
        height: 30px;
        background-color: #ff0000ab;
        margin-bottom: -30%;
        border: 2px solid black;
        margin: 0%;
        padding: 0%;
        border-top: 0px;
        border-right: 0px;
    }

    .popupBackground3 {
        width: 2000px;
        height: 100%;
        background-color: whitesmoke;
        margin-top: -4%;
        position: absolute;
        /* margin-left: -3%; */
        opacity: .8;
        visibility: hidden;
        z-index: 200000000;

    }

    .pu3Box {
        width: 30%;
       /* border: 1px solid grey;*/
        padding: .5%; 
        height: 300px;
        overflow-x: overlay;
        width: 98%;
        height: 100%;
        /*max-height: 520px;*/
        overflow-y: visible;
        overflow:  visible;

    }

    .popupMessage3 {
        z-index: 2;
        border: 2px solid black;
        width: 1300px;
        height: 750px;
        background-color: white;
        text-align: center;
        position: relative;
        left: 24vw;
        top: 8vh;
        position: absolute;
        visibility: hidden;
    }
    .popupMessage2 {
        z-index: 200;
        z-index: 200000000000;

        border: 2px solid black;
        width: 1000px;
        height: 650px;
        background-color: white;
        text-align: center;
        position: relative;
        left: 30vw;
        top: 6vh;
        position: absolute;
        visibility: visible;
        overflow-y: overlay;
        overflow-x: hidden;
        display: none;
    }

    .reportLine{
        margin-top: 1%;
        padding-left: 2%;
        display:flex;
            /* flex-wrap: wrap; */
    /* align-content: space-evenly; */
    justify-content: space-between;
    display: flex;
    width: 96%;
    }

    .select2 {
        position: relative;
        box-sizing: border-box;
        padding: 0%;

        height: 30px;
        border-radius: 5px;

        width: 100%;

    }

    .popupMessage {
        z-index: 200000000000;
        border: 2px solid black;
        width: 500px;
        height: 300px;
        background-color: white;
        text-align: center;
        position: relative;
        left: 45vw;
        top: 27vh;
        position: absolute;
        display: none;
    }

    .popupBtn {
        margin-top: 2%;
        width: 80px;
        height: 45px;
        margin-right: 2%;
        border-radius: 5px;
        background-color: #fdf0df;
    }

    .popupBtn3 {
        
        background-color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 0px;
        left: 0px;
        height: 44px;
        width: 150px;
        border-radius: 10px;
        z-index: 6;

        min-width: 120px;
    }



    .orangeButton {
        width: 80px;
        height: 45px;
        margin-right: 2%;
        border-radius: 5px;
        background-color: #fdf0df;

    }

    .orangeInput2 {
        background-color: #fdf0df;
        width: 200px;
        border-radius: 5px;
        height: 28px;
    }

    .orangeInput {
        background-color: #fdf0df;
        width: 600px;
        border-radius: 5px;
        height: 28px;

    }

    .otherFields {
        margin-left: 0%;
        display: flex;
        align-items: baseline;
    }

    .otherFields2 {
        margin-left: 0%;
        display: flex;
        align-items: baseline;
        overflow-x: overlay;
        width: 100%;
    }

    .DEfields {
        margin-top: -2%;
        display: flex;
        min-height: 100px;
    }

    .DEfieldSection {
        width: 220px;
        text-align: left;
        min-width: 150px
    }

    .itemDiv {
        width: 300px;
        height: 200px;
        background-color: grey;
    }

    .titleSection {
        text-align: left;
        margin-top: 1%;
        margin-bottom: 3%;
        font-size: 22px;

    }


    .pageWrapper {
        text-align: left;
        display: block;
        position: relative;
        left: 0%;
        top: 0%;
        height: 98%;
        width: 90%;
        left: 0%;
        padding-left: 10%;
    }

    .defaultInp {
        margin-top: 1%;
        margin-left: 0%;
        height: 44px;
        width: 300px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8080805c;
        padding: 1%;
    }

    .existingUser {
        margin-left: 1%;
    }

    .userSelect {
        position: relative;
        box-sizing: border-box;
        padding: 0%;
        margin-top: .5%;
        margin-bottom: 2%;
        height: 30px;
        border-radius: 5px;
        width: 200px;
        margin-left: 0%;

    }


    .field {
        margin-top: 3%;
        margin-left: 1%;
        width: 100%;
        display: inline-flex;
    }


    .contactInfo {
        margin-left: 1%;
    }

    .leftCol {
        width: 40%;
        padding-bottom: .5%;
        float: 0;
        text-align: left;
    }

    .rightCol {
        width: 60%;
        text-align: left;
        float: 1;
    }


    .availabilityDay {
        width: 36px;
        height: 20px;
        background-color: white;
        border: 2px solid black;
        padding: 1.1%;
        border-radius: 5px;
        margin-left: 3%;
        text-align: center;
        margin-top: 2%;
    }

    .rowOfBoxes {
        display: flex;
    }

    .availabilityBox {
        display: inherit;
        position: inherit;
        text-align: left;
        margin-left: 2%;
        margin-top: 2%;


    }

    .availabilityText {
        margin-left: 3%;
    }

    .createItem {
        margin-right: 20%;
    }

    .jobSelect {
        width: 100%;
        margin-bottom: 3%;
    }



    .vendorSelect {
        margin-bottom: 3%;
        width: 27%;
    }

    .locationSelect {
        width: 35%;
    }

    .createUserText {
        margin-left: 20%;
    }


    .itemSearch {
        margin-right: 25%;
    }

    .selectTextWrapper {

    }

    .jobPositionText {
        margin-left: 1%;
        margin-right: 8%
    }

    .rgText1 {
        margin-left: 1%;
        margin-right: 1.5%;
    }

    .bottomSection {
    height: 309px;
    padding-top: 0%;
    z-index: 0;
    overflow-y: scroll;
    }

    .selectGrouper {
        width: 57%;
        text-align: left;
        margin-left: 2%
    }

    .blackLineDiv2 {
        color: black;
        height: 1px;
        background-color: black;
        margin-bottom: 1%;
        width: 104.1%;
        margin-top: 1.5%;
        left: -2%;
        position: relative;
    }


    .locationSelect {}

    .locationText {
        margin-top: 5%;

    }

    .formBtnDiv {
        position: relative;
        width: 80%;
        margin-top: 4%;
        left: 92%;
        display: flex;
    }


    .formBtn {
        background-color: rgb(255, 255, 255);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_text_default);
        text-align: center;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(47, 47, 47);
        border-radius: 1px;
        padding: 12px 16px;
        opacity: 1;
        margin-right: 5%;
        cursor: pointer;
        top: 857px;
        left: 1064px;
        width: 135px;
        height: 38px;
        z-index: 6;
    }


    .formBtn2 {
        background-color: rgb(252, 143, 20);
        font-family: var(--font_default);
        font-size: 15px;
        font-weight: 600;
        color: var(--color_primary_contrast_default);
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        box-shadow: 0px 7px 30px -10px rgba(var(--color_primary_default_rgb), 0.1);
        padding: 12px 24px;
        opacity: 1;
        cursor: pointer;
        top: 857px;
        left: 1373px;
        width: 135px;
        height: 38px;
        z-index: 4;
    }





