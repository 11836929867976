/* Modify the base sidebar classes */
.sidebar {
  transition: all 0.3s ease;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Control overflow based on expanded state */
.sidebar.expanded {
  width: 300px;
  overflow-y: auto;
}

.sidebar.collapsed {
  width: 60px;
  overflow: hidden; /* Prevent scrolling when collapsed */
}

/* Modify the collapsed sidebar specifically */
.collapsed-sidebar {
  height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  overflow: hidden; /* Prevent scrolling in collapsed state */
}

.expandButton{
  position: relative;
  bottom: 10px;
  left: 0%;
  transform: translateY(2vh);
  padding: 5px 10px;
  fontSize: 12px;
  background: none;
  border: 1px solid #ccc;
  borderRadius: 3px;
  cursor: pointer;
}


.collapsed-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.collapsed-icons i {
  font-size: 1.5rem;
  margin-bottom: 20px;
  cursor: pointer;
}

.toggleBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.2rem;
}

.main {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 250px;
}

.logo {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.selectWrapper {
  width: 100%;
  margin-bottom: 20px;
}

.linkArea {
  display: flex;
  flex-direction: column;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 0.9rem;
  cursor: pointer;
  padding-bottom: 8%;
  border-radius: 6px;
  padding-left: 3.5%;
  text-align: left;
}

.listItem i {
  margin-right: 10px;
  font-size: 1.2rem;
  padding-left: 5%;
  text-align: left;
  padding-bottom: 5%;
  margin-bottom: 29%;
}

.raiseNoOutline {
  transition: 0.25s;
}

.raiseNoOutline:hover,
.raise:hover {
  transform: translateY(-0.3em);
}

.raise {
  transition: 0.25s;
}

.raise:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.setupListItem {
  margin-top: 8%;
}

.main::-webkit-scrollbar {
  display: none;
}






@media screen and (max-width: 1199px) and (min-width: 768px) and (orientation: landscape) {

  .main {
    padding: 10px;
    max-width: 109px;
    height: 100vh;
  }

  .logo {
    max-width: 150px;
    margin-bottom: 10px;
  }

  .listItem {
    font-size: 0.8rem;
  }

  .listItem i {
    font-size: 1rem;
  }

  .sidebar.expanded {
    width: 200px;
  }

  .sidebar.collapsed {
    width: 50px;
  }

  .collapsed-icons i {
    font-size: 1.2rem;
  }
}


@media screen and (max-width: 480px) {
  .listItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 10px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-right: 10px;
    font-size: 1.2rem;
    padding-left: 5%;
    text-align: left;
    padding-bottom: 11%;
    margin-bottom: 11%;
  }
  
  .listItem i {
    margin-right: 10px;
    font-size: 1.2rem;
    padding-left: 5%;
    text-align: left;
    padding-bottom: 5%;
    margin-bottom: 9%;
  }


  .hideFromMobile {
    display: none;
  }

  .sidebar.expanded  .main{
    max-width: 180px;
    width:180px;
  }
  .collapsed-sidebar{
    width: 40px;
    max-width: 40px;

   
  }
  .sidebar.collapsed {
    width: 40px;
  }

  .collapsed-icons i {
    font-size: 1rem;
  }
}

@media screen and (min-width: 480px) and (max-width: 540px) {
  .listItem {
    padding-bottom: 29%;
  }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
  .listItem {
    padding-bottom: 3%;
  }
  
  .sidebar{
    padding-bottom:5vh
  }
.expandButton{
  margin-top: 73vh;
  width: 9vw;
}
.expandbutton2{
  margin-top: 1vh;
  width: 9vw;

}
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .listItem {
    padding-bottom: 10%;
  }
}

@media screen and (min-width: 390px) and (max-width: 400px) {
  .listItem {
    padding-bottom: 6%;
  }
}

@media screen and (min-width: 350px) and (max-width: 360px) {
  .listItem {
    padding-bottom: 0%;
  }
}

@media screen and (min-height: 350px) and (max-height: 700px) {
  .listItem {
    padding-bottom: 7%;
    font-size: 1em;
  }
  .main{
    height: 91%;
  }
}

@media screen and (min-height: 700px) and (max-height: 900px) {
  .listItem {
    font-size: 1em;
    padding-bottom: 14%;
  }
}

@media screen and (min-width: 540px) and (max-width: 720px) {
  .listItem {
    padding-bottom: 10%;
  }
}

@media screen and (min-width: 1015px) and (max-width: 1340px) {
  .sidebar.expanded {
    width: 250px;
  }

  .main {
    padding: 15px;
    max-width: 250px;
    height: 100vh;
  }

  .listItem {
    font-size: 0.85rem;
    padding-bottom: 8%;
    margin-bottom: 8px;
  }

  .listItem i {
    font-size: 1.1rem;
    margin-bottom: 20%;
    padding-bottom: 3%;
  }

  .logo {
    max-width: 170px;
    margin-bottom: 15px;
  }

  .linkArea {
    overflow: show;
    height: calc(90vh - 160px);
  }

  .expandButton {
    bottom: 20px;
    width: 4vw;
    height: 4vh;
    position: relative;
    font-size: 0.9rem;
    margin-bottom: 5%;
    margin-top: -5%;
  }

  .collapsed-sidebar {
    height: 100%;
    padding: 15px 0;
  }

  .collapsed-icons i {
    font-size: 1.3rem;
    margin-bottom: 22px;
  }
}

@media (max-width: 768px) {
  .main {
    padding: 10px;
    max-width: 109px;
    height: 100vh;
  }

  .logo {
    max-width: 150px;
    margin-bottom: 10px;
  }

  .listItem {
    font-size: 0.8rem;
  }

  .listItem i {
    font-size: 1rem;
  }

  .sidebar.expanded {
    width: 200px;
  }

  .sidebar.collapsed {
    width: 50px;
  }

  .collapsed-icons i {
    font-size: 1.2rem;
  }


  .collapsed-sidebar{
    height: 94.7%;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .sidebar{
    height:103vh
  }
}


/* Add these specific fixes for iPad Air 2 and similar tablets */
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .main{
    max-width: 100%
  }
  .sidebar.collapsed {
    width: 55px;
  }

  .main {
    padding: 15px;
    max-width: 30vw;
    width:  30vw;
    height: 103vh;
  }

  .logo {
    max-width: 180px;
    margin-bottom: 15px;
  }

  .listItem {
    font-size: 0.9rem;
    padding-bottom: 12%;
    font-size: 1.5rem;

  }

  .listItem i {
    font-size: 1.1rem;
    margin-bottom: 15%;
  }

  .collapsed-sidebar {
    height: 96%;
  }

  .collapsed-icons i {
    font-size: 1.3rem;
    margin-bottom: 25px;
  }



  .selectWrapper {
    margin-bottom: 15px;
  }

  .setupListItem {
    margin-top: 6%;
  }

  .sidebar.expanded {
    width: 34vw;
    font-size: 1.5rem;
    padding-right: 1vw;
    overflow-y: auto;
  }

  .sidebar.collapsed {
    width: 55px;
    overflow: hidden;
  }

  .main {
    height: 100vh;
    max-height: 100vh;
    padding: 15px;
    max-width: 30vw;
    width: 30vw;
    overflow-y: auto;
  }

  /* Only allow scrolling in expanded state */
  .linkArea {
    overflow-y: auto;
    height: calc(100vh - 180px);
  }

  /* Prevent scrolling in collapsed state */
  .sidebar.collapsed .linkArea {
    overflow: hidden;
  }

  .collapsed-sidebar {
    height: 100vh;
    max-height: 100vh;
    padding: 15px 0;
    overflow: hidden;
  }

  /* Ensure expand button is always visible */


  .sidebar {
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }

  .main {
    height: 100vh;
    max-height: 100vh;
    padding: 15px;
    max-width: 30vw;
    width: 30vw;
    overflow-y: auto;
  }

  .collapsed-sidebar {
    height: 100vh;
    max-height: 100vh;
    padding: 15px 0;
  }

  .listItem {
    font-size: 1.2rem; /* Slightly reduced from 1.5rem */
    padding-bottom: 8%; /* Reduced from 12% */
    margin-bottom: 8px;
  }

  /* Adjust expand button position */

  .expandButton {
    transform: translateY(1.5vh);
    padding: 4px 8px;
    font-size: 11px;

    background-color: red;
    
    position: relative;
    bottom: 10px;
    left: 0%;
    transform: translateY(2vh);
    padding: 5px 10px;
    background: none;
    border: 1px solid #ccc;
    cursor: pointer;

  }

  /* Ensure content is scrollable */
  .linkArea {
    overflow-y: auto;
    height: calc(100vh - 180px); /* Adjust based on header/logo height */
  }

  /* Hide scrollbars but keep functionality */
  .linkArea::-webkit-scrollbar {
    display: none;
  }
  
  .main::-webkit-scrollbar {
    display: none;
  }
}

/* Specific iPad Air 2 landscape mode */
@media screen 
  and (min-width: 1024px) 
  and (max-height: 768px) 
  and (orientation: landscape) {
  .sidebar {
    height: 100vh;
  }

  .main {
    height: 100vh;
  }

  .listItem {
    padding-bottom: 6%;
  }
}

/* iPad Air 2 portrait specific adjustments */
@media screen 
  and (max-width: 768px) 
  and (min-height: 1024px) 
  and (orientation: portrait) {
  .sidebar.expanded {
    overflow-y: auto;
    margin-left: 3vw;
    
  }

  .sidebar.collapsed {
    overflow: hidden;
  }

  .linkArea {
    width: 20vw;
  }
}


@media screen and (max-width: 375px) {
  .listItem{
    padding-bottom:5%;
  }

  .sidebar{
    height: 109vh;
  }
}

/* 19.5/9 aspect ratio for mobile */
@media screen and (max-aspect-ratio: 19.5/9) {

}



